import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { BarChartIcon } from '@/components/Icons/BarChartIcon';
import { RadarChartIcon } from '@/components/Icons/RadarChartIcon';
import { Typography } from '@/components/Typography/Typography';
import { CompetenciesView } from '@/core/enums/functions/performanceProfile/competenciesCharts/competenciesChartsViewEnum';
import { colorTypes } from '@/styles/types';

interface ICompetenciesChartsNavigation {
  backToQuestionnaireHistory: () => void;
  handleChangeView: (view: CompetenciesView) => void;
}

export const CompetenciesChartsNavigation: React.FC<ICompetenciesChartsNavigation> = ({
  backToQuestionnaireHistory,
  handleChangeView,
}) => {
  const { t: competenciesChartsTranslations } = useTranslation('performanceProfileCharts', {
    keyPrefix: 'buttons',
  });

  return (
    <>
      <Button
        buttonVariant={'Transparent'}
        buttonStyle={'Icon'}
        onClick={backToQuestionnaireHistory}
        className={'self-start gap-2'}
      >
        <ArrowRightIcon className={`text-${colorTypes.Secondary} rotate-180`} />
        <Typography color={'Secondary'} type={'14-R'}>
          {competenciesChartsTranslations('backToPerformanceProfiles.label')}
        </Typography>
      </Button>
      <div className={'flex flex-row gap-2'}>
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          onClick={() => handleChangeView(CompetenciesView.BarChart)}
        >
          <BarChartIcon />
        </Button>
        <Button
          buttonVariant={'Icon'}
          buttonStyle={'Icon'}
          onClick={() => handleChangeView(CompetenciesView.RadarChart)}
        >
          <RadarChartIcon />
        </Button>
      </div>
    </>
  );
};
