import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { ArrowIcon } from '@/components/Icons/ArrowIcon';
import { Typography } from '@/components/Typography/Typography';
import { Location } from '@/components/views/person/PersonPage/DepartmentView/Location/Location';
import { IDepartment } from '@/types/department';

import styles from './styles.module.scss';

export const Department: React.FC<IDepartment> = ({
  name,
  departments,
  id,
  isExpanded = false,
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const departmentId = `${id}`;
  const departmentFieldName = `departments.${departmentId}.value`;

  const formContext = useFormContext();
  const isIntermediate = formContext.watch(`departments.${departmentId}.intermediate`);
  const isChecked = formContext.watch(`departments.${departmentId}.value`);

  useEffect(() => {
    if (isChecked === undefined) {
      formContext.setValue(departmentFieldName, false);
    }
  }, []);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div className='mt-2'>
      <AnimatePresence initial={false}>
        <div
          className={clsx(
            styles.container,
            'flex flex-row items-center py-2 overflow-hidden cursor-pointer',
            {
              [styles.checked]: isChecked || isIntermediate,
            }
          )}
        >
          <Checkbox
            name={departmentFieldName}
            intermediate={isIntermediate}
            className='mr-2 shrink-0'
            id={departmentFieldName}
          />
          <label className={'cursor-pointer'} htmlFor={departmentFieldName}>
            <Typography type='H3' color='Primary'>
              {name}
            </Typography>
          </label>
          <motion.div
            key={'arrow-' + id}
            className={clsx(styles.arrowButton, 'ms-2')}
            onClick={handleExpand}
            initial={{ rotate: 0 }}
            animate={{ rotate: expanded ? 0 : 180 }}
          >
            <ArrowIcon />
          </motion.div>
        </div>

        <motion.div
          key={'department-' + id}
          className={clsx('flex-col', { 'overflow-hidden': !expanded })}
          animate={expanded ? { height: 'auto', opacity: 100 } : { height: 0, opacity: 0 }}
        >
          {departments.map((element) => (
            <Location name={element.name} key={element.id} id={element.id} departmentId={id} />
          ))}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
