import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.performanceProfile;

//history
const history = createSelector([selector], (state) => state.history);
const historyLock = createSelector([selector], (state) => state.historyLock);
//target catalogs
const targetCatalogs = createSelector([selector], (state) => state.targetCatalogs);
const targetCatalogsLock = createSelector([selector], (state) => state.targetCatalogsLock);
//target catalog types
const targetCatalogTypes = createSelector([selector], (state) => state.targetCatalogTypes);
const targetCatalogTypesLock = createSelector([selector], (state) => state.targetCatalogTypesLock);
//competencies reviews
const competenceChartsData = createSelector([selector], (state) => state.competenceChartsData);
const competenceChartsDataLock = createSelector(
  [selector],
  (state) => state.competenceChartsDataLock
);
//questionnaires
const questionnaire = createSelector([selector], (state) => state.questionnaire);
const questionnaireStatus = createSelector([selector], (state) => state.questionnaireStatus);
const questionnaireLock = createSelector([selector], (state) => state.questionnaireLock);
const updateQuestionnaireLock = createSelector(
  [selector],
  (state) => state.updateQuestionnaireLock
);
const completeQuestionnaireLock = createSelector(
  [selector],
  (state) => state.completeQuestionnaireLock
);
//view mode
const viewMode = createSelector([selector], (state) => state.viewDetails.viewMode);
const detailsIDs = createSelector([selector], (state) => state.viewDetails.detailsIDs);

export const performanceProfileSelectors = {
  history,
  historyLock,
  targetCatalogs,
  targetCatalogsLock,
  targetCatalogTypes,
  targetCatalogTypesLock,
  competenceChartsData,
  competenceChartsDataLock,
  questionnaire,
  questionnaireStatus,
  questionnaireLock,
  updateQuestionnaireLock,
  completeQuestionnaireLock,
  viewMode,
  detailsIDs,
};
