import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CarServiceForm } from '@/components/functions/Mobility/forms/CarServiceForm';
import { SelfDriverForm } from '@/components/functions/Mobility/forms/SelfDriverForm';
import { IMobilityForm } from '@/components/functions/Mobility/hooks/useMobilityForm';
import { IMobilitySubForm } from '@/components/functions/Mobility/Mobility';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { mobilitySelectors } from '@/core/redux/slices/functions/workingTimeMobility/mobility/selectors';

export const ArrivalForm: React.FC = () => {
  const { t: mobilityTranslations } = useTranslation('mobility');

  const [selectedID, setSelectedID] = useState<string | undefined>(undefined);

  const mobility = useAppSelector(mobilitySelectors.mobility);
  const availableMobilityTypes = mobility?.availableMobilityTypes ?? [];

  const formContext = useFormContext<IMobilityForm>();
  const selectedFormType = formContext.getValues('arrivalType');
  const selectedFormID = formContext.getValues('arrivalFormID');

  const arrivalSelectValues = availableMobilityTypes.reduce<ISelectItem[]>(
    (accum, transportType) => {
      accum.push({
        id: transportType.id.toString(),
        component: transportType.name,
      });

      return accum;
    },
    []
  );

  const handleChange = (id: string) => {
    const parsedID = parseInt(id);

    const foundElement = availableMobilityTypes.find(
      (transportType) => transportType.id === parsedID
    );

    if (!foundElement) {
      return;
    }

    formContext.setValue('arrivalType', foundElement.type, { shouldDirty: true });
    formContext.setValue('arrivalFormID', parsedID);
  };

  const mobilitySubForms: Record<MobilityFormTypes, IMobilitySubForm | null> = {
    [MobilityFormTypes.CarService]: {
      view: <CarServiceForm context={'arrival'} />,
      type: MobilityFormTypes.CarService,
    },
    [MobilityFormTypes.SelfDriver]: {
      view: <SelfDriverForm context={'arrival'} />,
      type: MobilityFormTypes.CarService,
    },
    [MobilityFormTypes.Default]: null,
  };

  useEffect(() => {
    if (!selectedFormID) {
      return;
    }

    setSelectedID(selectedFormID.toString());
  }, [selectedFormID]);

  return (
    <div className={'flex flex-col gap-2 max-w-[450px] w-full'}>
      <div className={'flex flex-row items-center gap-3'}>
        <Typography>{mobilityTranslations('arrivalType.label')}</Typography>
      </div>
      <Select
        values={arrivalSelectValues}
        onChange={handleChange}
        selectedExternal={selectedID}
        setSelectedExternal={setSelectedID}
        initialValueID={mobility?.arrival.type?.id.toString()}
        isFlexible={true}
        contentOffset={{ mainAxis: 4 }}
      />
      <div className={'mt-4'}>{mobilitySubForms[selectedFormType]?.view}</div>
    </div>
  );
};
