import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PerformanceProfileViews } from '@/core/enums/functions/performanceProfile/performanceProfileViewEnum';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface ITargetCatalogs {
  id: string;
  name: string;
}

export interface IHistory {
  createdTimestamp?: string | null;
  status: QuestionnaireStatus | null;
  statusText?: string | null;
  closedBy?: string | null;
  closedTimestamp?: string | null;
  id: number;
  targetCatalogType: string;
  lastStatusID: number;
  createdBy: string;
}

export interface IHistoryFetchPayload {
  personID: number;
}

export interface ITargetCatalogsFetchPayload {
  personID: number;
}

export interface ICompetenceReview {
  value: number;
  index: string;
}

export interface ICompetenceReviewItem {
  date: string;
  reviews: Record<string, ICompetenceReview>;
}

export interface ICompetenceChartsVariant {
  id: number;
  name: string;
  hasChilds: boolean;
}

export interface ICompetenceCharts {
  data: ICompetenceReviewItem[];
  variants: ICompetenceChartsVariant[];
  valueRange: {
    min: number;
    max: number;
  };
}

export interface ICompetenceChartsPayload {
  personID: number;
  parentID: number;
  competenceAnalysisIds: number[];
}

export interface IQuestionnaireQuestionReview {
  id: number;
  review: number;
  description?: string | null;
}

export interface IQuestionnaireQuestionReviewHistoryItem {
  id: number;
  assessmentType: string;
  fromTimestamp: string | null;
  review: number;
}

export interface IQuestionnaireQuestion {
  id: number;
  name: string;
  description?: string | null;
  review?: number | null;
  reviews: IQuestionnaireQuestionReview[];
  reviewsHistory: IQuestionnaireQuestionReviewHistoryItem[];
}

export interface IQuestionnaireQuestionsGroup {
  id: number;
  name: string;
  questionsList: IQuestionnaireQuestion[];
}

export interface IQuestionnaireFetchPayload {
  personID: number;
  competenceAnalysisID: number;
}

export interface IQuestionnaireQuestionDescriptionUpdatePayload {
  questionID: number;
  description: string;
}

export interface IQuestionnaireUpdatePayload {
  competence: {
    id: number;
    review: number;
    description: string | null;
  };
}

export interface IQuestionnaire {
  competencies: IQuestionnaireQuestionsGroup[];
}

export interface ITargetCatalogType {
  id: number;
  name: string;
}

export interface IQuestionnaireStartNewPayload {
  personID: number;
}

export interface IQuestionnaireCompletePayload {
  personID: number;
  competenceAnalysisID: number;
}

export interface IUnlockQuestionnairePayload {
  personID: number;
  competenceAnalysisID: number;
}

export interface IQuestionnaireSendNotificationPayload {
  competenceAnalysisID: number;
  usersIds: number[];
}

export interface IPerformanceProfileViewDetails {
  detailsIDs: Record<number, number[]> | null;
  viewMode: Record<number, PerformanceProfileViews> | null;
}

export interface ITargetCatalogTypesFetchPayload {
  personID: number;
}

export interface IPerformanceProfileState {
  history: IHistory[];
  historyLock: LoadingStatus;
  targetCatalogs: ITargetCatalogs[];
  targetCatalogsLock: LoadingStatus;
  selectedQuestionnaires: number[];
  competenceChartsData: ICompetenceCharts | null;
  competenceChartsDataLock: LoadingStatus;
  questionnaire: IQuestionnaire | null;
  questionnaireStatus: QuestionnaireStatus | null;
  questionnaireLock: LoadingStatus;
  updateQuestionnaireLock: LoadingStatus;
  completeQuestionnaireLock: LoadingStatus;
  targetCatalogTypes: ITargetCatalogType[];
  targetCatalogTypesLock: LoadingStatus;
  viewDetails: IPerformanceProfileViewDetails;
}

const initialState: IPerformanceProfileState = {
  //targetCatalogs
  targetCatalogs: [],
  targetCatalogsLock: LoadingStatus.NEVER,
  //history
  history: [],
  historyLock: LoadingStatus.NEVER,
  //selected
  selectedQuestionnaires: [],
  //charts data
  competenceChartsData: null,
  competenceChartsDataLock: LoadingStatus.NEVER,
  //questionnaire
  questionnaire: null,
  questionnaireStatus: null,
  questionnaireLock: LoadingStatus.NEVER,
  updateQuestionnaireLock: LoadingStatus.NEVER,
  completeQuestionnaireLock: LoadingStatus.NEVER,
  //target catalog types
  targetCatalogTypes: [],
  targetCatalogTypesLock: LoadingStatus.NEVER,
  //view mode
  viewDetails: {
    viewMode: null,
    detailsIDs: null,
  },
};

export const performanceProfileSlice = createSlice({
  name: 'performanceProfile',
  initialState,
  reducers: {
    //history
    fetchHistory: (state, action: PayloadAction<IHistoryFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setHistoryLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      historyLock: action.payload,
    }),
    setHistory: (state, action: PayloadAction<IHistory[]>) => ({
      ...state,
      history: action.payload,
    }),
    //target catalogs
    fetchTargetCatalogs: (state, action: PayloadAction<ITargetCatalogsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setTargetCatalogsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      targetCatalogsLock: action.payload,
    }),
    setTargetCatalogs: (state, action: PayloadAction<ITargetCatalogs[]>) => ({
      ...state,
      targetCatalogs: action.payload,
    }),
    //target catalogs types
    fetchTargetCatalogTypes: (state, action: PayloadAction<ITargetCatalogsFetchPayload>) => ({
      ...state,
      payload: action.payload.personID,
    }),
    setTargetCatalogTypesLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      targetCatalogTypesLock: action.payload,
    }),
    setTargetCatalogTypes: (state, action: PayloadAction<ITargetCatalogType[]>) => ({
      ...state,
      targetCatalogTypes: action.payload,
    }),
    //competencies reviews
    fetchCompetenceChartsData: (state, action: PayloadAction<ICompetenceChartsPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCompetenceChartsData: (state, action: PayloadAction<ICompetenceCharts | null>) => ({
      ...state,
      competenceChartsData: action.payload,
    }),
    setCompetenceChartsDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      competenceChartsDataLock: action.payload,
    }),

    //questionnaire
    setQuestionnaire: (state, action: PayloadAction<IQuestionnaire | null>) => ({
      ...state,
      questionnaire: action.payload,
    }),
    setQuestionnaireLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      questionnaireLock: action.payload,
    }),
    fetchQuestionnaire: (state, action: PayloadAction<IQuestionnaireFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateQuestionnaire: (state, action: PayloadAction<IQuestionnaireUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateQuestionnaireLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateQuestionnaireLock: action.payload,
    }),
    setQuestionnaireStatus: (state, action: PayloadAction<QuestionnaireStatus | null>) => ({
      ...state,
      questionnaireStatus: action.payload,
    }),
    startNewQuestionnaire: (state, action: PayloadAction<IQuestionnaireStartNewPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    completeQuestionnaire: (state, action: PayloadAction<IQuestionnaireCompletePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCompleteQuestionnaireLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      completeQuestionnaireLock: action.payload,
    }),
    unlockQuestionnaire: (state, action: PayloadAction<IUnlockQuestionnairePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    sendNotification: (state, action: PayloadAction<IQuestionnaireSendNotificationPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    //view mode
    setViewDetailsIDs: (
      state,
      action: PayloadAction<{ personID: number; competenceAnalysisIDs: number[] }>
    ) => ({
      ...state,
      viewDetails: {
        ...state.viewDetails,
        detailsIDs: {
          ...state.viewDetails.detailsIDs,
          [action.payload.personID]: action.payload.competenceAnalysisIDs,
        },
      },
    }),
    setViewDetailsViewMode: (
      state,
      action: PayloadAction<{ personID: number; viewMode: PerformanceProfileViews }>
    ) => ({
      ...state,
      viewDetails: {
        ...state.viewDetails,
        viewMode: {
          ...state.viewDetails.viewMode,
          [action.payload.personID]: action.payload.viewMode,
        },
      },
    }),
  },
});

export const performanceProfileReducer = performanceProfileSlice.reducer;
export const performanceProfileActions = performanceProfileSlice.actions;
