import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import {
  IAdditionalPersonRequirement,
  IAdditionalPersonRequirementDeletePayload,
  IAdditionalPersonRequirementPayload,
  IOrganizationalUnit,
  IOrganizationalUnitsDeletePayload,
  IOrganizationalUnitsFetchPayload,
  organizationalUnitsActions,
} from '@/core/redux/slices/functions/organizationalUnits/slice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type OrganizationalUnitsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/organization_unit',
  'get',
  '200'
>;
type OrganizationalUnitsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit',
  'get'
>;

type AdditionalPersonRequirementResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement',
  'get',
  '200'
>;
type AdditionalPersonRequirementRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement',
  'get'
>;

type AdditionalPersonRequirementDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/additional_person_requirement',
  'delete'
>;

type OrganizationalUnitDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/organization_unit',
  'delete'
>;

function* fetchOrganizationalUnits(
  action: PayloadAction<IOrganizationalUnitsFetchPayload>
): Generator<any, void, OrganizationalUnitsResponse> {
  const { personID } = action.payload;

  yield put(organizationalUnitsActions.setOrganizationalUnitsLock(LoadingStatus.LOADING));

  try {
    const request: OrganizationalUnitsRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/organization_unit', 'get', request);

    const organizationalUnits = response.units.map<IOrganizationalUnit>((unit) => ({
      id: unit.id,
      name: unit.name,
      comment: unit.note,
      fromTimestamp: toClientDateInput(unit.from_timestamp),
      toTimestamp: toClientDateInput(unit.to_timestamp),
      type: isEnumValue(OrganizationalUnitsTypes, unit.type)
        ? unit.type
        : OrganizationalUnitsTypes.Default,
    }));

    yield put(organizationalUnitsActions.setOrganizationalUnits(organizationalUnits));
    yield put(organizationalUnitsActions.setOrganizationalUnitsLock(LoadingStatus.LOADED));
  } catch (error) {
    yield put(organizationalUnitsActions.setOrganizationalUnitsLock(LoadingStatus.ERROR));
    console.log('Error on organizational units fetching', error);
  }
}

function* fetchAdditionalPersonRequirements(
  action: PayloadAction<IAdditionalPersonRequirementPayload>
): Generator<any, void, AdditionalPersonRequirementResponse> {
  const { personID } = action.payload;

  yield put(
    organizationalUnitsActions.setAdditionalPersonRequirementUnitsLock(LoadingStatus.LOADING)
  );

  try {
    const request: AdditionalPersonRequirementRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/additional_person_requirement', 'get', request);

    const additionalPersonRequirement: IAdditionalPersonRequirement = {
      isNewRequirementAvailable: response.is_new_requirement_available,
      isEditable: response.is_editable,
      responsible: response.responsible,
      personRequirements: response.person_requirements.map((requirement) => ({
        id: requirement.id,
        assistance: requirement.assistance,
        comment: requirement.comment,
        appliedTimestamp: toClientDateInput(requirement.applied_timestamp),
        approvedTimestamp: toClientDateInput(requirement.approved_from_timestamp),
        toTimestamp: toClientDateInput(requirement.approved_to_timestamp),
        minutesPerDay: requirement.minutes_per_day,
        responsible: requirement.responsible,
      })),
    };

    yield put(
      organizationalUnitsActions.setAdditionalPersonRequirementUnits(additionalPersonRequirement)
    );
    yield put(
      organizationalUnitsActions.setAdditionalPersonRequirementUnitsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    yield put(
      organizationalUnitsActions.setAdditionalPersonRequirementUnitsLock(LoadingStatus.ERROR)
    );
    console.log('Error on additional person requirement fetching', error);
  }
}

function* deleteAdditionalPersonRequirement(
  action: PayloadAction<IAdditionalPersonRequirementDeletePayload>
): Generator<any, void, any> {
  const { additionalPersonRequirementID, personID } = action.payload;

  try {
    const request: AdditionalPersonRequirementDeleteRequest = {
      query: {
        additional_person_requirement_id: additionalPersonRequirementID,
      },
      ...authAdd(),
    };

    yield call(restCall, '/additional_person_requirement', 'delete', request);
    yield put(
      organizationalUnitsActions.fetchAdditionalPersonRequirementUnits({ personID: personID })
    );
  } catch (error) {
    console.log('Error on additional person requirement removing', error);
  }
}

function* deleteOrganizationalUnit(
  action: PayloadAction<IOrganizationalUnitsDeletePayload>
): Generator<any, void, any> {
  const { organizationalUnitID, personID } = action.payload;

  try {
    const request: OrganizationalUnitDeleteRequest = {
      json: {
        person_id: personID,
        unit_id: organizationalUnitID,
      },
      ...authAdd(),
    };

    yield call(restCall, '/organization_unit', 'delete', request);
    yield put(organizationalUnitsActions.fetchOrganizationalUnits({ personID: personID }));
  } catch (error) {
    console.log('Error on organizational unit removing', error);
  }
}

export const organizationalUnitsSagas = [
  takeLatest(organizationalUnitsActions.fetchOrganizationalUnits, fetchOrganizationalUnits),
  takeLatest(organizationalUnitsActions.deleteOrganizationalUnit, deleteOrganizationalUnit),
  takeLatest(
    organizationalUnitsActions.fetchAdditionalPersonRequirementUnits,
    fetchAdditionalPersonRequirements
  ),
  takeLatest(
    organizationalUnitsActions.deleteAdditionalPersonRequirement,
    deleteAdditionalPersonRequirement
  ),
];
