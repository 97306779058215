import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import fp from 'lodash/fp';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import { authAdd, restCall } from '@/core/clients/rest';
import { mixinWithErrorHandler, mixinWithOnlyStartLoading } from '@/core/redux/sagas';
import {
  dashboardActions,
  IActivitiesFetchPayload,
  IFavoritePerson,
  IFavoritePersonsFetchPayload,
  IFavoritePersonsGroup,
} from '@/core/redux/slices/dashboard/dashboardSlice';
import { augmentSaga } from '@/core/redux/utils';
import { createDateRangeObject } from '@/core/utils/dateTimeUtil';
import {
  GetWidgetsSettingsDocument,
  GetWidgetsSettingsQuery,
  UpdateWidgetsPositionDocument,
  UpdateWidgetsPositionMutation,
  UpdateWidgetsSettingsDocument,
  UpdateWidgetsSettingsMutationHookResult,
} from '@/services/graphql/base/graphql';
import type oas from '@/services/rest/base/openapi';
import {
  IGetWidgetsSettingsQuery,
  IUpdateWidgetPositionPayload,
  IUpdateWidgetVisibilityPayload,
} from '@/types/dashboardWidget';
import { LoadingStatus } from '@/types/loadingStatus';
import { IShortcutFunctionsItem } from '@/types/person';

import { authSelectors } from '../auth/selectors';

type FavoritePersonsGroupsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/person/favorite',
  'get',
  '200'
>;

type FavoritePersonsGroupsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/person/favorite',
  'get'
>;

function* fetchFavoritePersonsGroups(action: PayloadAction<IFavoritePersonsFetchPayload>): Generator<any, void, FavoritePersonsGroupsResponse> {
  const { search } = action.payload;

  try {
    yield put(dashboardActions.setFavoritePersonsLock(LoadingStatus.LOADING));

    const request: FavoritePersonsGroupsRequest = {
      query: {
        search: search,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/person/favorite', 'get', request);

    const favoritePersonsGroups = response.groups.map<IFavoritePersonsGroup>((group) => ({
      id: group.id,
      name: group.name,
      persons: group.persons.map<IFavoritePerson>((person) => ({
        id: person.id,
        isFavorite: person.is_favorite,
        lastModified: person.last_modified,
        name: person.name,
        surname: person.surname,
        functions: person.functions.map<IShortcutFunctionsItem>((func) => ({
          iconFilenameDisk: func.icon_filename_disk ?? '',
          id: func.id,
          name: func.name,
          tooltip: func.tooltip,
        })),
      })),
    }));

    yield put(dashboardActions.setFavoritePersons(favoritePersonsGroups));

    yield put(dashboardActions.setFavoritePersonsLock(fp.isEmpty(favoritePersonsGroups)
      ? LoadingStatus.LOADED_EMPTY : LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on favorite persons fetching', error);
    yield put(dashboardActions.setFavoritePersonsLock(LoadingStatus.ERROR));
  }
}

function* fetchActivities(action: PayloadAction<IActivitiesFetchPayload>): Generator<any, void, any> {
  const aToken = yield select(authSelectors.accessToken);

  const dateRangeParam = action.payload ? createDateRangeObject(action.payload.dateRangeType, action.payload.dateRange) : null;

  try {
    const response = yield call(restCall, '/activity', 'get', {
      query: {
        from_date: dateRangeParam?.fromDate,
        to_date: dateRangeParam?.toDate,
      },
      aToken,
    });
    yield put(dashboardActions.setActivitiesList(response.data));
    yield put(dashboardActions.setActivitiesTotalCount(response.total))
    yield put(dashboardActions.setActivitiesLock(fp.isEmpty(response.data)
      ? LoadingStatus.LOADED_EMPTY : LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on trying to fetch activities', error);
    yield put(dashboardActions.setActivitiesLock(LoadingStatus.ERROR));
  }
}

function* fetchWidgets(): Generator<any, void, IGetWidgetsSettingsQuery> {
  const client = initializeApollo();
  const response = yield call(client.query<GetWidgetsSettingsQuery>, {
    query: GetWidgetsSettingsDocument,
  });
  const filteredSettings = response?.data.settings.find((item: any) => item.widgets.length > 0); // TODO: Remove the filter after BE updates, we should get settings as an object and not array

  if (filteredSettings?.widgets && filteredSettings?.widgets.length) {
    yield put(dashboardActions.setWidgets(filteredSettings.widgets));
  }
}

function* updateWidgetSettings(action: PayloadAction<IUpdateWidgetVisibilityPayload[]>):
  Generator<any, void, UpdateWidgetsSettingsMutationHookResult> {
  try {
    const client = initializeApollo();
    const { payload } = action;
    const res = [];
    for (const update of payload) {
      yield call(client.mutate, {
        mutation: UpdateWidgetsSettingsDocument,
        variables: { ...update },
      });
      res.push({ id: String(update.id), is_visible: update.is_visible });
    }
    yield put(dashboardActions.updateWidgets(res));
    yield call(fetchWidgets);
  } catch (error) {
    console.error('Error updating widget settings:', error);
  }
}

function* updateWidgetPosition(action: PayloadAction<IUpdateWidgetPositionPayload>):
  Generator<any, void, UpdateWidgetsSettingsMutationHookResult> {
  const client = initializeApollo();

  for (const key of Object.keys(action.payload.widgetsState)) {
    if (!fp.isEmpty(action.payload.widgetsState[key])) {
      for (const [index, widget] of action.payload.widgetsState[key].entries()) {
        yield call(client.mutate<UpdateWidgetsPositionMutation>, {
          mutation: UpdateWidgetsPositionDocument,
          variables: {
            id: widget.id,
            column_id: key,
            order_number: String(index),
          },
        });
      }
    }
  }

  yield call(fetchWidgets);
}


export const dashboardSagas = [
  takeLatest(dashboardActions.fetchFavoritePersons,
    augmentSaga(fetchFavoritePersonsGroups,
      mixinWithErrorHandler('', dashboardActions.setFavoritePersonsLock),
      mixinWithOnlyStartLoading(dashboardActions.setFavoritePersonsLock))),

  takeLatest(dashboardActions.fetchWidgets, augmentSaga(fetchWidgets)),

  takeLatest(dashboardActions.fetchActivities, augmentSaga(fetchActivities)),

  takeLatest(dashboardActions.updateWidgetSettings, augmentSaga(updateWidgetSettings)),
  takeLatest(dashboardActions.updateWidgetPosition, augmentSaga(updateWidgetPosition)),
];
