import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import {
  GetAppParametersDocument,
  GetAppParametersQuery,
  GetAppParametersQueryHookResult,
} from '@/services/graphql/base/graphql';

import { IFetchParametersPayload, parametersActions } from './parametersSlice';

function* fetchParameters(
  action: PayloadAction<IFetchParametersPayload>
): Generator<any, void, GetAppParametersQueryHookResult> {
  const { payload } = action;

  try {
    const client = initializeApollo();

    const response = yield call(client.query, {
      query: GetAppParametersDocument,
      variables: {
        parameterNames: payload,
      },
    });

    const params = response.data ? response.data.parameters : [];

    yield put(parametersActions.setParameters(params));
  } catch (error) {
    console.log('Error on parameters data fetching');
  }
}

export const parametersSagas = [takeLatest(parametersActions.fetchParameters, fetchParameters)];
