import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VocationalTrainingAreaType } from '@/core/enums/functions/vocationalTrainingArea/vocationalTrainingAreaTypesEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IVocationalTrainingAreaItem {
  id: number;
  name: string;
  nearestActivity?: string | null;
  lastActivity?: string | null;
  type?: VocationalTrainingAreaType | null;
  hasDocuments: boolean;
}

export interface IVocationalTrainingAreaGroup {
  id: number;
  name: string;
  childGroup?: IVocationalTrainingAreaGroup[] | null;
  items?: IVocationalTrainingAreaItem[] | null;
}

export interface IVocationalTrainingAreaFetchPayload {
  personID: number;
  searchValue?: string | null;
  selectedType?: VocationalTrainingAreaType | null;
  isFinished?: boolean | null;
}

export interface IVocationalTrainingAreaState {
  vocationalTrainingAreaList: IVocationalTrainingAreaItem[] | null;
  vocationalTrainingAreaListLock: LoadingStatus;
  vocationalTrainingAreaGroups: IVocationalTrainingAreaGroup[] | null;
  vocationalTrainingAreaGroupsLock: LoadingStatus;
  payload: IVocationalTrainingAreaFetchPayload | null;
  selectedVocationalTrainingArea: IVocationalTrainingAreaItem | null;
}

const initialState: IVocationalTrainingAreaState = {
  selectedVocationalTrainingArea: null,
  vocationalTrainingAreaList: null,
  vocationalTrainingAreaListLock: LoadingStatus.NEVER,
  vocationalTrainingAreaGroups: null,
  vocationalTrainingAreaGroupsLock: LoadingStatus.NEVER,
  payload: null,
};

export const vocationalTrainingAreaSlice = createSlice({
  name: 'vocationalTrainingArea',
  initialState,
  reducers: {
    fetchVocationalTrainingAreaGroups: (
      state,
      action: PayloadAction<IVocationalTrainingAreaFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setVocationalTrainingAreaGroupsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      vocationalTrainingAreaGroupsLock: action.payload,
    }),
    setVocationalTrainingAreaGroups: (
      state,
      action: PayloadAction<IVocationalTrainingAreaGroup[] | null>
    ) => ({
      ...state,
      vocationalTrainingAreaGroups: action.payload,
    }),
    fetchVocationalTrainingAreaList: (
      state,
      action: PayloadAction<IVocationalTrainingAreaFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setVocationalTrainingAreaListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      vocationalTrainingAreaListLock: action.payload,
    }),
    setVocationalTrainingAreaList: (
      state,
      action: PayloadAction<IVocationalTrainingAreaItem[] | null>
    ) => ({
      ...state,
      vocationalTrainingAreaList: action.payload,
    }),
    setSelectedVocationalTrainingArea: (
      state,
      action: PayloadAction<IVocationalTrainingAreaItem | null>
    ) => ({
      ...state,
      selectedVocationalTrainingArea: action.payload,
    }),
  },
});

export const vocationalTrainingAreaReducer = vocationalTrainingAreaSlice.reducer;
export const vocationalTrainingAreaActions = vocationalTrainingAreaSlice.actions;
