import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { competenceAnalysisRecommendationsTypes } from '@/core/enums/competenceAnalysisRecommendationsTypesEnum';
import {
  competenceAnalysisActions,
  ICompetenceAnalysis,
  ICompetenceAnalysisCompetencies,
  ICompetenceAnalysisFetchPayload,
  ICompetenceAnalysisImportFetchPayload,
  ICompetenceAnalysisUpdatePayload,
} from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';
import { competenceAnalysisSelectors } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/selectors';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import { extractIntegerFromString, getSelectedOption } from '@/core/utils/commonUtils';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type CompetenceAnalysisResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/competence_analysis',
  'get',
  '200'
>;
type CompetenceAnalysisImportResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/competence_analysis/import',
  'get',
  '200'
>;

const mapCompetenceAnalysisCompetencies = (
  response: CompetenceAnalysisImportResponse
): ICompetenceAnalysisCompetencies => {
  const { competence_analysis } = response;

  return {
    sociabilityRating: extractIntegerFromString(competence_analysis.sociability_rating),
    sociabilityComment: competence_analysis.sociability_comment,
    cooperationRating: extractIntegerFromString(competence_analysis.cooperation_rating),
    cooperationComment: competence_analysis.cooperation_comment,
    teamRating: extractIntegerFromString(competence_analysis.team_rating),
    teamComment: competence_analysis.team_comment,
    takeCriticismRating: extractIntegerFromString(competence_analysis.take_criticism_rating),
    takeCriticismComment: competence_analysis.take_criticism_comment,
    concentrationRating: extractIntegerFromString(competence_analysis.concentration_rating),
    concentrationComment: competence_analysis.concentration_comment,
    memorizationRating: extractIntegerFromString(competence_analysis.memorization_rating),
    memorizationComment: competence_analysis.memorization_comment,
    workSpeedRating: extractIntegerFromString(competence_analysis.work_speed_rating),
    workSpeedComment: competence_analysis.work_speed_comment,
    independentWorkRating: extractIntegerFromString(competence_analysis.independent_work_rating),
    independentWorkComment: competence_analysis.independent_work_comment,
    enduranceWorkRating: extractIntegerFromString(competence_analysis.endurance_work_rating),
    enduranceWorkComment: competence_analysis.endurance_work_comment,
    flexibilityRating: extractIntegerFromString(competence_analysis.flexibility_rating),
    flexibilityComment: competence_analysis.flexibility_comment,
    selfAssessmentRating: extractIntegerFromString(competence_analysis.self_assessment_rating),
    selfAssessmentComment: competence_analysis.self_assessment_comment,
    selfRepresentationRating: extractIntegerFromString(
      competence_analysis.self_representation_rating
    ),
    selfRepresentationComment: competence_analysis.self_representation_comment,
    grossFineMotorSkillsWithoutToolsRating: extractIntegerFromString(
      competence_analysis.gross_fine_motor_skills_without_tools_rating
    ),
    grossFineMotorSkillsWithoutToolsComment:
      competence_analysis.gross_fine_motor_skills_without_tools_comment,
    grossFineMotorSkillsWithToolsRating: extractIntegerFromString(
      competence_analysis.gross_fine_motor_skills_with_tools_rating
    ),
    grossFineMotorSkillsWithToolsComment:
      competence_analysis.gross_fine_motor_skills_with_tools_comment,
    physicalCapacityRating: extractIntegerFromString(competence_analysis.physical_capacity_rating),
    physicalCapacityComment: competence_analysis.physical_capacity_comment,
    spacialOrientationRating: extractIntegerFromString(
      competence_analysis.spacial_orientation_rating
    ),
    spacialOrientationComment: competence_analysis.spacial_orientation_comment,
    timeOrientationRating: extractIntegerFromString(competence_analysis.time_orientation_rating),
    timeOrientationComment: competence_analysis.time_orientation_comment,
    mobilityTrafficRating: extractIntegerFromString(competence_analysis.mobility_traffic_rating),
    mobilityTrafficComment: competence_analysis.mobility_traffic_comment,
    readRating: extractIntegerFromString(competence_analysis.read_rating),
    readComment: competence_analysis.read_comment,
    writingRating: extractIntegerFromString(competence_analysis.writing_rating),
    writingComment: competence_analysis.writing_comment,
    calculateRating: extractIntegerFromString(competence_analysis.calculate_rating),
    calculateComment: competence_analysis.calculate_comment,
  };
};

const mapCompetenceAnalysis = (response: CompetenceAnalysisResponse): ICompetenceAnalysis => {
  const { competence_analysis } = response;

  const recommendationOptions = {
    [competenceAnalysisRecommendationsTypes.ActivityOriented]:
      competence_analysis.is_activity_oriented,
    [competenceAnalysisRecommendationsTypes.OccupationalFieldOriented]:
      competence_analysis.is_occupational_field_oriented,
    [competenceAnalysisRecommendationsTypes.WorkplaceOriented]:
      competence_analysis.is_workplace_oriented,
    [competenceAnalysisRecommendationsTypes.JobProfileOriented]:
      competence_analysis.is_job_profile_oriented,
  };

  const competencies = mapCompetenceAnalysisCompetencies({
    competence_analysis: competence_analysis.competencies,
  });

  return {
    melba: competence_analysis.melba,
    melbaSl: competence_analysis.melba_sl,
    mast: competence_analysis.mast,
    pac: competence_analysis.pac,
    detmolderLearningPathModel: competence_analysis.detmolder_learning_path_model,
    isOwnTestProcedure: competence_analysis.is_own_test_procedure,
    ida: competence_analysis.ida,
    hamet: competence_analysis.hamet,
    imba: competence_analysis.imba,
    isOtherTestProcedureV1: competence_analysis.is_other_test_procedure_v1,
    designationOtherTestProcedureV1: competence_analysis.designation_other_test_procedure_v1,
    isOtherTestProcedureV2: competence_analysis.is_other_test_procedure_v2,
    designationOtherTestProcedureV2: competence_analysis.designation_other_test_procedure_v2,
    otherCompetencies1Name: competence_analysis.other_competencies1_name,
    otherCompetencies1Rating: competence_analysis.other_competencies1_rating,
    otherCompetencies1Comment: competence_analysis.other_competencies1_comment,
    otherCompetencies2Name: competence_analysis.other_competencies2_name,
    otherCompetencies2Rating: competence_analysis.other_competencies2_rating,
    otherCompetencies2Comment: competence_analysis.other_competencies2_comment,
    importDate: toClientDateInput(competence_analysis.import_date),
    recommendations: getSelectedOption(recommendationOptions),
    ...competencies,
  };
};

function* fetchCompetenceAnalysis(
  action: PayloadAction<ICompetenceAnalysisFetchPayload>
): Generator<any, void, CompetenceAnalysisResponse> {
  yield put(competenceAnalysisActions.setCompetenceAnalysisLock(LoadingStatus.LOADING));

  const { integrationPlanID } = action.payload;

  try {
    const response = yield call(restCall, `/integration_plan/competence_analysis`, 'get', {
      query: { integration_plan_id: integrationPlanID },
      ...authAdd(),
    });

    const competenceAnalysis = mapCompetenceAnalysis(response);

    yield put(competenceAnalysisActions.setCompetenceAnalysis(competenceAnalysis));

    yield put(competenceAnalysisActions.setCompetenceAnalysisLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan general data fetching');
    yield put(competenceAnalysisActions.setCompetenceAnalysisLock(LoadingStatus.ERROR));
  }
}

function* updateCompetenceAnalysis(
  action: PayloadAction<ICompetenceAnalysisUpdatePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, competenceAnalysis } = action.payload;

  const isCompetenceAnalysisImported = yield select(
    competenceAnalysisSelectors.competenceAnalysisIsImported
  );

  const request = {
    query: {
      person_id: personID,
      is_imported: isCompetenceAnalysisImported,
      integration_plan_id: integrationPlanID,
    },
    json: {
      melba: competenceAnalysis.melba ?? false,
      melba_sl: competenceAnalysis.melbaSl ?? false,
      mast: competenceAnalysis.mast ?? false,
      pac: competenceAnalysis.pac ?? false,
      detmolder_learning_path_model: competenceAnalysis.detmolderLearningPathModel ?? false,
      is_own_test_procedure: competenceAnalysis.isOwnTestProcedure ?? false,
      ida: competenceAnalysis.ida ?? false,
      hamet: competenceAnalysis.hamet ?? false,
      imba: competenceAnalysis.imba ?? false,
      is_other_test_procedure_v1: competenceAnalysis.isOtherTestProcedureV1 ?? false,
      designation_other_test_procedure_v1:
        competenceAnalysis.designationOtherTestProcedureV1 ?? null,
      is_other_test_procedure_v2: competenceAnalysis.isOtherTestProcedureV2 ?? false,
      designation_other_test_procedure_v2:
        competenceAnalysis.designationOtherTestProcedureV2 ?? null,
      sociability_rating: competenceAnalysis.sociabilityRating ?? null,
      sociability_comment: competenceAnalysis.sociabilityComment ?? null,
      cooperation_rating: competenceAnalysis.cooperationRating ?? null,
      cooperation_comment: competenceAnalysis.cooperationComment ?? null,
      team_rating: competenceAnalysis.teamRating ?? null,
      team_comment: competenceAnalysis.teamComment ?? null,
      take_criticism_rating: competenceAnalysis.takeCriticismRating ?? null,
      take_criticism_comment: competenceAnalysis.takeCriticismComment ?? null,
      concentration_rating: competenceAnalysis.concentrationRating ?? null,
      concentration_comment: competenceAnalysis.concentrationComment ?? null,
      memorization_rating: competenceAnalysis.memorizationRating ?? null,
      memorization_comment: competenceAnalysis.memorizationComment ?? null,
      work_speed_rating: competenceAnalysis.workSpeedRating ?? null,
      work_speed_comment: competenceAnalysis.workSpeedComment ?? null,
      independent_work_rating: competenceAnalysis.independentWorkRating ?? null,
      independent_work_comment: competenceAnalysis.independentWorkComment ?? null,
      endurance_work_rating: competenceAnalysis.enduranceWorkRating ?? null,
      endurance_work_comment: competenceAnalysis.enduranceWorkComment ?? null,
      flexibility_rating: competenceAnalysis.flexibilityRating ?? null,
      flexibility_comment: competenceAnalysis.flexibilityComment ?? null,
      self_assessment_rating: competenceAnalysis.selfAssessmentRating ?? null,
      self_assessment_comment: competenceAnalysis.selfAssessmentComment ?? null,
      self_representation_rating: competenceAnalysis.selfRepresentationRating ?? null,
      self_representation_comment: competenceAnalysis.selfRepresentationComment ?? null,
      gross_fine_motor_skills_without_tools_rating:
        competenceAnalysis.grossFineMotorSkillsWithoutToolsRating ?? null,
      gross_fine_motor_skills_without_tools_comment:
        competenceAnalysis.grossFineMotorSkillsWithoutToolsComment ?? null,
      gross_fine_motor_skills_with_tools_rating:
        competenceAnalysis.grossFineMotorSkillsWithToolsRating ?? null,
      gross_fine_motor_skills_with_tools_comment:
        competenceAnalysis.grossFineMotorSkillsWithToolsComment ?? null,
      physical_capacity_rating: competenceAnalysis.physicalCapacityRating ?? null,
      physical_capacity_comment: competenceAnalysis.physicalCapacityComment ?? null,
      spacial_orientation_rating: competenceAnalysis.spacialOrientationRating ?? null,
      spacial_orientation_comment: competenceAnalysis.spacialOrientationComment ?? null,
      time_orientation_rating: competenceAnalysis.timeOrientationRating ?? null,
      time_orientation_comment: competenceAnalysis.timeOrientationComment ?? null,
      mobility_traffic_rating: competenceAnalysis.mobilityTrafficRating ?? null,
      mobility_traffic_comment: competenceAnalysis.mobilityTrafficComment ?? null,
      read_rating: competenceAnalysis.readRating ?? null,
      read_comment: competenceAnalysis.readComment ?? null,
      writing_rating: competenceAnalysis.writingRating ?? null,
      writing_comment: competenceAnalysis.writingComment ?? null,
      calculate_rating: competenceAnalysis.calculateRating ?? null,
      calculate_comment: competenceAnalysis.calculateComment ?? null,
      other_competencies1_name: competenceAnalysis.otherCompetencies1Name ?? null,
      other_competencies1_rating: competenceAnalysis.otherCompetencies1Rating ?? null,
      other_competencies1_comment: competenceAnalysis.otherCompetencies1Comment ?? null,
      other_competencies2_name: competenceAnalysis.otherCompetencies2Name ?? null,
      other_competencies2_rating: competenceAnalysis.otherCompetencies2Rating ?? null,
      other_competencies2_comment: competenceAnalysis.otherCompetencies2Comment ?? null,
      is_activity_oriented:
        competenceAnalysis.recommendations ===
          competenceAnalysisRecommendationsTypes.ActivityOriented ?? false,
      is_occupational_field_oriented:
        competenceAnalysis.recommendations ===
          competenceAnalysisRecommendationsTypes.OccupationalFieldOriented ?? false,
      is_workplace_oriented:
        competenceAnalysis.recommendations ===
          competenceAnalysisRecommendationsTypes.WorkplaceOriented ?? false,
      is_job_profile_oriented:
        competenceAnalysis.recommendations ===
          competenceAnalysisRecommendationsTypes.JobProfileOriented ?? false,
    },
    ...authAdd(),
  };
  try {
    yield put(competenceAnalysisActions.setUpdateCompetenceAnalysisLock(LoadingStatus.LOADING));

    const updateResponse = yield call(restCall, '/integration_plan/competence_analysis', 'post', {
      ...request,
    });

    yield put(
      integrationPlanActions.checkIntegrationPlanUpdate({
        integrationPlanID,
        updateResponse: updateResponse,
      })
    );

    yield put(competenceAnalysisActions.setUpdateCompetenceAnalysisLock(LoadingStatus.LOADED));
    yield put(competenceAnalysisActions.setCompetenceAnalysisIsImported(false));
  } catch (error) {
    console.log('Error on integration competence analysis data updating');
    yield put(competenceAnalysisActions.setUpdateCompetenceAnalysisLock(LoadingStatus.ERROR));
  }
}

function* fetchCompetenceAnalysisImport(
  action: PayloadAction<ICompetenceAnalysisImportFetchPayload>
): Generator<any, void, any> {
  const { personID, includesComments } = action.payload;

  try {
    yield put(competenceAnalysisActions.setCompetenceAnalysisLock(LoadingStatus.LOADING));

    const response = yield call(restCall, '/integration_plan/competence_analysis/import', 'get', {
      query: {
        person_id: personID,
        include_comments: includesComments,
      },
      ...authAdd(),
    });

    const competenceAnalysisImport = mapCompetenceAnalysisCompetencies(response);

    const competenceAnalysis = yield select(competenceAnalysisSelectors.competenceAnalysis);

    const updatedCompetenceAnalysis: ICompetenceAnalysis = {
      ...competenceAnalysis,
      ...competenceAnalysisImport,
    };

    yield put(competenceAnalysisActions.setCompetenceAnalysis(updatedCompetenceAnalysis));

    yield put(competenceAnalysisActions.setCompetenceAnalysisLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration competence analysis import data fetching');
    yield put(competenceAnalysisActions.setCompetenceAnalysisLock(LoadingStatus.ERROR));
  }
}

export const competenceAnalysisSagas = [
  takeLatest(competenceAnalysisActions.fetchCompetenceAnalysis, fetchCompetenceAnalysis),
  takeLatest(competenceAnalysisActions.updateCompetenceAnalysis, updateCompetenceAnalysis),
  takeLatest(
    competenceAnalysisActions.fetchCompetenceAnalysisImport,
    fetchCompetenceAnalysisImport
  ),
];
