import { call, put, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import {
  GetProfileDocument,
  GetProfileQuery,
  GetProfileQueryHookResult,
} from '@/services/graphql/system/graphql';
import { IUser } from '@/types/user';

function* fetchSelfProfile(): Generator<any, void, GetProfileQueryHookResult> {
  const client = initializeApollo();

  const response = yield call(client.query<GetProfileQuery>, {
    query: GetProfileDocument,
    errorPolicy: 'ignore',
    context: { isSystem: true },
  });

  const user: IUser | null = (response.data && response.data.users_me) ? {
    avatar: {
      filename_disk: response.data.users_me.avatar?.filename_disk ?? '',
    },
    title: response.data.users_me.title ?? '',
    id: response.data.users_me.id,
    email: response.data.users_me.email ?? '',
    last_name: response.data.users_me.last_name ?? '',
    first_name: response.data.users_me.first_name ?? '',
    settingsID: response.data.users_me.settings?.id ?? '',
  } : null;

  yield put(authActions.setUser(user));
}

export const authSagas = [
  takeLatest(authActions.fetchSelfProfile,
    fetchSelfProfile),
];
