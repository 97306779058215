import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isValid, parse, parseISO } from 'date-fns';
import fp from 'lodash/fp';
import { twMerge } from 'tailwind-merge';

import { SortByIcon } from '@/components/Icons/SortByIcon';
import { SortingAscendIcon } from '@/components/Icons/SortingAscendIcon';
import { SortingDescendIcon } from '@/components/Icons/SortingDescendIcon';
import { FRONTEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { Parameter } from '@/core/enums/parametersEnum';
import { useAppParameterValue } from '@/core/hooks/useAppParameter';
import { intToColor } from '@/core/utils/commonUtils';
import { colorTypes } from '@/styles/types';

import { CheckboxUI } from '../Checkbox/Checkbox';

import { CopyPopup } from './CopyPopup/CopyPopup';

import styles from './styles.module.scss';

type config = {
  key: string;
  direction: 'asc' | 'desc';
};

export interface ITableColumn {
  header: string;
  accessor: string;
  template?: (value: any, rowData: any) => void;
  className?: string;
  headerClassName?: string;
  isSortable?: boolean;
  isHidden?: boolean;
}

export interface ITableProps {
  columns: ITableColumn[];
  initialSelectedState?: any[];
  data: any[];
  className?: string;
  singleSelect?: boolean;
  onMultiSelectChange?: (selected: any[]) => void;
  onSingleSelectChange?: (selected: any) => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  clipboard?: any[];
  defaultSortConfig?: config;
  isDiselectAllowed?: boolean;
  preventDefault?: boolean;
}

export const Table: React.FC<ITableProps> = ({
  data,
  columns,
  onMultiSelectChange,
  onSingleSelectChange,
  initialSelectedState,
  singleSelect = false,
  header,
  footer,
  className,
  clipboard,
  defaultSortConfig = {
    key: '',
    direction: 'asc',
  },
  isDiselectAllowed = true,
  preventDefault = false,
}) => {
  const evenBgparameter = useAppParameterValue(Parameter.TableEvenBackground);
  const oddBgparameter = useAppParameterValue(Parameter.TableOddBackground);

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<config>(defaultSortConfig);
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);

  const sortedData = useMemo(() => {
    const sortedData = data ? [...data] : [];

    const { key, direction } = sortConfig;

    if (key) {
      sortedData.sort((a, b) => {
        const aValue = fp.get(key, a);
        const bValue = fp.get(key, b);

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          const aDate: Date = parse(aValue, FRONTEND_DATE_FORMAT, new Date());
          const bDate: Date = parse(bValue, FRONTEND_DATE_FORMAT, new Date());

          // Compare as dates if valid, otherwise compare as default strings
          if (isValid(aDate) && isValid(bDate)) {
            return direction === 'asc'
              ? aDate.valueOf() - bDate.valueOf()
              : bDate.valueOf() - aDate.valueOf();
          } else {
            const aValueLower = String(aValue).toLowerCase();
            const bValueLower = String(bValue).toLowerCase();
            return aValueLower.localeCompare(bValueLower) * (direction === 'asc' ? 1 : -1);
          }
        }

        return direction === 'asc' ? aValue - bValue : bValue - aValue;
      });
    }
    return sortedData;
  }, [data, sortConfig]);

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleMultiSelect = (id: any) => {
    let newSelectedRows: any[];

    if (selectedRows.includes(id)) {
      newSelectedRows = selectedRows.filter((i: any) => i !== id);
    } else {
      newSelectedRows = [...selectedRows, id];
    }

    onMultiSelectChange?.(newSelectedRows);

    if (preventDefault) {
      return;
    }

    setSelectedRows(newSelectedRows);
  };

  const handleSingleSelect = (id: any) => {
    if (selectedRows.includes(id)) {
      if (!isDiselectAllowed) {
        return;
      }

      onSingleSelectChange?.(null);

      if (preventDefault) {
        return;
      }

      setSelectedRows([]);
      return;
    }
    onSingleSelectChange?.(id);

    if (preventDefault) {
      return;
    }

    setSelectedRows([id]);
  };

  const handleSelect = (id: any) => {
    return singleSelect ? handleSingleSelect(id) : handleMultiSelect(id);
  };

  const handleSelectAllRows = (isChecked: boolean) => {
    if (!isChecked) {
      setSelectedRows([]);
      onMultiSelectChange?.([]);
    } else {
      const selected = sortedData.map((item) => item.id);
      setSelectedRows(selected);
      onMultiSelectChange?.(selected);
    }
  };

  useEffect(() => {
    if (fp.isEqual(selectedRows, initialSelectedState)) {
      return;
    }

    if (initialSelectedState) {
      setSelectedRows(initialSelectedState);
    }
  }, [initialSelectedState]);

  useEffect(() => {
    if (selectedRows.length === sortedData.length) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [selectedRows, sortedData]);

  const renderSortingIcon = useCallback(
    (columnAccessor: string): React.ReactNode => {
      if (columnAccessor !== sortConfig.key) {
        return <SortByIcon />;
      }

      if (sortConfig.direction === 'asc') {
        return <SortingAscendIcon />;
      }

      return <SortingDescendIcon />;
    },
    [sortConfig]
  );

  const filteredColumns = useMemo(() => columns.filter((item) => !item.isHidden), [columns]);

  return (
    <div className={'overflow-y-auto'}>
      {header && <div className='py-5'>{header}</div>}
      <div className={twMerge('overflow-auto max-h-full h-full', className)}>
        <table className={'w-full border-collapse whitespace-nowrap'}>
          <thead className={styles.sticky}>
            <tr>
              {!singleSelect && (
                <th className='px-5 py-3 pr-0'>
                  <CheckboxUI
                    checked={isAllChecked}
                    id='all'
                    name='all'
                    onChange={handleSelectAllRows}
                  />
                </th>
              )}
              {filteredColumns.map((column: ITableColumn) => {
                const { accessor, header, headerClassName, isSortable = true } = column;
                return (
                  <th
                    key={accessor}
                    className={twMerge(
                      `px-5 py-1 text-left text-sm font-medium tracking-wider cursor-pointer gap-2.5`,
                      headerClassName
                    )}
                    onClick={() => handleSort(accessor)}
                  >
                    <div>
                      {(header || isSortable) && (
                        <div
                          className={`p-3 w-fit flex items-center gap-2.5 hover:bg-${colorTypes.Hover} hover:text-black rounded-md`}
                        >
                          {header && <span>{header}</span>}
                          {isSortable && renderSortingIcon(accessor)}
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
              {clipboard && <th></th>}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, index) => {
              const isEven = (index + 1) % 2 == 0;

              const evenBg = intToColor(Number(evenBgparameter));
              const oddBg = intToColor(Number(oddBgparameter));

              return (
                <tr
                  key={row.id}
                  className={twMerge(
                    styles.row,
                    selectedRows.includes(row.id) && styles.row_active
                  )}
                  onClick={() => handleSelect(row.id)}
                  style={{
                    backgroundColor: isEven ? evenBg : oddBg,
                  }}
                >
                  {!singleSelect && (
                    <td className='px-5 py-4 pr-0 align-top	'>
                      <CheckboxUI
                        checked={selectedRows.includes(row.id)}
                        id={row.id}
                        name={row.id}
                      />
                    </td>
                  )}
                  {filteredColumns.map((column) => (
                    <td
                      key={column.accessor}
                      className={twMerge(
                        `align-top	 px-6 py-4 whitespace-nowrap text-sm font-medium text-${colorTypes.Primary}`,
                        column.className
                      )}
                    >
                      {column.template
                        ? column.template(fp.get(column.accessor, row), row)
                        : fp.get(column.accessor, row) ?? '--'}
                    </td>
                  ))}
                  {clipboard && (
                    <td
                      className={twMerge(
                        `align-top	 px-6 py-4 whitespace-nowrap text-sm font-medium text-${colorTypes.Primary}`
                      )}
                    >
                      <CopyPopup rowData={row} clipboard={clipboard} />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {footer && <div className='py-5'>{footer}</div>}
    </div>
  );
};
