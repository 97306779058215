import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import fp from 'lodash/fp';
import { call, put, takeLatest } from 'redux-saga/effects';

import { IMobilityForm } from '@/components/functions/Mobility/hooks/useMobilityForm';
import { authAdd, restCall } from '@/core/clients/rest';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { MobilityRefundVariant } from '@/core/enums/functions/workingTimeMobility/mobilityRefundVariantsEnum';
import {
  IMobility,
  IMobilityFetchPayload,
  IMobilityUpdatePayload,
  ITransportTypeService,
  mobilityActions,
} from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type FetchMobilityResponse = OASOutput<NormalizeOAS<typeof oas>, '/mobility', 'get', '200'>;
type FetchMobilityRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/mobility', 'get'>;

type UpdateMobilityRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/mobility', 'post'>;

const getMobilityTransportServiceContacts = (
  transportService?: FetchMobilityResponse['arrival']['transport_service'] | null
): string => {
  if (!transportService) {
    return '';
  }

  const contacts = fp.omit(['to_timestamp', 'type'], transportService);

  const values = Object.values(contacts);

  return values.join(' ');
};

const mapMobilityResponse = (response: FetchMobilityResponse): IMobility => {
  const { departure, arrival, available_transport_services, available_mobility_types } = response;

  const getSelfDriveOption = ({
    nonRefundable,
    ticketPrice,
    byMileage,
  }: {
    nonRefundable?: boolean;
    ticketPrice?: boolean;
    byMileage?: boolean;
  }): MobilityRefundVariant => {
    const options: Record<MobilityRefundVariant, boolean> = {
      [MobilityRefundVariant.NonRefundable]: nonRefundable ?? false,
      [MobilityRefundVariant.TicketPrice]: ticketPrice ?? false,
      [MobilityRefundVariant.ByMileage]: byMileage ?? false,
      [MobilityRefundVariant.Default]: false,
    };

    const selectedOption = getSelectedOption(options);

    return isEnumValue(MobilityRefundVariant, selectedOption)
      ? selectedOption
      : MobilityRefundVariant.Default;
  };

  return {
    arrival: {
      type: {
        id: arrival.mobility_type.id,
        type: isEnumValue(MobilityFormTypes, arrival.mobility_type.type)
          ? arrival.mobility_type.type
          : MobilityFormTypes.Default,
        name: arrival.mobility_type.name,
      },
      transportService: arrival.transport_service
        ? {
            type: arrival.transport_service.type,
            contacts: getMobilityTransportServiceContacts(arrival.transport_service),
            date: toClientDateInput(arrival.transport_service.to_timestamp) ?? '',
          }
        : null,
      selfDrive: arrival.self_drive
        ? {
            refundVariant:
              getSelfDriveOption({
                nonRefundable: arrival.self_drive.is_reimburse_expenses,
                ticketPrice: arrival.self_drive.is_reimburse_expenses_jobticket_amount,
                byMileage: arrival.self_drive.is_reimburse_expenses_kilometer_flat_rate,
              }) || MobilityRefundVariant.Default,
            kilometer: arrival.self_drive.kilometers_amount ?? 0,
          }
        : null,
    },
    departure: {
      type: {
        id: departure.mobility_type.id,
        type: isEnumValue(MobilityFormTypes, departure.mobility_type.type)
          ? departure.mobility_type.type
          : MobilityFormTypes.Default,
        name: departure.mobility_type.name,
      },
      transportService: departure.transport_service
        ? {
            type: departure.transport_service.type,
            contacts: getMobilityTransportServiceContacts(departure.transport_service),
            date: toClientDateInput(departure.transport_service.to_timestamp) ?? '',
          }
        : null,
      selfDrive: departure.self_drive
        ? {
            refundVariant:
              getSelfDriveOption({
                nonRefundable: departure.self_drive.is_reimburse_expenses,
                ticketPrice: departure.self_drive.is_reimburse_expenses_jobticket_amount,
                byMileage: departure.self_drive.is_reimburse_expenses_kilometer_flat_rate,
              }) || MobilityRefundVariant.Default,
            kilometer: departure.self_drive.kilometers_amount ?? 0,
          }
        : null,
    },
    availableTransportServices: available_transport_services.map<ITransportTypeService>(
      (transportService) => ({
        type: transportService.type,
        date: toClientDateInput(transportService.to_timestamp) ?? '',
        contacts: getMobilityTransportServiceContacts(transportService),
      })
    ),
    availableMobilityTypes: available_mobility_types.map((mobilityType) => ({
      type: isEnumValue<MobilityFormTypes>(MobilityFormTypes, mobilityType.type)
        ? mobilityType.type
        : MobilityFormTypes.Default,
      id: mobilityType.id,
      name: mobilityType.name,
    })),
  };
};

const mapMobilityRequest = (
  formValues: IMobilityForm,
  personID: number
): UpdateMobilityRequest['json'] => {
  const getParameters = (
    formType: MobilityFormTypes,
    context: 'arrival' | 'departure'
  ): UpdateMobilityRequest['json']['arrival'] | UpdateMobilityRequest['json']['departure'] => {
    const form = context === 'arrival' ? formValues.arrivalForm : formValues.departureForm;
    const formID = context === 'arrival' ? formValues.arrivalFormID : formValues.departureFormID;

    switch (formType) {
      case MobilityFormTypes.CarService:
        return {
          mobility_type_id: formID,
          transport_service_type_id: form.carService?.type ? form.carService.type.id : undefined,
          to_timestamp: toBackendDate(form.carService?.date),
        };
      case MobilityFormTypes.SelfDriver:
        return {
          mobility_type_id: formID,
          kilometers_amount: Number(form.selfDriver?.kilometer),
          is_reimburse_expenses:
            form.selfDriver?.refundVariant === MobilityRefundVariant.NonRefundable,
          is_reimburse_expenses_jobticket_amount:
            form.selfDriver?.refundVariant === MobilityRefundVariant.TicketPrice,
          is_reimburse_expenses_kilometer_flat_rate:
            form.selfDriver?.refundVariant === MobilityRefundVariant.ByMileage,
        };
      case MobilityFormTypes.Default:
        return {
          mobility_type_id: formID,
        };
    }
  };

  return {
    arrival: getParameters(formValues.arrivalType, 'arrival'),
    departure: getParameters(formValues.departureType, 'departure'),
    person_id: personID,
  };
};

function* fetchMobility(
  action: PayloadAction<IMobilityFetchPayload>
): Generator<any, void, FetchMobilityResponse> {
  const { personID } = action.payload;
  try {
    yield put(mobilityActions.setMobilityLock(LoadingStatus.LOADING));

    const request: FetchMobilityRequest = {
      query: {
        person_id: personID,
      },
      ...authAdd(),
    };

    const response = yield call(restCall, '/mobility', 'get', request);

    const mobility = mapMobilityResponse(response);

    yield put(mobilityActions.setMobility(mobility));
    yield put(mobilityActions.setMobilityLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on mobility fetching', error);
    yield put(mobilityActions.setMobilityLock(LoadingStatus.ERROR));
  }
}

function* updateMobility(action: PayloadAction<IMobilityUpdatePayload>): Generator<any, void, any> {
  const { personID, formValues } = action.payload;

  try {
    yield put(mobilityActions.setMobilityLock(LoadingStatus.LOADING));

    const request: UpdateMobilityRequest = {
      json: {
        ...mapMobilityRequest(formValues, personID),
      },
      ...authAdd(),
    };

    yield call(restCall, '/mobility', 'post', request);
    yield put(mobilityActions.setMobilityLock(LoadingStatus.LOADED));

    yield put(mobilityActions.fetchMobility({ personID: personID }));
  } catch (error) {
    console.log('Error on mobility updating', error);
    yield put(mobilityActions.setMobilityLock(LoadingStatus.ERROR));
  }
}

export const mobilitySagas = [
  takeLatest(mobilityActions.fetchMobility, fetchMobility),
  takeLatest(mobilityActions.updateMobility, updateMobility),
];
