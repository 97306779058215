import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';
import { IUser } from '@/types/user';

export interface IAuthState {
  profile: {
    status: LoadingStatus;
    entity: IUser | null;
  };
  accessToken: string | null;
}

const initialState: IAuthState = {
  profile: {
    status: LoadingStatus.NEVER,
    entity: null,
  },
  accessToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser | null>) => ({
      ...state,
      profile: {
        ...state.profile,
        entity: action.payload,
      },
    }),
    setAToken: (state, action: PayloadAction<string | null>) => ({
      ...state,
      accessToken: action.payload,
    }),
    fetchSelfProfile: () => {},
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
