import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Dialog, IDialog } from '@/components/Dialog/Dialog';
import { DialogContent } from '@/components/Dialog/DialogContent/DialogContent';
import { Typography } from '@/components/Typography/Typography';

export const RequiredTimeItemValueDialog: React.FC<IDialog> = ({ onConfirm }) => {
  const { t: personRequirementTimeListTranslations } = useTranslation(
    'personRequirementTimeListModal'
  );

  return (
    <Dialog open={true}>
      <DialogContent>
        <div className={'flex flex-col items-center gap-3'}>
          <Typography>
            {personRequirementTimeListTranslations('notifications.enterValue.label')}
          </Typography>
          <Button onClick={onConfirm}>
            {personRequirementTimeListTranslations('notifications.buttons.confirm.label')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
