import React from 'react';

import { ITableColumn, Table } from '@/components/Table/Table';

const data = [
  {
    id: '1',
    name: 'Unit first',
  },
  {
    id: '2',
    name: 'Unit second',
  },
  {
    id: '3',
    name: 'Unit third',
  },
];

export const OrganizationUnits: React.FC = () => {
  const columns: ITableColumn[] = [{ header: 'Name', accessor: 'name' }];

  return (
    <>
      <Table data={data} columns={columns} />
    </>
  );
};
