import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { VocationalTrainingAreaFilters } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaFilters/VocationalTrainingAreaFilters';
import { VocationalTrainingAreaTable } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaTable';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { VocationalTrainingAreaType } from '@/core/enums/functions/vocationalTrainingArea/vocationalTrainingAreaTypesEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaSelectors } from '@/core/redux/slices/functions/vocationalTrainingArea/selector';
import { vocationalTrainingAreaActions } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { efficacyAssessmentModalActions } from '@/core/redux/slices/modalsSlice/efficacyAssessment/efficacyAssessmentModalSlice';

export interface IVocationalTrainingAreaForm {
  searchByName: string;
  selectedType: VocationalTrainingAreaType | null;
  groupView: boolean;
  isFinished: boolean;
}

export const VocationalTrainingArea: React.FC = () => {
  const { t: vocationalTrainingAreaTranslations } = useTranslation('vocationalTrainingArea');

  const { selectedPersonID } = useSelectedPerson();

  const selectedTrainingArea = useAppSelector(
    vocationalTrainingAreaSelectors.selectedVocationalTrainingArea
  );

  const form = useForm<IVocationalTrainingAreaForm>({
    defaultValues: {
      searchByName: '',
      selectedType: null,
      groupView: false,
      isFinished: false,
    },
  });

  const { searchByName, selectedType, isFinished, groupView } = form.watch();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    groupView
      ? dispatch(
          vocationalTrainingAreaActions.fetchVocationalTrainingAreaGroups({
            personID: selectedPersonID,
            searchValue: searchByName,
            selectedType: selectedType,
            isFinished: isFinished,
          })
        )
      : dispatch(
          vocationalTrainingAreaActions.fetchVocationalTrainingAreaList({
            personID: selectedPersonID,
            searchValue: searchByName,
            selectedType: selectedType,
            isFinished: isFinished,
          })
        );
  }, [searchByName, selectedType, isFinished, groupView]);

  const openEfficacyAssessment = () => {
    if (!selectedPersonID || !selectedTrainingArea) {
      return;
    }

    dispatch(
      efficacyAssessmentModalActions.openEfficacyAssessmentModal({
        personID: selectedPersonID,
        measureID: selectedTrainingArea.id,
      })
    );
  };

  return (
    <FunctionWrapper>
      <FunctionContent withFooter>
        <FormProvider {...form}>
          <div className={'flex flex-col px-5 h-full overflow-hidden'}>
            <div className='flex flex-col gap-3'>
              <Typography type={'H3'}>
                {vocationalTrainingAreaTranslations('title.label')}
              </Typography>
              <VocationalTrainingAreaFilters />
              <VocationalTrainingAreaTable />
            </div>
          </div>
        </FormProvider>
      </FunctionContent>
      <FunctionFooter>
        <Button onClick={openEfficacyAssessment} disabled={!selectedTrainingArea}>
          {vocationalTrainingAreaTranslations('buttons.openEfficacyAssessment.label')}
        </Button>
      </FunctionFooter>
    </FunctionWrapper>
  );
};
