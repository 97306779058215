import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isBefore, parse } from 'date-fns';

import { Button } from '@/components/Button/Button';
import { appointmentDetailsSchemaBuilder } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/appointmentDetailsValidator';
import { IAppointmentDetailsForm } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { FRONTEND_DATE_FORMAT, FRONTEND_TIME_FORMAT } from '@/core/constants/dateFormat';
import useRequiredFieldsWatcher from '@/core/hooks/useRequiredFieldsWatcher';
import { useAppDispatch } from '@/core/redux/hooks';
import { IAppointmentDetails } from '@/core/redux/slices/functions/activityPlanning/appointmentDetails/slice';
import {
  activityPlaningAppointmentDetailsModalsActions,
  IAppointmentDetailsModalPayload,
} from '@/core/redux/slices/modalsSlice/functions/activityPlanning/activityPlaningAppointmentDetails/slice';

interface IAppointmentDetailsFooter {
  appointmentDetails: IAppointmentDetails | null;
  form: UseFormReturn<IAppointmentDetailsForm, any, undefined>;
  payload: IAppointmentDetailsModalPayload | null;
}

export const AppointmentDetailsFooter: React.FC<IAppointmentDetailsFooter> = ({
  appointmentDetails,
  form,
  payload,
}) => {
  const { t: appointmentDetailTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentDetails.footer',
  });

  const dispatch = useAppDispatch();

  let isCompletable = false;
  const isDirty = form.formState.isDirty;

  const isRequiredFieldsFilled = useRequiredFieldsWatcher(
    appointmentDetailsSchemaBuilder(appointmentDetailTranslations),
    form.watch()
  );

  if (appointmentDetails && appointmentDetails.appointmentEndDate) {
    const today = new Date();

    const parsedTime = appointmentDetails.appointmentEndTime
      ? parse(
          `${appointmentDetails.appointmentEndDate} ${appointmentDetails.appointmentEndTime}`,
          `${FRONTEND_DATE_FORMAT} ${FRONTEND_TIME_FORMAT}`,
          new Date()
        )
      : parse(appointmentDetails?.appointmentEndDate, FRONTEND_DATE_FORMAT, new Date());

    isCompletable = isBefore(parsedTime, today);
  }

  const openCompleteAppointmentModal = () => {
    if (!payload || !payload.appointmentID) {
      return;
    }

    dispatch(
      activityPlaningAppointmentDetailsModalsActions.openCompleteAppointmentModal({
        appointmentID: payload.appointmentID,
        measureID: payload.measureID,
        endTime: form.getValues('endTime'),
        startDate: form.getValues('startDate'),
        startTime: form.getValues('startTime'),
      })
    );
  };

  const handleReset = () => {
    if (isDirty) {
      form.reset();
      return;
    }

    dispatch(activityPlaningAppointmentDetailsModalsActions.closeAppointmentDetailsModal());
  };

  return (
    <div className={'flex flex-row gap-2 justify-between shadow-footer pt-2'}>
      <div className={'flex flex-row gap-2'}>
        {isCompletable && (
          <Button className={'h-auto'} onClick={openCompleteAppointmentModal}>
            {appointmentDetailTranslations('buttons.completeAppointment.label')}
          </Button>
        )}
        {isDirty && (
          <div className={'flex flex-row gap-2'}>
            <Button type={'submit'} buttonVariant={isRequiredFieldsFilled ? 'Default' : 'Disabled'}>
              {appointmentDetailTranslations('buttons.save.label')}
            </Button>
          </div>
        )}
        <Button onClick={handleReset}>
          {appointmentDetailTranslations('buttons.cancel.label')}
        </Button>
      </div>
    </div>
  );
};
