import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { QualificationObjectivesList } from '@/components/Modals/Modals/functions/integrationPlanModals/QualificationObjectivesModal/QualificationObjectivesContent/QualificationObjectivesList/QualificationObjectivesList';
import { TableHeader } from '@/components/Modals/Modals/functions/integrationPlanModals/QualificationObjectivesModal/QualificationObjectivesContent/TableHeader/TableHeader';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { qualificationDocumentationActions } from '@/core/redux/slices/functions/integrationPlan/qualificationDocumentation/qualificationDocumentationSlice';
import {
  integrationPlanModalsActions,
  IQualificationObjectivesModalItem,
} from '@/core/redux/slices/modalsSlice/functions/integrationPlan/integrationPlanModalSlice';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';
import { twMerge } from '@/core/utils/tailwindUtil';

import styles from './styles.module.scss';

interface IQualificationObjectivesContent {
  onExit: () => void;
  integrationPlanID?: number;
  personID?: number;
}

export const QualificationObjectivesContent: React.FC<IQualificationObjectivesContent> = ({
  onExit,
  integrationPlanID,
  personID,
}) => {
  const { t: qualificationObjectivesModalTranslations } = useTranslation(
    'qualificationObjectivesModal'
  );

  const { qualificationObjectives } = useAppSelector(
    integrationPlanModalsSelectors.qualificationObjectivesModal
  );

  const initialValues = qualificationObjectives.reduce(
    (accum, qualificationObjective) => ({
      ...accum,
      [qualificationObjective.id]: qualificationObjective.isSelected,
    }),
    {}
  );

  const form = useForm({
    values: initialValues,
  });

  const dispatch = useAppDispatch();

  const handleExit = () => {
    onExit();
  };

  const handleUpdateQualificationObjectives = () => {
    const currentValues: Record<string, boolean> = form.watch();

    const newQualificationObjectivesKeys = Object.keys(currentValues);

    const newQualificationObjectives = newQualificationObjectivesKeys.reduce<
      Record<string, IQualificationObjectivesModalItem>
    >((accum, qualificationObjectiveKey) => {
      const foundQualificationObjective = qualificationObjectives.find(
        (qualificationObjective) =>
          qualificationObjective.id === parseInt(qualificationObjectiveKey)
      );

      if (!foundQualificationObjective) {
        return accum;
      }

      return {
        ...accum,
        [foundQualificationObjective.id]: {
          id: foundQualificationObjective.id,
          qualificationObjectiveName: foundQualificationObjective.qualificationObjectiveName,
          fromTimestamp: foundQualificationObjective.fromTimestamp,
          toTimestamp: foundQualificationObjective.toTimestamp,
          isSelected: currentValues[foundQualificationObjective.id],
        },
      };
    }, {});

    dispatch(
      qualificationDocumentationActions.updateQualificationObjectives(newQualificationObjectives)
    );

    if (integrationPlanID && personID) {
      dispatch(
        integrationPlanModalsActions.updateQualificationObjectives({
          integrationPlanID,
          personID,
          qualificationsObjectives: currentValues,
        })
      );
    }

    handleExit();
  };

  useEffect(() => {
    if (integrationPlanID && personID)
      dispatch(
        integrationPlanModalsActions.fetchQualificationObjectivesModal({
          integrationPlanID,
          personID,
        })
      );
  }, []);

  return (
    <div className={'flex flex-col gap-4 min-w-[600px] max-w-[1000px]'}>
      <Typography type={'H3'} className={'px-4 pt-4'}>
        {qualificationObjectivesModalTranslations('title.label')}
      </Typography>
      <div>
        <TableHeader />
        <FormProvider {...form}>
          <div className={twMerge('flex flex-col max-h-[50vh] overflow-y-auto', styles.scrollbar)}>
            <QualificationObjectivesList qualificationObjectives={qualificationObjectives} />
          </div>
        </FormProvider>
      </div>
      <div className={'self-end w-auto flex flex-row gap-3 px-4 pb-4'}>
        {!fp.isEqual(initialValues, form.watch()) && (
          <Button onClick={handleUpdateQualificationObjectives}>
            {qualificationObjectivesModalTranslations('buttons.adoptGoals.label')}
          </Button>
        )}
        <Button onClick={handleExit} buttonVariant={'Secondary'}>
          {qualificationObjectivesModalTranslations('buttons.closeSection.label')}
        </Button>
      </div>
    </div>
  );
};
