import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Input } from '@/components/Input/Input';
import { RequiredTimeItemValueDialog } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/Dialogs/RequiredTimeItemValueDialog';
import {
  IPersonRequirementTimeGroupItemForm,
  IPersonRequirementTimeListForm,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/hooks/usePersonRequirementTimeLIstForm';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { IPersonRequirementTimeItem } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

interface IPersonRequirementTimeListGroupItem extends IPersonRequirementTimeItem {
  id: number;
  groupFormIndex: number;
  formIndex: number;
}

const GROUP_COUNT = 4;

export const PersonRequirementTimeListGroupItem: React.FC<IPersonRequirementTimeListGroupItem> = ({
  name,
  id,
  formIndex,
  groupFormIndex,
}) => {
  const { control, watch, setValue } = useFormContext<IPersonRequirementTimeListForm>();
  const [dialogIsOpened, setDialogIsOpened] = useState<boolean>(false);

  const cycleID = watch(
    `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.cycleID`
  );
  const value = watch(`personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.value`);
  const isInGroup = watch(
    `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.isInGroup`
  );
  const minutesPerDay = watch(
    `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.minutesPerDay`
  );

  const isOnlyActive = watch('isOnlyActive');
  const hidden = isOnlyActive && !minutesPerDay;

  const { availablePersonRequirementTimeCycles } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementTimeListModal
  );

  const cycleValues: ISelectItem[] = availablePersonRequirementTimeCycles.map<ISelectItem>(
    (timeCycle) => ({
      id: String(timeCycle.id),
      component: timeCycle.name,
    })
  );

  useEffect(() => {
    if (!value) {
      const defaultValue: IPersonRequirementTimeGroupItemForm = {
        id: id,
        minutesPerDay: null,
        isInGroup: false,
        value: null,
        comment: null,
        cycleID: null,
      };
      setValue(
        `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}`,
        defaultValue
      );

      return;
    }

    const cycle = availablePersonRequirementTimeCycles.find((cycle) => cycle.id === cycleID);

    const divisor = cycle ? cycle.divisor || 1 : 1;
    const adjustedValue = isInGroup ? value / GROUP_COUNT : value;
    const minutesPerDay = (adjustedValue / divisor).toFixed(2);

    setValue(
      `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.minutesPerDay`,
      parseFloat(minutesPerDay)
    );
    setValue(`personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.id`, id);
  }, [value, isInGroup, cycleID]);

  const handleSelectChange = (id: string) => {
    if (!value) {
      setDialogIsOpened(true);
      return;
    }

    setValue(
      `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.cycleID`,
      Number(id)
    );
  };

  const handleCheck = (isChecked: boolean) => {
    if (!value) {
      setDialogIsOpened(true);
      return;
    }

    setValue(
      `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.isInGroup`,
      isChecked
    );
  };

  return hidden ? null : (
    <div className={'grid grid-cols-12 items-center gap-x-5'}>
      <Typography className={'col-span-4'}>{name}</Typography>
      <Input
        control={control.register(
          `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.value`
        )}
        className={'text-center w-[100px]'}
        type={'number'}
      />
      <div className={'flex items-center justify-center'}>
        <Checkbox
          name={`personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.isInGroup`}
          onClick={handleCheck}
        />
      </div>
      <Select
        values={cycleValues}
        initialValueID={String(cycleID)}
        className={'col-span-2'}
        onChange={handleSelectChange}
      />
      <Input
        control={control.register(
          `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.minutesPerDay`
        )}
        readOnly={true}
      />
      <Input
        control={control.register(
          `personRequirementTimeListGroups.${groupFormIndex}.items.${formIndex}.comment`
        )}
        containerClassName={'col-span-3'}
      />
      {dialogIsOpened && <RequiredTimeItemValueDialog onConfirm={() => setDialogIsOpened(false)} />}
    </div>
  );
};
