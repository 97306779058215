import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';

export const VocationalTrainingAreaFinishedMeasures: React.FC = () => {
  const { t: vocationalTrainingAreaTranslations } = useTranslation('vocationalTrainingArea', {
    keyPrefix: 'filters.finishedMeasures',
  });

  return (
    <div className={'flex flex-row gap-3'}>
      <Typography>{vocationalTrainingAreaTranslations('label')}</Typography>
      <Checkbox name={'isFinished'} />
    </div>
  );
};
