import React, { DetailedHTMLProps, HTMLAttributes, HTMLInputTypeAttribute } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

import { CrossIcon } from '@/components/Icons/CrossIcon';
import { SearchIcon } from '@/components/Icons/SearchIcon';
import { geInputVariant, InputVariants } from '@/components/Input/helpers/inputVariants';
import { usePermissions } from '@/components/PermissionsContext/PermissionsContext';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { useSchemaContext } from '../SchemaContext/SchemaContext';

export interface IInput
  extends DetailedHTMLProps<HTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  control: UseControllerProps;
  resetButton?: boolean;
  label?: string | React.ReactNode;
  errorMessage?: string;
  type?: HTMLInputTypeAttribute;
  inputVariant?: InputVariants;
  handleChange?: (previousValue: string, newValue: string) => string;
  onAfterChange?: (value: string) => void;
  className?: string;
  containerClassName?: string;
  wrapperClassName?: string;
  rightIcon?: React.ReactNode;
  readOnly?: boolean;
  preventDefault?: boolean;
}

export const Input: React.FC<IInput> = ({
  control,
  label,
  errorMessage,
  handleChange,
  onAfterChange,
  className,
  containerClassName,
  wrapperClassName,
  rightIcon,
  readOnly,
  resetButton = true,
  preventDefault = false,
  type = 'text',
  inputVariant = 'Default',
  ...rest
}) => {
  const {
    field: { onChange: controllerOnChange, ...field },
    fieldState,
  } = useController(control);

  const permissions = usePermissions(field.name);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (preventDefault || readOnly || permissions.isReadOnly) {
      return;
    }

    if (handleChange) {
      controllerOnChange(handleChange(field.value, event.target.value));
      return;
    }
    controllerOnChange(event.target.value);
    onAfterChange?.(event.target.value);
  };

  const handleFieldReset = () => {
    controllerOnChange('');
  };

  const isValueExist = field.value !== null && field.value !== undefined && field.value !== '';
  const isResetable =
    resetButton &&
    isValueExist &&
    type !== 'date' &&
    !rightIcon &&
    !field.disabled &&
    !readOnly &&
    !permissions.isReadOnly;

  const { isRequiredField } = useSchemaContext(field.name);

  return (
    <div className={twMerge('flex flex-col gap-3', containerClassName)}>
      {label && (
        <div>
          {typeof label === 'string' ? (
            <Typography as='label' htmlFor={field.name}>
              {label}
            </Typography>
          ) : (
            label
          )}{' '}
          {isRequiredField && '*'}
        </div>
      )}
      <div className={twMerge('relative', wrapperClassName)}>
        <input
          onChange={onChange}
          type={type}
          className={twMerge(
            '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
            geInputVariant(inputVariant),
            fieldState.error && `border-${colorTypes.Error} focus:border-${colorTypes.Error}`,
            resetButton && 'pe-[40px]',
            className
          )}
          readOnly={readOnly || permissions.isReadOnly}
          {...field}
          {...rest}
          value={isValueExist ? String(field.value) : ''}
        />
        {(inputVariant === 'Search' || inputVariant === 'WidgetSearch') && (
          <SearchIcon className={'absolute left-3 top-1/2 transform -translate-y-1/2'} />
        )}
        {isResetable && (
          <CrossIcon
            className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-${colorTypes.Disabled} cursor-pointer`}
            onClick={handleFieldReset}
          />
        )}
        {rightIcon && rightIcon}
      </div>
      {fieldState.error && (
        <Typography type={'14-R'} color={'Error'} className={'mt-1'}>
          {fieldState.error.message}
        </Typography>
      )}
      {!fieldState.error && errorMessage && (
        <Typography type={'14-R'} color={'Disabled'} className={'mt-1'}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
