import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  IVocationalTrainingAreaDocumentsModalFetchPayload,
  vocationalTrainingAreaModalActions,
} from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type VocationalTrainingAreaDocumentsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get',
  '200'
>;
type VocationalTrainingAreaDocumentsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure/document',
  'get'
>;

function* fetchVocationalTrainingAreaDocuments(
  action: PayloadAction<IVocationalTrainingAreaDocumentsModalFetchPayload>
): Generator<any, void, VocationalTrainingAreaDocumentsResponse> {
  const { measureID } = action.payload;

  yield put(
    vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocumentsLock(LoadingStatus.LOADING)
  );

  try {
    const request: VocationalTrainingAreaDocumentsRequest = {
      query: {
        measure_id: measureID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/vocational_training_area/measure/document',
      'get',
      request
    );

    yield put(
      vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocuments(response.documents)
    );

    yield put(
      vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocumentsLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    yield put(
      vocationalTrainingAreaModalActions.setVocationalTrainingAreaDocumentsLock(LoadingStatus.ERROR)
    );
  }
}

export const vocationalTrainingAreaModalsSagas = [
  takeLatest(
    vocationalTrainingAreaModalActions.fetchVocationalTrainingAreaDocuments,
    fetchVocationalTrainingAreaDocuments
  ),
];
