export const DASHBOARD_URL = '/dashboard';
export const AUTH_URL = '/auth';
export const FUNCTIONS_URL = '/persons/functions';
export const PERSONS_URL = '/persons';
export const MEDICATION_ADMINISTRATION_URL = '/medication/administration';
export const REPORTS_URL = '/reports'

/* Modals */

export const PERSON_WIDGET_MODAL_URL = '?modal=person-widget-modal';
export const WIDGET_CONTROL_MODAL_URL = '?modal=widget-control-modal';
export const ADD_PERSON_MODAL_URL = '?modal=add-person-modal';
