import React from 'react';

export const RadioButtonCheckedIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 24 24'
    fill='currentColor'
    strokeWidth='2'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8z'
    />
  </svg>
);
