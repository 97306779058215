export enum Parameter {
  TableEvenBackground = 'W_TableEvenBackgroundColour',
  TableOddBackground = 'W_TableOddBackgroundColour',
  SelfAssessmentEvenBackgroundColor = 'W_SEFullListEvenItemBackgroundColour',
  SelfAssessmentOddBackgroundColor = 'W_SEFullListOddItemBackgroundColour',
  InternalDifferentiationItemEvenBackground = 'W_InternalDifferentiationEvenItemBackgroundColor',
  InternalDifferentiationItemOddBackground = 'W_InternalDifferentiationOddItemBackgroundColor',
  VocationalTrainingAreaEvenItemBackgroundColor = 'W_VocationalTrainingAreaEvenItemBackgroundColor',
  VocationalTrainingAreaOddItemBackgroundColor = 'W_VocationalTrainingAreaOddItemBackgroundColor',
  AdditionalPersonRequirementConfirmCreatingText = 'ask4saveHBbeantragtam',
  AdditionalPersonRequirementResponsibleLeaderRequired = 'Verantwortlicher4HBGIsPflicht',
}
