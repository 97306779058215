import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { TestPerformerSelect } from '@/components/Modals/Modals/functions/testingResultModals/TestPerformerSelect/TestPerformerSelect';
import { TestProcedureSelect } from '@/components/Modals/Modals/functions/testingResultModals/TestProcedureSelect/TestProcedureSelect';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { IAppliedTestProcedure } from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export const CreateAppliedTestProcedureModalContent: React.FC = () => {
  const { t: createAppliedTestProcedureModalTranslations } = useTranslation(
    'createAppliedTestProcedureModal'
  );

  const { createAppliedTestProcedureLock } = useAppSelector(
    testingResultModalsSelectors.createAppliedTestingResultModal
  );

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  const dispatch = useAppDispatch();

  const defaultValues: Omit<IAppliedTestProcedure, 'id'> = {
    procedure: undefined,
    performedBy: undefined,
    testResult: undefined,
    performedTimestamp: undefined,
  };

  const form = useForm<Omit<IAppliedTestProcedure, 'id'>>({ defaultValues });
  const currentValues = form.watch();

  const handleClose = () => {
    dispatch(testingResultModalsActions.closeCreateAppliedTestProcedureResultModal());
  };

  const handleCreate = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      testingResultModalsActions.createAppliedTestProcedure({
        appliedTestProcedure: currentValues,
        personID: selectedPersonID,
      })
    );
  };

  const handleCancel = () => {
    form.reset();
  };

  useEffect(() => {
    if (createAppliedTestProcedureLock === LoadingStatus.LOADED) {
      handleClose();
    }
  }, [createAppliedTestProcedureLock]);

  const allValuesFilled =
    currentValues.procedure &&
    currentValues.procedure.id !== undefined &&
    currentValues.performedTimestamp &&
    currentValues.testResult &&
    currentValues.performedBy &&
    currentValues.performedBy.id !== undefined;

  return (
    <Modal onExit={handleClose}>
      <div className={'flex flex-col gap-4 min-w-[600px]'}>
        <FormProvider {...form}>
          <Typography type={'H3'}>
            {createAppliedTestProcedureModalTranslations('title.label')}
          </Typography>
          <div className={'flex flex-row'}>
            <TestProcedureSelect />
            <Button buttonVariant={'Transparent'} buttonStyle={'Icon'}>
              <ArrowRightIcon />
            </Button>
          </div>
          <div className={'flex flex-row gap-2 items-center justify-between'}>
            <div className={'flex flex-row items-center gap-4'}>
              <Typography>
                {createAppliedTestProcedureModalTranslations('fields.createdDate.label')}
              </Typography>
              <DatePicker control={form.register('performedTimestamp')} />
            </div>
            <div className={'flex flex-row items-center gap-4'}>
              <Typography className={'shrink-0'}>
                {createAppliedTestProcedureModalTranslations('fields.createdBy.label')}
              </Typography>
              <TestPerformerSelect />
            </div>
          </div>
          <div className={'flex flex-col gap-2 mt-4'}>
            <Typography>
              {createAppliedTestProcedureModalTranslations('fields.procedureResult.label')}
            </Typography>
            <TextArea control={form.register('testResult')} />
          </div>
        </FormProvider>
        <div className={'flex flex-row justify-end gap-3'}>
          {form.formState.isDirty ? (
            <>
              <Button onClick={handleCreate} disabled={!allValuesFilled}>
                {createAppliedTestProcedureModalTranslations('buttons.save.label')}
              </Button>
              <Button buttonVariant={'Secondary'} onClick={handleCancel}>
                {createAppliedTestProcedureModalTranslations('buttons.cancel.label')}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleClose}>
                {createAppliedTestProcedureModalTranslations('buttons.close.label')}
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
