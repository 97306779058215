export enum FunctionViews {
  IntegrationPlan = 'integrationPlan',
  SelfAssesment = 'selfAssesment',
  PerformanceProfile = 'performanceProfile',
  ResearchResult = 'researchResult',
  PersonGeneralData = 'personGeneralData',
  WheelView = 'wheelView',
  Mobility = 'mobility',
  WorkingTime = 'workingTime',
  Diagnostics = 'diagnostics',
  LegalGuardian = 'legalGuardian',
  SpecialistGeneralData = 'specialistGeneralData',
  Medication = 'medication',
  OrganizationalUnits = 'organizationalUnits',
  AdditionalPersonRequirement = 'additionalPersonRequirement',
  ActivityPlanning = 'activityPlanning',
}
