import { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import fp from 'lodash/fp';

import {
  IDepartmentsState,
  ILocationsChecksState,
  usePersonPageStoreSelector,
} from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';

export function useDepartmentListForm() {
  const [locationsCheckState, setLocationCheckState] = usePersonPageStoreSelector(
    (store) => store.locationsChecksState
  );

  const form = useForm({ defaultValues: locationsCheckState });
  const watcher = useWatch(form);

  useEffect(() => {
    if (!fp.isEqual(watcher, locationsCheckState)) {
      const watcherDepartments = watcher.departments;
      const contextDepartments = locationsCheckState?.departments;
      const watcherCheckAll = watcher.departments_check_all;
      const contextCheckAll = locationsCheckState?.departments_check_all;

      if (watcherDepartments) {
        const watcherIntermediateDepartment = Object.keys(watcherDepartments).some(
          (departmentKey) => !watcherDepartments?.[departmentKey]?.value
        );

        const newDepartments = Object.keys(watcherDepartments).reduce(
          (newFormAccumulator: IDepartmentsState, departmentKey) => {
            const watcherDepartmentValue = watcherDepartments[departmentKey]?.value;
            const contextDepartmentValue = contextDepartments?.[departmentKey]?.value;
            const watcherDepartmentLocations = watcherDepartments[departmentKey]?.locations;
            const contextDepartmentLocations = contextDepartments?.[departmentKey]?.locations;

            if (!fp.isEqual(watcherDepartmentLocations, contextDepartmentLocations)) {
              let locationsValues = null;
              let department;
              if (watcherDepartmentLocations) {
                locationsValues = Object.values(watcherDepartmentLocations);

                if (locationsValues.every((locationValue) => locationValue)) {
                  department = { value: true, intermediate: false };
                } else if (locationsValues.every((locationsValues) => !locationsValues)) {
                  department = { value: false, intermediate: false };
                } else {
                  department = { value: false, intermediate: true };
                }
              } else {
                department = { value: watcherDepartmentValue, intermediate: false };
              }
              return {
                ...newFormAccumulator,
                [departmentKey]: {
                  ...department,
                  locations: watcherDepartmentLocations,
                },
              };
            }

            if (!fp.isEqual(watcherDepartmentValue, contextDepartmentValue)) {
              const watcherLocations = watcherDepartments[departmentKey]?.locations;
              let newLocationsState = {};
              if (watcherLocations) {
                newLocationsState = Object.keys(watcherLocations).reduce(
                  (acc, locationKey) => ({
                    ...acc,
                    [locationKey]: watcherDepartmentValue,
                  }),
                  {}
                );
              }
              return {
                ...newFormAccumulator,
                [departmentKey]: {
                  value: watcherDepartmentValue,
                  intermediate: false,
                  locations: newLocationsState,
                },
              };
            }

            if (watcherIntermediateDepartment && !watcherCheckAll) {
              return {
                ...newFormAccumulator,
                [departmentKey]: watcherDepartments[departmentKey],
              };
            }

            if (!fp.isEqual(watcherCheckAll, contextCheckAll)) {
              const watcherLocations = watcherDepartments[departmentKey]?.locations;
              let newLocationsState = {};
              if (watcherLocations) {
                newLocationsState = Object.keys(watcherLocations).reduce(
                  (acc, locationKey) => ({
                    ...acc,
                    [locationKey]: watcherCheckAll,
                  }),
                  {}
                );
              }
              return {
                ...newFormAccumulator,
                [departmentKey]: {
                  value: watcherCheckAll,
                  intermediate: false,
                  locations: newLocationsState,
                },
              };
            }

            return {
              ...newFormAccumulator,
              [departmentKey]: watcherDepartments[departmentKey],
            };
          },
          {}
        );

        const newFormIntermediateDepartment = Object.keys(newDepartments).some(
          (departmentKey) => !newDepartments?.[departmentKey]?.value
        );
        const isCheckedAll = !newFormIntermediateDepartment;

        const newForm: ILocationsChecksState = {
          departments_check_all: isCheckedAll,
          departments: newDepartments,
        };

        if (!fp.isEqual(locationsCheckState, newForm)) {
          form.reset(newForm);
          setLocationCheckState({ locationsChecksState: newForm });
        }
      }
    }
  }, [form, locationsCheckState, setLocationCheckState, watcher]);

  return useMemo(() => {
    return form;
  }, [form]);
}
