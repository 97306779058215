import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { PersonFavoriteButton } from '@/components/PersonFavoriteButton/PersonFavoriteButton';
import { PersonFunctionsShortcuts } from '@/components/PersonFunctionsShortcuts/PersonFunctionsShortcuts';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { commonModalsSelectors } from '@/core/redux/slices/modalsSlice/common/selector';
import { generateName } from '@/core/utils/commonUtils';
import { IPerson } from '@/types/person';

import styles from './styles.module.scss';

export const Person: React.FC<IPerson> = ({ name, surname, id, functions }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const modalContext = useAppSelector(commonModalsSelectors.addPersonModal);

  const personName = generateName({ name, surname });
  const personFieldName = `persons.person_id=${id}`;

  const formContext = useFormContext();
  const state = formContext.watch(personFieldName);

  useEffect(() => {
    if (state === undefined) {
      formContext.setValue(personFieldName, false);
    }
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={clsx(
        styles.container,
        'flex flex-row items-center justify-between py-2 cursor-pointer',
        {
          [styles.checked]: state,
        }
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='flex'>
        <Checkbox name={personFieldName} className='mr-2 shrink-0' id={personFieldName} />
        <label className={'cursor-pointer'} htmlFor={personFieldName}>
          <Typography type='16-R' color='Primary'>
            {personName}
          </Typography>
        </label>
      </div>
      <PersonFunctionsShortcuts
        personID={id}
        modalContext={modalContext}
        functions={functions}
        isHovered={isHovered}
      />
      <PersonFavoriteButton isHovered={isHovered} personID={id} />
    </div>
  );
};
