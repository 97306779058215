import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IMedication {
  id: number;
  name: string;
  isActive: boolean;
  orderedBy: string | null;
  orderedFromTimestamp: string | null;
  orderedToTimestamp: string | null;
  dosages: string | null;
  isDemanded: boolean | null;
  isNotDispensed: boolean;
  dosageForm: string | null;
  note: string | null;
}

export interface IDosage {
  dosage?: string | null;
  inWorkshop?: boolean | null;
  daytime?: string | null;
  id: number;
}

export interface IMedicationDetails {
  name?: string;
  isNotDispensed: boolean;
  orderedBy?: string | null;
  orderedFromTimestamp?: string | null;
  orderedToTimestamp?: string | null;
  isDemanded?: boolean | null;
  dosageForm?: {
    value?: string | null;
    id: number;
  } | null;
  dosageFormID?: string | null;
  note?: string | null;
  isRemarkedOnAwarding?: boolean | null;
  documentLink?: string | null;
  id: number;
  dosages: IDosage[];
  prescriptionFile?: IPrescriptionFile | null;
}

export interface IPrescriptionFile {
  fileName: string;
  fileContent: string;
}

export interface IMedicationData {
  name: string | null;
  isNotDispensed: boolean;
  isRemarkedOnAwarding?: boolean | null;
  dosageFormId?: number | null;
  orderedBy?: string | null;
  orderedFromTimestamp?: string | null;
  orderedToTimestamp?: string | null;
  dosages: {
    id: number;
    dosage?: string | null;
    inWorkshop?: boolean | null;
  }[];
  isDemanded?: boolean | null;
  note?: string | null;
  prescriptionFile: IPrescriptionFile | null;
}

export interface IMedicationsFetchPayload {
  personID: number;
  isActive?: boolean;
}

export interface IMedicationDetailsFetchPayload {
  personID: number;
  medicationID: number;
}

export interface IMedicationDownloadDocumentPayload {
  medicationID: number;
}

export interface IMedicationUpdatePayload {
  personID: number;
  medicationID: number;
  medicationData: IMedicationData;
}

export interface IMedicationCreatePayload {
  personID: number;
  medicationData: IMedicationData;
}

export interface IMedicationStopAdministratingPayload {
  personID: number;
  medicationID: number;
  date: string;
}

export interface IMedicationState {
  medications: IMedication[];
  medicationsLock: LoadingStatus;
  downloadMedicationDocumentLock: LoadingStatus;
  medicationDetails: IMedicationDetails | null;
  medicationDetailsLock: LoadingStatus;
  updateMedicationsLock: LoadingStatus;
  createMedicationsLock: LoadingStatus;
  stopAdministratingLock: LoadingStatus;
}

const initialState: IMedicationState = {
  //medications
  medications: [],
  medicationsLock: LoadingStatus.NEVER,
  downloadMedicationDocumentLock: LoadingStatus.NEVER,
  //details
  medicationDetails: null,
  medicationDetailsLock: LoadingStatus.NEVER,
  //update
  updateMedicationsLock: LoadingStatus.NEVER,
  //create
  createMedicationsLock: LoadingStatus.NEVER,
  //stop administrating
  stopAdministratingLock: LoadingStatus.NEVER,
};

export const medicationSlice = createSlice({
  name: 'medication',
  initialState,
  reducers: {
    //medications
    fetchMedications: (state, action: PayloadAction<IMedicationsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMedicationsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      addressDataLock: action.payload,
    }),
    setMedications: (state, action: PayloadAction<IMedication[]>) => ({
      ...state,
      medications: action.payload ?? [],
    }),
    //details
    fetchMedicationDetails: (state, action: PayloadAction<IMedicationDetailsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMedicationDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      medicationDetailsLock: action.payload,
    }),
    setMedicationDetails: (state, action: PayloadAction<IMedicationDetails | null>) => ({
      ...state,
      medicationDetails: action.payload,
    }),
    downloadMedicationDocument: (
      state,
      action: PayloadAction<IMedicationDownloadDocumentPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setDownloadMedicationDocumentLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      downloadMedicationDocumentLock: action.payload,
    }),
    //update
    updateMedication: (state, action: PayloadAction<IMedicationUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setUpdateMedicationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateMedicationsLock: action.payload,
    }),
    //create
    createMedication: (state, action: PayloadAction<IMedicationCreatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setCreateMedicationLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateMedicationsLock: action.payload,
    }),
    //stop administrating
    stopAdministrating: (state, action: PayloadAction<IMedicationStopAdministratingPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setStopAdministratingLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      stopAdministratingLock: action.payload,
    }),
  },
});

export const medicationReducer = medicationSlice.reducer;
export const medicationActions = medicationSlice.actions;
