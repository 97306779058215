import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import FormRender from '@/components/FormRender/FormRender';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { specialistGeneralDataSelectors } from '@/core/redux/slices/functions/specialistGeneralData/generalData/selectors';
import {
  IGeneralData,
  specialistGeneralDataActions,
} from '@/core/redux/slices/functions/specialistGeneralData/generalData/specialistGeneralDataSlice';

import { firstColumn, secondColumn } from './helpers/formTemplates';

interface IGeneralDataComponent {
  userID: number;
}

export const GeneralData: React.FC<IGeneralDataComponent> = ({ userID }) => {
  const { t: specialistGeneralData } = useTranslation('specialistGeneralData');
  const methods = useForm<IGeneralData>();

  const generalData = useAppSelector(specialistGeneralDataSelectors.generalData);

  const dispatch = useAppDispatch();

  const onSubmit = (data: IGeneralData) => console.log(data);

  useEffect(() => {
    if (!userID) {
      return;
    }

    dispatch(
      specialistGeneralDataActions.fetchGeneralData({
        userID: userID,
      })
    );
  }, [dispatch, userID]);

  useEffect(() => {
    if (!generalData) {
      return;
    }

    methods.reset(generalData);
  }, [generalData]);

  return (
    <div className='w-full flex flex-col gap-4'>
      <Typography type='H3'>{specialistGeneralData('title.label')}</Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className='flex gap-4 w-full'>
          <div className='flex gap-6 grow'>
            <div className='w-1/2'>
              <FormRender
                list={firstColumn}
                translationContext={specialistGeneralData}
                className='flex flex-wrap'
              />
            </div>
            <div className='w-1/2'>
              <FormRender
                list={secondColumn}
                translationContext={specialistGeneralData}
                className='flex flex-wrap'
              />
            </div>
          </div>
          <Button type='submit'>Submit</Button>
        </form>
      </FormProvider>
    </div>
  );
};
