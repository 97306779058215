import React from 'react';

import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { useAppSelector } from '@/core/redux/hooks';
import { integrationPlanModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/integrationPlan/selector';
import { LoadingStatus } from '@/types/loadingStatus';

interface IPrintVariantsList {
  printVariants: Record<string, string>;
}

export const PrintVariantsList: React.FC<IPrintVariantsList> = ({ printVariants }) => {
  const loadingStatus = useAppSelector(
    integrationPlanModalsSelectors.createIntegrationPlanDocumentsPrintVariantsLoadingStatus
  );

  const printVariantsKeys = Object.keys(printVariants);

  return (
    <Skeleton
      trigger={loadingStatus === LoadingStatus.LOADING}
      className={'mt-4 flex flex-col gap-2'}
    >
      {printVariantsKeys.length > 1 &&
        printVariantsKeys.map((printVariantKey) => (
          <CheckboxItem
            fieldName={`forms.${printVariantKey}`}
            key={printVariantKey}
            label={printVariants[printVariantKey]}
            className={'flex justify-between'}
          />
        ))}
    </Skeleton>
  );
};
