import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { differenceInMinutes, parse } from 'date-fns';
import fp from 'lodash/fp';

import { IWorkingTime } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';

interface IWeeklyWorkingTime {
  workingTimeSum: number;
  pauseTimeSum: number;
  days: number;
}

export function useWorkingTime(
  currentFormState: IWorkingTime | null,
  setCurrentFormState: React.Dispatch<React.SetStateAction<IWorkingTime | null>>
) {
  const { reset, watch } = useFormContext<IWorkingTime>();

  const workingDays = watch('workingWeek.workingDays');
  const currentForm = watch();

  useEffect(() => {
    if (fp.isEqual(currentFormState, currentForm) || !workingDays) {
      return;
    }

    const workingDaysKeys = Object.keys(workingDays);

    const weeklyWorkingTime = workingDaysKeys.reduce<IWeeklyWorkingTime>(
      (accum, dayKey) => {
        const fromTime = workingDays[dayKey]?.fromDateStamp;
        const toTime = workingDays[dayKey]?.toDateStamp;

        if (!fromTime || !toTime || fromTime === '00:00' || toTime === '00:00') {
          return accum;
        }

        const pauseDuration = workingDays[dayKey]?.pauseDuration ?? 0;
        const parsePauseDuration = Number(pauseDuration);

        const parsedFromTime = parse(fromTime, 'HH:mm', new Date());
        const parsedToTime = parse(toTime, 'HH:mm', new Date());

        const workingTimeDuration = differenceInMinutes(parsedToTime, parsedFromTime);

        accum.workingTimeSum += workingTimeDuration;
        accum.pauseTimeSum += parsePauseDuration <= workingTimeDuration ? parsePauseDuration : 0;
        accum.days += workingTimeDuration > 0 ? 1 : 0;

        return accum;
      },
      {
        workingTimeSum: 0,
        pauseTimeSum: 0,
        days: 0,
      }
    );

    const newForm: IWorkingTime = {
      ...currentForm,
      workingWeek: {
        ...currentForm.workingWeek,
        sumGross: Number((weeklyWorkingTime.workingTimeSum / 60).toFixed(2)),
        sumNet: Number(
          ((weeklyWorkingTime.workingTimeSum - weeklyWorkingTime.pauseTimeSum) / 60).toFixed(2)
        ),
        workingDaysCount: weeklyWorkingTime.days,
      },
    };

    setCurrentFormState(newForm);
    reset(newForm, { keepDefaultValues: true, keepDirty: true, keepErrors: true });
  }, [currentForm, workingDays, currentFormState, reset, setCurrentFormState]);
}
