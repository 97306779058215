import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const EducationCoursePrognosis: React.FC = () => {
  const { t: statusReportTranslations } = useTranslation('statusReport', {
    keyPrefix: 'educationCoursePrognosis',
  });

  return (
    <Accordion
      title={<Typography type={'H3'}>{statusReportTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-4`}
    >
      <div className={'flex flex-col mt-4'}>
        <CheckboxItem
          label={statusReportTranslations('fields.isAdditionalRequirements.label')}
          fieldName={'isAdditionalRequirements'}
          className={'justify-between'}
        />
        <CheckboxItem
          label={statusReportTranslations('fields.isContinued.label')}
          fieldName={'isContinued'}
          className={'justify-between'}
        />
        <CheckboxItem
          label={statusReportTranslations('fields.isVocationalTrainingPlaceRealistic.label')}
          fieldName={'isVocationalTrainingPlaceRealistic'}
          className={'justify-between'}
        />
        <CheckboxItem
          label={statusReportTranslations('fields.isTransitionToGeneralLaborMarketRealistic.label')}
          fieldName={'isTransitionToGeneralLaborMarketRealistic'}
          className={'justify-between'}
        />
        <CheckboxItem
          label={statusReportTranslations('fields.isDiscussionRequired.label')}
          fieldName={'isDiscussionRequired'}
          className={'justify-between'}
        />
      </div>
    </Accordion>
  );
};
