import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@/components/Overlays/Modal/Modal';
import { QuestionnaireAnswerOptions } from '@/core/enums/functions/selfAssessment/questionnaireAnswersEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { simpleSelfAssesmentSelectors } from '@/core/redux/slices/functions/simpleSelfAssesment/selectors';
import {
  ISelfAssessmentAnswer,
  ISelfAssessmentQuestion,
  simpleSelfAssesmentActions,
} from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { selfAssesmentModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/selfAssesment/selector';
import { selfAssesmentModalsActions } from '@/core/redux/slices/modalsSlice/functions/selfAssesment/selfAssesmentModalsSlice';
import { colorTypes } from '@/styles/types';

import { SimpleQuestionnaireForm } from './SimpleQuestionnaireForm/SimpleQuestionnaireForm';
import { SimpleQuestionnaireNavigation } from './SimpleQuestionnaireNavigation/SimpleQuestionnaireNavigation';

const ModalContent: React.FC = () => {
  const { t: selfAssessmentTranslations } = useTranslation('selfAssesment');

  const m2sID = useAppSelector(selfAssesmentModalsSelectors.m2sID);
  const availableQuestionnaires = useAppSelector(
    simpleSelfAssesmentSelectors.availableQuestionnaires
  );
  const personQuestionnaireAnswers = useAppSelector(
    simpleSelfAssesmentSelectors.personQuestionnaireAnswers
  );
  const personQuestionnaire = useAppSelector(simpleSelfAssesmentSelectors.personQuestionnaire);

  const [questions, setQuestions] = useState<ISelfAssessmentQuestion[]>([]);
  const [question, setQuestion] = useState<ISelfAssessmentQuestion | null>(null);
  const [answer, setAnswer] = useState<ISelfAssessmentAnswer | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!m2sID) {
      return;
    }
    dispatch(
      simpleSelfAssesmentActions.fetchPersonQuestionnaireAnswers({
        variables: {
          m2sID,
        },
      })
    );
  }, [m2sID]);

  useEffect(() => {
    if (!m2sID || !availableQuestionnaires || !personQuestionnaireAnswers) {
      return;
    }
    dispatch(
      simpleSelfAssesmentActions.fetchPersonQuestionnaire({
        variables: {
          m2sID,
        },
        availableQuestionnaires,
        personQuestionnaireAnswers,
      })
    );
  }, [m2sID, availableQuestionnaires, personQuestionnaireAnswers]);

  useEffect(() => {
    const questions = personQuestionnaire?.questionnaire?.questions;
    if (!questions) {
      return;
    }
    setQuestions(questions);
    questions[selectedIndex] && setQuestion(questions[selectedIndex]);
  }, [personQuestionnaire, selectedIndex]);

  useEffect(() => {
    if (!personQuestionnaireAnswers) {
      return;
    }
    const foundAnswer = personQuestionnaireAnswers.find(
      (answer) => answer.m2sID === m2sID && answer.sefID === question?.sefID
    );
    setAnswer(foundAnswer ? foundAnswer : null);
  }, [question, personQuestionnaireAnswers]);

  const handleNext = () => {
    setSelectedIndex(Math.min(selectedIndex + 1, questions.length - 1));
  };

  const handlePrevious = () => {
    setSelectedIndex(Math.max(selectedIndex - 1, 0));
  };

  const handleExit = () => {
    dispatch(selfAssesmentModalsActions.closeSimpleQuestionnaireModal());
    if (!personQuestionnaire) {
      return;
    }
    dispatch(
      simpleSelfAssesmentActions.fetchPersonAnswers({
        variables: {
          maID: personQuestionnaire.personID,
        },
      })
    );
  };

  const handleAnswer = (selectedAnswer: QuestionnaireAnswerOptions, note: string) => {
    if (!question || !m2sID) {
      return;
    }
    if (answer?.answer && answer.answer === selectedAnswer) {
      return;
    }
    if (answer?.seaID) {
      dispatch(
        selfAssesmentModalsActions.updateSelfAssessmentAnswer({
          variables: {
            seaID: answer?.seaID,
            data: {
              antwort: selectedAnswer,
              anmerkung: note ? note : '',
            },
          },
        })
      );
    } else {
      dispatch(
        selfAssesmentModalsActions.createSelfAssessmentAnswer({
          variables: {
            sefID: Number(question.sefID),
            answer: selectedAnswer,
            note: note ? note : '',
          },
          m2sID: m2sID,
        })
      );
    }
    handleNext();
  };

  return (
    <Modal className={`w-[600px] text-${colorTypes.SelfAssessmentActive}`} onExit={handleExit}>
      <SimpleQuestionnaireNavigation
        selectedIndex={selectedIndex}
        questions={questions}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
        handleExit={handleExit}
      />
      <SimpleQuestionnaireForm
        selectedIndex={selectedIndex}
        questions={questions}
        question={question}
        answer={answer}
        handleAnswer={handleAnswer}
      />
    </Modal>
  );
};

export const SimpleQuestionnaireModal: React.FC = () => {
  const { simpleQuestionnaireModal } = useAppSelector(
    selfAssesmentModalsSelectors.simpleQuestionnaireModalState
  );

  if (simpleQuestionnaireModal.isRender) {
    return <ModalContent />;
  }

  return null;
};
