import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { useAppDispatch } from '@/core/redux/hooks';
import { userSettingsActions } from '@/core/redux/slices/userSettings/userSettingsSlice';

export function useSelectedLocations() {
  const [locationsChecksState] = usePersonPageStoreSelector((store) => store.locationsChecksState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!locationsChecksState) {
      return;
    }
    
    dispatch(userSettingsActions.updateUserSelectedDepartments(locationsChecksState));
  }, [locationsChecksState]);

  return useMemo(() => {
    if (fp.isEmpty(locationsChecksState?.departments)) {
      return null;
    }
    if (locationsChecksState?.departments) {
      return Object.values(locationsChecksState?.departments)
        .map((department) => {
          if (department?.locations) {
            return Object.keys(department.locations).filter((key) => department?.locations?.[key]);
          }
          return null;
        })
        .filter((locations) => !fp.isEmpty(locations))
        .flat()
        .map((location) => location?.split('=')[1])
        .filter((location) => !fp.isEmpty(location));
    }
    return null;
  }, [locationsChecksState]);
}
