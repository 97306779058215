import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePersonRequirementTimeLIstForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/hooks/usePersonRequirementTimeLIstForm';
import { PersonRequirementTimeList } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/PersonRequirementTimeList';
import { PersonRequirementTimeListFilters } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/PersonRequirementTimeListFilters';
import { PersonRequirementTimeListFooter } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/PersonRequirementTimeListFooter';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IAdditionalPersonRequirementTimeListModalContent {
  onExit: () => void;
}

export const AdditionalPersonRequirementTimeListModalContent: React.FC<IAdditionalPersonRequirementTimeListModalContent> =
  ({ onExit }) => {
    const { t: personRequirementTimeListModalTranslations } = useTranslation(
      'personRequirementTimeListModal'
    );

    const { personRequirementTimeGroups, personRequirementTimeGroupsLock, payload } =
      useAppSelector(organizationalUnitsModalsSelectors.additionalPersonRequirementTimeListModal);

    const form = usePersonRequirementTimeLIstForm(personRequirementTimeGroups);

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!payload || !payload.additionalPersonRequirementID) {
        return;
      }

      dispatch(
        organizationalUnitsModalsActions.fetchAdditionalPersonRequirementTimeList({
          additionalPersonRequirementID: payload.additionalPersonRequirementID,
        })
      );

      dispatch(organizationalUnitsModalsActions.fetchAvailablePersonRequirementTimeCycles());
    }, [payload?.additionalPersonRequirementID]);

    const renderFooter = (): React.ReactNode => (
      <PersonRequirementTimeListFooter
        form={form}
        onExit={onExit}
        assistanceName={payload?.assistanceName}
        additionalRequirementID={payload?.additionalPersonRequirementID}
      />
    );

    return (
      <Sidebar onExit={onExit} position={'right'} footer={renderFooter()}>
        <div className={'flex flex-col gap-5 w-[70vw] p-5 pb-0'}>
          <Typography type={'H3'}>
            {personRequirementTimeListModalTranslations('title.label', {
              assistanceName: payload?.assistanceName,
            })}
          </Typography>
          <FormProvider {...form}>
            <Skeleton trigger={personRequirementTimeGroupsLock === LoadingStatus.LOADING}>
              <PersonRequirementTimeListFilters />
              <PersonRequirementTimeList
                personRequirementTimeGroups={personRequirementTimeGroups}
              />
            </Skeleton>
          </FormProvider>
        </div>
      </Sidebar>
    );
  };
