import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxUI } from '@/components/Checkbox/Checkbox';
import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import {
  IMedication,
  medicationActions,
} from '@/core/redux/slices/functions/medication/medicationSlice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMedicationList {
  medications: IMedication[];
  setSelectedMedicationID: Dispatch<SetStateAction<number | null>>;
  personID?: number;
}

export const MedicationList: React.FC<IMedicationList> = ({
  personID,
  medications,
  setSelectedMedicationID,
}) => {
  const dispatch = useAppDispatch();
  const { t: medicationTranslations } = useTranslation('medication');

  const [isActive, setIsActive] = useState<boolean>(true);

  const columns: ITableColumn[] = [
    {
      header: medicationTranslations('columns.isDemanded.label'),
      accessor: 'isDemanded',
      template: (value: boolean) => {
        return <CheckboxUI checked={Boolean(value)} readOnly />;
      },
    },
    {
      header: medicationTranslations('columns.name.label'),
      accessor: 'name',
    },
    {
      header: medicationTranslations('columns.dosageForm.label'),
      accessor: 'dosageForm',
    },
    {
      header: medicationTranslations('columns.dosage.label'),
      accessor: 'dosage',
      template: (value: string | null) => {
        if (!value) {
          return '--';
        }

        const values = value.split('\n');
        const html = `<div>${values.map((str) => `<div>${str}</div>`).join('')}</div>`;

        return (
          <div
            className='whitespace-normal'
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        );
      },
    },
    {
      header: medicationTranslations('columns.orderedBy.label'),
      accessor: 'orderedBy',
    },
    {
      header: medicationTranslations('columns.orderedTimestamp.label'),
      accessor: 'orderedFromTimestamp',
      template: (value: string, data: IMedication) => {
        const { orderedFromTimestamp, orderedToTimestamp } = data;
        return orderedFromTimestamp || orderedToTimestamp ? (
          <>
            {orderedFromTimestamp && (
              <div>
                {medicationTranslations('from.label')}: {toClientDateInput(orderedFromTimestamp)}
              </div>
            )}
            {orderedToTimestamp && (
              <div>
                {medicationTranslations('to.label')}: {toClientDateInput(orderedToTimestamp)}
              </div>
            )}
          </>
        ) : (
          '--'
        );
      },
    },
  ];

  useEffect(() => {
    if (!personID) {
      return;
    }

    dispatch(
      medicationActions.fetchMedications({
        personID: personID,
        isActive: isActive,
      })
    );
  }, [personID, isActive]);

  useEffect(() => {
    return () => {
      dispatch(medicationActions.setMedications([]));
      dispatch(medicationActions.setMedicationsLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <div className='flex flex-col h-full'>
      <div className='flex-grow'>
        <label className='w-fit flex gap-4 cursor-pointer p-5'>
          <Typography>{medicationTranslations('filter.isActive.label')}</Typography>
          <CheckboxUI
            checked={isActive}
            className='pointer-events-none'
            onChange={() => setIsActive((prev) => !prev)}
          />
        </label>

        <Table
          data={medications}
          columns={columns}
          singleSelect
          onSingleSelectChange={(selected) => setSelectedMedicationID(selected)}
          className='max-h-[calc(100vh_-_431px)]'
        />
      </div>
    </div>
  );
};
