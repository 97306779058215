import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.activityPlanning.appointmentDetails;

const appointmentDetails = createSelector([selector], (state) => state.appointmentDetails);
const appointmentDetailLock = createSelector([selector], (state) => state.appointmentDetailsLock);
const appointmentDetailMeasureAids = createSelector([selector], (state) => state.measureAidsValues);
const appointmentTypes = createSelector([selector], (state) => state.appointmentTypeValues);

export const activityPlaningAppointmentDetailsSelectors = {
  appointmentDetails,
  appointmentDetailLock,
  appointmentDetailMeasureAids,
  appointmentTypes,
};
