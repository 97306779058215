import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useActivityPlaningSelector } from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningContext/ActivityPlanningContext';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { activityPlaningAppointmentParticipantsListSelectors } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/selectors';
import { activityPlanningAppointmentParticipantsListActions } from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const ParticipantsList: React.FC = () => {
  const { t: appointmentParticipantsListTranslations } = useTranslation('activityPlanning', {
    keyPrefix: 'appointmentParticipantsList',
  });

  const [selectedAppointmentID] = useActivityPlaningSelector(
    (store) => store.selectedAppointmentID
  );
  const [selectedMeasureID] = useActivityPlaningSelector((store) => store.selectedMeasureID);

  const appointmentParticipantsList = useAppSelector(
    activityPlaningAppointmentParticipantsListSelectors.appointmentParticipantsList
  );
  const loadingStatus = useAppSelector(
    activityPlaningAppointmentParticipantsListSelectors.appointmentParticipantsListLock
  );

  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    { accessor: 'w', header: appointmentParticipantsListTranslations('list.tableHeader.w.label') },
    {
      accessor: 'name',
      header: appointmentParticipantsListTranslations('list.tableHeader.participant.label'),
    },
    {
      accessor: 'department',
      header: appointmentParticipantsListTranslations('list.tableHeader.department.label'),
    },
  ];

  useEffect(() => {
    if (!selectedAppointmentID || !selectedMeasureID) {
      return;
    }

    dispatch(
      activityPlanningAppointmentParticipantsListActions.fetchAppointmentParticipantsList({
        appointmentID: selectedAppointmentID,
        measureID: selectedMeasureID,
      })
    );
  }, [selectedAppointmentID]);

  return (
    <div className={'border flex flex-col gap-2 p-2'}>
      <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
        <Table
          columns={columns}
          data={selectedAppointmentID ? appointmentParticipantsList : []}
          singleSelect={true}
        />
      </Skeleton>
    </div>
  );
};
