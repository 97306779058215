import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Typography } from '@/components/Typography/Typography';

export const EndOfFirstYear: React.FC = () => {
  const { t: endOfFirstYearTranslations } = useTranslation('summaryFA', {
    keyPrefix: 'endOfFirstYear',
  });

  return (
    <div className={'flex flex-col gap-3'}>
      <Typography type={'H3'}>{endOfFirstYearTranslations('title.label')}</Typography>
      <CheckboxItem
        label={endOfFirstYearTranslations('fields.isEndeBBB1.label')}
        fieldName={'isEndeBBB1'}
        className={'justify-between'}
      />
    </div>
  );
};
