import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { qualificationDocumentationStatusEnum } from '@/core/enums/qualificationDocumentationStatusEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IQualificationObjectivesDocumentationsItem {
  qualificationObjectiveID: number;
  selectedOption: qualificationDocumentationStatusEnum;
  qualificationObjectiveName: string;
  qualificationObjectiveStatusComment?: string;
  qualificationObjectiveExtension?: string;
}

export interface IStatusReport {
  isFirstYearVocationalTraining: boolean;
  isSecondYearVocationalTraining: boolean;
  isFurtherIndividualAppointment: boolean;
  isCurrentlyNoQuestions: boolean;
  isQuestionsNeedClarification: boolean;
  isCompetenceAnalysisUnchanged: boolean;
  isNewClassification: boolean;
  isActivityOriented: boolean;
  isOccupationalFieldOriented: boolean;
  isWorkplaceOriented: boolean;
  isJobProfileOriented: boolean;
  isParticipantExpectationChanged: string;
  isAdditionalRequirements: boolean;
  isContinued: boolean;
  isVocationalTrainingPlaceRealistic: boolean;
  isTransitionToGeneralLaborMarketRealistic: boolean;
  isDiscussionRequired: boolean;
  qualificationObjectivesDocumentations: Record<string, IQualificationObjectivesDocumentationsItem>;
  participantExpectationChangeText?: string | null;
  participantExpectationChangeMethod?: string | null;
  questionsText?: string | null;
  actionCourseTimestamp?: string | null;
  documentNumber?: string | null;
  furtherIndividualAppointmentReason?: string | null;
  reportingPeriodFromTimestamp?: string | null;
  reportingPeriodToTimestamp?: string | null;
}

export interface IStatusReportFetchPayload {
  integrationPlanID: number;
  personID: number;
}

export interface IStatusReportUpdatePayload {
  integrationPlanID: number;
  personID: number;
  statusReport: IStatusReport;
}

export interface IStatusReportState {
  statusReport: IStatusReport | null;
  statusReportLock: LoadingStatus;
  updateStatusReportLock: LoadingStatus;
}

const initialState: IStatusReportState = {
  statusReport: null,
  statusReportLock: LoadingStatus.NEVER,
  updateStatusReportLock: LoadingStatus.NEVER,
};

export const statusReportSlice = createSlice({
  name: 'statusReport',
  initialState,
  reducers: {
    fetchStatusReport: (state, action: PayloadAction<IStatusReportFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateStatusReport: (state, action: PayloadAction<IStatusReportUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setStatusReportLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      statusReportLock: action.payload,
    }),
    setUpdateStatusReportLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      updateStatusReportLock: action.payload,
    }),
    setStatusReport: (state, action: PayloadAction<IStatusReport | null>) => ({
      ...state,
      statusReport: action.payload,
    }),
  },
});

export const statusReportReducers = statusReportSlice.reducer;

export const statusReportActions = statusReportSlice.actions;
