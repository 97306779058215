import { useAppSelector } from '../redux/hooks';
import { IParameterItem } from '../redux/slices/parameters/parametersSlice';
import { parametersSelectors } from '../redux/slices/parameters/selectors';

export const useAppParameterValue: React.FC<string> = (parameterName): string => {
  const parameters = useAppSelector(parametersSelectors.parameters);

  const parameter =
    parameters.find((item: IParameterItem) => item.name === parameterName)?.value ?? '';

  return parameter;
};
