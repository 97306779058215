import React from 'react';

import { ITableColumn, Table } from '@/components/Table/Table';
import { Typography } from '@/components/Typography/Typography';

const data = [
  {
    id: '1',
    name: 'Internship first',
  },
  {
    id: '2',
    name: 'Internship second',
  },
  {
    id: '3',
    name: 'Internship third',
  },
];

export const Internships: React.FC = () => {
  const columns: ITableColumn[] = [{ header: 'Name', accessor: 'name' }];

  return (
    <div className='flex flex-col gap-4'>
      <Typography type='H3'>Internships</Typography>
      <Table data={data} columns={columns} />
    </div>
  );
};
