export enum ViewMode {
  gridView = 'grid',
  listView = 'list',
}

export enum FunctionTypes {
  wizard = 'wizard',
  full = 'full',
  none = 'none',
}

export interface IFunction {
  id: string;
  is_pinned: boolean;
  function_type_id: {
    type: string;
  };
  functions_id: {
    name: string;
    icon: {
      filename_disk: string;
    };
  };
}

export interface IFunctionItem {
  id: string;
  functionID: string;
  isPinned: boolean;
  functionType: FunctionTypes;
  iconUrl: string;
  name: string;
  orderNumber: number;
  dragged?: boolean;
}

export interface IFunctionListItem {
  heading: string;
  functions: IFunctionItem[];
}

export interface IFunctionTypeTag {
  id: string;
  functionType: FunctionTypes;
  className?: string;
}

export interface IFunctionTypeCard {
  selected?: boolean;
  type: FunctionTypes;
  handleTypeChange: (type: FunctionTypes) => void;
  text: string;
  badgeColor: string;
}

export interface IFunctionView {
  functions: IFunctionItem[];
}
