import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { INotification } from '@/types/notifications';

import styles from './styles.module.scss';

const transition = {
  show: { opacity: 1, x: 0 },
  animate: { opacity: 0, x: -100 },
};

export const NotificationItem = React.forwardRef<HTMLDivElement, INotification>(
  ({ id, description, title }, ref) => {
    const dp = useAppDispatch();

    const close = () => {
      if (id) {
        dp(notificationsActions.hideNotification(id));
      }
    };

    return (
      <motion.div
        ref={ref}
        drag='x'
        layoutId={id}
        initial={transition.animate}
        animate={transition.show}
        exit={transition.animate}
        dragSnapToOrigin
        onDragEnd={(event, info) => {
          if (Math.abs(info.offset.x) > 100) {
            close();
          }
        }}
        className={clsx(styles.container, 'flex items-center relative')}
      >
        <div
          role='button'
          tabIndex={0}
          className={clsx(styles.cross, 'font-monospace absolute end-0 top-0')}
          onClick={close}
          onKeyDown={close}
        >
          X
        </div>
        <div className='flex flex-col gap-2'>
          <Typography type='16-M'>{title}</Typography>
          <Typography>{description}</Typography>
        </div>
      </motion.div>
    );
  }
);
