import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { createArrayWithLength } from '@/core/utils/commonUtils';
import { IPageButton, IPagination } from '@/types/pagination';

import styles from './styles.module.scss';

export const Pagination: React.FC<IPagination> = ({
  paginationSize = 12,
  paginationChunkSize = 3,
  total,
  selectedPage = 5,
  previous,
  next,
  setSelectedPage,
}) => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui' });
  const totalPages = Math.ceil(total / paginationSize);
  const pages = createArrayWithLength(totalPages);

  let chunked;

  if (paginationChunkSize > pages.length && paginationChunkSize + 2 < pages.length) {
    chunked = [...pages];
  } else if (paginationChunkSize > 1 && paginationChunkSize % 2 === 0) {
    chunked = fp.dropRight(pages.length - selectedPage - paginationChunkSize / 2, pages);
    chunked = fp.drop(selectedPage - paginationChunkSize / 2, chunked);
  } else if (paginationChunkSize > 1 && paginationChunkSize % 2 === 1) {
    chunked = fp.dropRight(
      pages.length - selectedPage - Math.floor(paginationChunkSize / 2),
      pages
    );
    chunked = fp.drop(selectedPage - Math.floor(paginationChunkSize) / 2, chunked);
  } else {
    chunked = [selectedPage];
  }

  const PageButton: React.FC<IPageButton> = useCallback(
    ({ page, disabled = false, customC }) => (
      <Button
        onClick={
          disabled
            ? undefined
            : () => {
                setSelectedPage(+page);
              }
        }
        buttonStyle={'Text'}
        buttonVariant={'Transparent'}
        disabled={disabled}
      >
        <Typography
          className={clsx(
            'flex justify-center items-center',
            styles.selectedPaginationPage,
            disabled
              ? styles.paginationPageDisabled
              : selectedPage === page
              ? styles.selectedPaginationPageActive
              : styles.selectedPaginationPageInactive
          )}
        >
          {customC || page}
        </Typography>
      </Button>
    ),
    [selectedPage, setSelectedPage]
  );

  return (
    <div className='flex items-center justify-between w-full'>
      <Typography type='14-R' color='Disabled'>
        {t('label.paginationInfo', {
          firstItem: (selectedPage - 1) * paginationSize + 1,
          lastItem: Math.min(selectedPage * paginationSize, total),
          totalItems: total,
        })}
      </Typography>
      <div className={clsx(styles.pagesList, 'flex')}>
        <PageButton
          page={selectedPage - 1}
          customC={previous || <Typography type='14-R'>&lt;</Typography>}
          disabled={selectedPage === 1}
        />
        {fp.first(chunked) === 1 || paginationChunkSize > totalPages || (
          <>
            <PageButton page={1} />
            {fp.first(chunked) === 2 || paginationChunkSize > totalPages || (
              <PageButton page='...' disabled />
            )}
          </>
        )}
        {chunked.map((page) => (
          <PageButton page={page} key={page.toString()} />
        ))}
        {fp.last(chunked) === totalPages || paginationChunkSize > totalPages || (
          <>
            {fp.last(chunked) === totalPages - 1 || paginationChunkSize > totalPages || (
              <PageButton page='...' disabled />
            )}
            <PageButton page={totalPages} customC={Number.isNaN(totalPages) && ' '} />
          </>
        )}
        <PageButton
          page={selectedPage + 1}
          customC={next || <Typography type='14-R'>&gt;</Typography>}
          disabled={selectedPage === pages.length}
        />
      </div>
    </div>
  );
};
