import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { DatePicker } from '@/components/Datepicker/DatePicker';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { IStatusReport } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { colorTypes } from '@/styles/types';

export const CourseOfMeasures: React.FC = () => {
  const { t: courseOfMeasuresTranslations } = useTranslation('statusReport', {
    keyPrefix: 'courseOfMeasures',
  });

  const { control, watch } = useFormContext<IStatusReport>();

  return (
    <Accordion
      title={<Typography type={'H3'}>{courseOfMeasuresTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col gap-2 mt-4'}>
        <div className={'flex flex-row gap-4 items-center'}>
          <Typography type={'H3'}>
            {courseOfMeasuresTranslations('fields.reasonForCreation.label')}
          </Typography>
          <div className={'flex flex-row gap-2 items-center ms-[50px]'}>
            <Typography>
              {courseOfMeasuresTranslations('fields.reasonForCreation.dateLabel.label')}
            </Typography>
            <DatePicker control={control.register('actionCourseTimestamp')} />
          </div>
        </div>
        <div className={'w-auto'}>
          <CheckboxItem
            label={courseOfMeasuresTranslations('fields.isFirstYearVocationalTraining.label')}
            fieldName={'isFirstYearVocationalTraining'}
            labelPosition={'right'}
          />
          <CheckboxItem
            label={courseOfMeasuresTranslations('fields.isSecondYearVocationalTraining.label')}
            fieldName={'isSecondYearVocationalTraining'}
            labelPosition={'right'}
          />
        </div>
        <div className={'grid grid-cols-12 gap-2'}>
          <CheckboxItem
            label={courseOfMeasuresTranslations('fields.isFurtherIndividualAppointment.label')}
            fieldName={'isFurtherIndividualAppointment'}
            labelPosition={'right'}
            className={'col-span-3'}
          />
          <div className={'col-span-2 '}>
            <Typography className={'flex-nowrap'}>
              {courseOfMeasuresTranslations('fields.furtherIndividualAppointmentReason.label')}
            </Typography>
          </div>
          <div className={'col-span-7'}>
            <TextArea
              control={control.register('furtherIndividualAppointmentReason')}
              containerClassName={'flex-grow'}
              readOnly={!watch('isFurtherIndividualAppointment')}
            />
          </div>
        </div>
        <div className={'flex flex-row gap-2 items-center'}>
          <Typography>{courseOfMeasuresTranslations('fields.reportingPeriod.label')}</Typography>
          <Typography>
            {courseOfMeasuresTranslations(
              'fields.reportingPeriod.reportingPeriodFromTimestamp.label'
            )}
          </Typography>

          <DatePicker control={control.register('reportingPeriodFromTimestamp')} />
          <Typography>
            {courseOfMeasuresTranslations(
              'fields.reportingPeriod.reportingPeriodToTimestamp.label'
            )}
          </Typography>
          <DatePicker control={control.register('reportingPeriodToTimestamp')} />
        </div>
        <div className={'grid grid-cols-12 grid-rows-[auto, auto] gap-x-2'}>
          <Typography className={'col-span-2 row-span-2'}>
            {courseOfMeasuresTranslations('fields.specialQuestions.label')}
          </Typography>
          <div className={'col-span-3'}>
            <CheckboxItem
              label={courseOfMeasuresTranslations('fields.isCurrentlyNoQuestions.label')}
              fieldName={'isCurrentlyNoQuestions'}
              className={'gap-5'}
              labelPosition={'right'}
            />
          </div>
          <div className={'col-span-7'} />
          <CheckboxItem
            label={courseOfMeasuresTranslations('fields.isQuestionsNeedClarification.label')}
            fieldName={'isQuestionsNeedClarification'}
            className={'col-span-3 gap-5'}
            labelPosition={'right'}
          />
          <TextArea
            control={control.register('questionsText')}
            containerClassName={'col-span-7 flex-grow'}
            readOnly={!watch('isQuestionsNeedClarification')}
          />
        </div>
      </div>
    </Accordion>
  );
};
