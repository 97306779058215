import { useEffect, useMemo } from 'react';
import fp from 'lodash/fp';

import { usePersonPageStoreSelector } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { personPageSelectors } from '@/core/redux/slices/personPage/personPageSelectors';
import { personPageActions } from '@/core/redux/slices/personPage/personPageSlice';
import { resolveProgress } from '@/core/utils/commonUtils';
import { sortByName, sortLocations } from '@/core/utils/departmentUtils';
import { SortingBy } from '@/types/sortingBy';

export default function useFetchedDepartments() {
  const dispatch = useAppDispatch();
  const fetchedDepartments = useAppSelector(personPageSelectors.departments);
  const departmentsStatus = useAppSelector(personPageSelectors.departmentsStatus);

  const [sortBy] = usePersonPageStoreSelector((store) => store.departmentsSortedBy);
  const [searchValue] = usePersonPageStoreSelector((store) => store.departmentSearchValue);

  useEffect(() => {
    if (fp.isEmpty(fetchedDepartments)) {
      dispatch(personPageActions.fetchDepartments());
    }
  }, [dispatch, fetchedDepartments]);

  const departments = useMemo(() => {
    if (fp.isEmpty(fetchedDepartments) || fp.isEmpty(sortBy) || searchValue === undefined) {
      return null;
    }
    const loweredSearch = searchValue ? searchValue.toLowerCase() : '';
    return fetchedDepartments
      .map((department) => {
        if (department.name.toLowerCase().includes(loweredSearch)) {
          return department;
        }

        const filteredLocations = department.departments.filter((location) =>
          location.name.toLowerCase().includes(loweredSearch)
        );

        return { ...department, departments: filteredLocations };
      })
      .filter((department) => department.departments.length > 0)
      .sort((a, b) => sortByName(a, b, sortBy ?? SortingBy.nameDescend))
      .map((department) => sortLocations(department, sortBy ?? SortingBy.nameDescend));
  }, [fetchedDepartments, sortBy, searchValue]);

  const isDepartmentsInProgress = useMemo(() => {
    return resolveProgress(departmentsStatus);
  }, [departmentsStatus]);

  return { departments, isDepartmentsInProgress };
}
