import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '../../types';

const selector = (state: IRootState) => state.functionPage;
const selectedPerson = createSelector([selector], (state) => state.selectedPerson);
const activeTabId = createSelector([selector], (state) => state.activeTabId);
const functions = createSelector([selector], (state) => state.functions);
const functionsLock = createSelector([selector], (state) => state.status);
const tabs = createSelector([selector], (state) => state.tabs);
const status = createSelector([selector], (state) => state.status);
const usersActiveTab = createSelector([selector], (state) => state.usersActiveTab);
const addShortcutTabLockStatus = createSelector([selector], (state) => state.addShortcutTabLock.status);
const addShortcutTabLockData = createSelector([selector], (state) => state.addShortcutTabLock.data);

const personID = createSelector([selector], (state) => state.selectedPerson?.id);

export const functionPageSelectors = {
  personID,
  selectedPerson,
  activeTabId,
  functions,
  functionsLock,
  tabs,
  status,
  usersActiveTab,
  addShortcutTabLockStatus,
  addShortcutTabLockData
};
