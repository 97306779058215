//eslint-disable-next-line
import '@nw/wheel/dist/cjs/index.css';
import '@/styles/global.scss';
import 'react-tooltip/dist/react-tooltip.css';
import '@progress/kendo-theme-default/dist/all.css';

import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import Main from '@/app/v1/Main';
import { useApollo } from '@/core/clients/apollo';
import i18n from '@/i18n';

const App: React.FC = () => {
  const apolloClient = useApollo('');

  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18n}>
          <Main />
        </I18nextProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
