import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { InternalDifferentiationListItem } from '@/components/functions/TestingResult/InternalDifferentiation/InternalDifferentiationList/InternalDifferentiationListItem/InternalDifferentiationListItem';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultSelectors } from '@/core/redux/slices/functions/testingResult/selectors';
import {
  IInternalDifferentiationItem,
  testingResultActions,
} from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface IInternalDifferentiationList {
  internalDifferentiationItems?: IInternalDifferentiationItem[];
  personID?: number;
}

const reduceInternalDifferentiationList = (
  internalDifferentiationsList?: IInternalDifferentiationItem[]
): Record<string, boolean> | undefined => {
  if (!internalDifferentiationsList) {
    return undefined;
  }

  return internalDifferentiationsList.reduce<Record<string, boolean>>(
    (accum, internalDifferentiationsItem) => ({
      ...accum,
      [internalDifferentiationsItem.id.toString()]: internalDifferentiationsItem.isChecked,
    }),
    {}
  );
};

export const InternalDifferentiationList: React.FC<IInternalDifferentiationList> = ({
  internalDifferentiationItems,
  personID,
}) => {
  const { t: internalDifferentiationTranslations } = useTranslation('testingResult', {
    keyPrefix: 'testProcedures.internalDifferentiation',
  });

  const dispatch = useAppDispatch();

  const oddColor = useAppSelector(parametersSelectors.internalDifferentiationItemOddColor);
  const evenColor = useAppSelector(parametersSelectors.internalDifferentiationItemEvenColor);

  const initialValues = reduceInternalDifferentiationList(internalDifferentiationItems);
  const updateInternalDifferentiationLock = useAppSelector(
    testingResultSelectors.updateInternalDifferentiationLock
  );
  const isShouldUpdate = useAppSelector(testingResultSelectors.isShouldUpdate);

  const form = useForm<Record<string, boolean>>({
    defaultValues: initialValues,
  });
  const currentValues = form.watch();

  const { isDirty } = form.formState;

  const handleChange = (selectedIds: string[]) => {
    const currentValues = form.watch();
    const formKeys = Object.keys(currentValues);

    formKeys.forEach((formKey) => {
      form.setValue(formKey, selectedIds.includes(formKey), { shouldDirty: true });
    });
  };

  const handleCancel = () => {
    form.reset();
  };

  const handleSave = () => {
    if (!personID || !internalDifferentiationItems) {
      return;
    }

    dispatch(
      testingResultActions.updateInternalDifferentiation({
        personID: personID,
        internalDifferentiation: currentValues,
        internalDifferentiationItems: internalDifferentiationItems,
      })
    );

    form.reset(currentValues);
  };

  useEffect(() => {
    if (isShouldUpdate && initialValues) {
      const formKeys = Object.keys(initialValues);

      formKeys.forEach((key) => {
        form.setValue(key, initialValues[key], { shouldDirty: true });
      });

      dispatch(testingResultActions.setIsShouldUpdate(false));
      return;
    }
  }, [isShouldUpdate, internalDifferentiationItems]);

  return (
    <div className={'flex flex-col'}>
      <FormProvider {...form}>
        {internalDifferentiationItems && (
          <>
            <div className={`grid grid-cols-12 gap-3 items-center bg-${colorTypes.Fields}`}>
              <div className={'col-span-8'} />
              <Typography type={'14-M'} className={'justify-self-center py-2'}>
                {internalDifferentiationTranslations('table.header.zero.label')}
              </Typography>
              <Typography type={'14-M'} className={'justify-self-center py-2'}>
                {internalDifferentiationTranslations('table.header.plusOne.label')}
              </Typography>
              <div className={'col-span-2'} />
            </div>
            {internalDifferentiationItems.map((internalDifferentiationItem, index) => (
              <InternalDifferentiationListItem
                {...internalDifferentiationItem}
                onChange={handleChange}
                bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
                key={internalDifferentiationItem.id}
              />
            ))}
          </>
        )}
      </FormProvider>
      {isDirty && (
        <div className={'flex flex-row justify-end gap-3 mt-4'}>
          {updateInternalDifferentiationLock === LoadingStatus.LOADING && (
            <SpinnerIcon className={`text-${colorTypes.Blue}`} />
          )}
          <Button onClick={handleSave}>
            {internalDifferentiationTranslations('buttons.save.label')}
          </Button>
          <Button buttonVariant={'Secondary'} onClick={handleCancel}>
            {internalDifferentiationTranslations('buttons.cancel.label')}
          </Button>
        </div>
      )}
    </div>
  );
};
