import React, { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { performanceProfileModalsActions } from '@/core/redux/slices/modalsSlice/functions/performanceProfile/performancePrfoileModalSlice';
import { performanceProfileModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/performanceProfile/selector';
import { yup } from '@/core/utils/commonUtils';

type FormValues = {
  targetCatalog: string;
};

const validationSchema = yup.object().shape({
  targetCatalog: yup.string().required(),
});

export const SelectTargetCatalogModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    performanceProfileModalsSelectors.selectTargerCatalogModal
  );

  if (isOpened) {
    return <ModalContent />;
  }
};

const ModalContent: React.FC = () => {
  const { t: startNewQuestionnaireModal } = useTranslation('startNewQuestionnaireModal');

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const targetCatalogTypes = useAppSelector(performanceProfileSelectors.targetCatalogTypes);
  const dispatch = useAppDispatch();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleExit = () => {
    dispatch(performanceProfileModalsActions.closeSelectTargetCatalogModal());
  };

  const onSubmit: SubmitHandler<FormValues> = () => {
    dispatch(
      performanceProfileActions.startNewQuestionnaire({
        personID: Number(selectedPerson?.id),
      })
    );
    handleExit();
  };

  const options: IRadioButtonGroupItem[] = useMemo(
    () => targetCatalogTypes.map((item) => ({ value: String(item.id), element: item.name })),
    [targetCatalogTypes]
  );

  const renderHeader = (
    <div className='mb-5 p-8 pb-0'>
      <Typography type='H2'>{startNewQuestionnaireModal('title')}</Typography>
    </div>
  );

  const renderFooter = (
    <div className='flex gap-2.5 px-8 py-4'>
      <Button
        type='submit'
        className='w-full'
        onClick={methods.handleSubmit(onSubmit)}
        disabled={!methods.formState.isValid}
      >
        {startNewQuestionnaireModal('buttons.create')}
      </Button>
      <Button type='button' className='w-full' buttonVariant='Secondary' onClick={handleExit}>
        {startNewQuestionnaireModal('buttons.cancel')}
      </Button>
    </div>
  );

  return (
    <Modal
      className='max-w-[700px] h-[500px] p-0'
      onExit={handleExit}
      footer={renderFooter}
      header={renderHeader}
    >
      <FormProvider {...methods}>
        <form className='my-2.5'>
          <RadioButtonGroup options={options} name={'targetCatalog'} itemClassName='px-8 py-2.5' />
        </form>
      </FormProvider>
    </Modal>
  );
};
