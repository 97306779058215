import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.workingTimeMobility.mobility;

const mobility = createSelector([selector], (state) => state.mobility);
const mobilityLock = createSelector([selector], (state) => state.mobilityLock);

export const mobilitySelectors = {
  mobility,
  mobilityLock,
};
