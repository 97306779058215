import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import { ArrivalForm } from '@/components/functions/Mobility/ArrivalForm/ArrivalForm';
import { DepartureForm } from '@/components/functions/Mobility/DepartureForm/DepartureForm';
import {
  IMobilityForm,
  useMobilityForm,
} from '@/components/functions/Mobility/hooks/useMobilityForm';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { mobilityActions } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { mobilitySelectors } from '@/core/redux/slices/functions/workingTimeMobility/mobility/selectors';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMobilitySubForm {
  view: JSX.Element;
  type: MobilityFormTypes;
}

export const Mobility: React.FC = () => {
  const { t: mobilityTranslations } = useTranslation('mobility');

  const { form } = useMobilityForm();

  const { selectedPersonID } = useSelectedPerson();

  const loadingStatus = useAppSelector(mobilitySelectors.mobilityLock);

  const dispatch = useAppDispatch();

  const handleSave = (data: IMobilityForm) => {
    if (!selectedPersonID) {
      return;
    }

    const formValues = fp.cloneDeep(data);

    dispatch(
      mobilityActions.updateMobility({
        formValues: formValues,
        personID: selectedPersonID,
      })
    );
  };

  const handleCancel = () => {
    form.reset();
  };

  return (
    <FunctionWrapper>
      <FunctionContent withFooter={true}>
        <Skeleton trigger={loadingStatus === LoadingStatus.LOADING} rows={10}>
          <div className={'flex flex-col gap-4 py-3 px-7'}>
            <Typography type={'H3'}>{mobilityTranslations('title.label')}</Typography>
            <div className={'flex-grow flex flex-row gap-[120px] items-start'}>
              <FormProvider {...form}>
                <ArrivalForm />
                <DepartureForm />
              </FormProvider>
            </div>
          </div>
        </Skeleton>
      </FunctionContent>
      <div className={'flex flex-row px-7 py-[9px] justify-start items-center gap-3 shadow-footer'}>
        <Button onClick={form.handleSubmit(handleSave)}>
          {mobilityTranslations('buttons.save.label')}
        </Button>
        <Button onClick={handleCancel} buttonVariant={'Secondary'}>
          {mobilityTranslations('buttons.cancel.label')}
        </Button>
      </div>
    </FunctionWrapper>
  );
};
