import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.functions.vocationalTrainingArea;

const vocationalTrainingAreaGroups = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaGroups
);
const vocationalTrainingAreaList = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaList
);
const vocationalTrainingAreaGroupsLock = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaGroupsLock
);
const selectedVocationalTrainingArea = createSelector(
  [selector],
  (state) => state.selectedVocationalTrainingArea
);

export const vocationalTrainingAreaSelectors = {
  vocationalTrainingAreaGroups,
  vocationalTrainingAreaList,
  vocationalTrainingAreaGroupsLock,
  selectedVocationalTrainingArea,
};
