import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { PersonInfoModalContent } from '@/components/Modals/Modals/functionsPage/PersonInfoModal/PersonInfoModalContent/PersonInfoModalContent';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Typography } from '@/components/Typography/Typography';
import { PersonInfoModalViews } from '@/core/enums/personInfoModalViews';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionsPageModalsActions } from '@/core/redux/slices/modalsSlice/functionsPage/functionsPageModalsSlice';
import { functionsPageModalsSelectors } from '@/core/redux/slices/modalsSlice/functionsPage/selectors';
import { colorTypes } from '@/styles/types';

import { LogbookSummaryContent } from './LogbookSummaryContent/LogbookSummaryContent';

interface View {
  type: PersonInfoModalViews;
  view: JSX.Element;
  title: string;
}

export const ModalContent: React.FC = () => {
  const { payload: person } = useAppSelector(functionsPageModalsSelectors.personInfoModal);

  const { t: personInfoModalTranslations } = useTranslation('personInfoModal');

  const dispatch = useAppDispatch();

  const [selectedView, setSelectedView] = useState<PersonInfoModalViews>(
    PersonInfoModalViews.LogbookSummary
  );

  const handleExit = () => {
    dispatch(functionsPageModalsActions.closePersonInfoModal());
  };

  const pesontGeneralDataTabs: Record<PersonInfoModalViews, View> = {
    [PersonInfoModalViews.LogbookSummary]: {
      type: PersonInfoModalViews.LogbookSummary,
      view: <LogbookSummaryContent/>,
      title:  personInfoModalTranslations('tabs.logbookSummary.label'),
    },
    [PersonInfoModalViews.PersonalInformation]: {
      type: PersonInfoModalViews.PersonalInformation,
      view: <PersonInfoModalContent />,
      title: personInfoModalTranslations('tabs.personalInformation.label'),
    },
  };

  const handleTabClick = (view: PersonInfoModalViews) => {
    setSelectedView(view);
  };

  return (
    <Sidebar onExit={handleExit} position='right' className='w-[716px]'>
      {person && (
        <>
          <div className='p-8'>
            <Typography type={'H2'}>
              {person.name} {person.surname}
            </Typography>
          </div>

          <div className={`flex flex-row gap-3 border-b border-b-${colorTypes.Stroke} px-7`}>
            {Object.values(pesontGeneralDataTabs).map((view) => (
              <Button
                onClick={() => handleTabClick(view.type)}
                buttonVariant={selectedView === view.type ? 'Default' : 'Secondary'}
                key={view.type}
                className={'rounded-b-none px-4 pt-2 pb-[7px]'}
              >
                {view.title}
              </Button>
            ))}
          </div>
          <div className='flex-grow h-full flex flex-col'>
            {pesontGeneralDataTabs[selectedView].view}
          </div>
        </>
      )}
    </Sidebar>
  );
};

export const PersonInfoModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(functionsPageModalsSelectors.personInfoModal);

  if (isOpened) {
    return <ModalContent />;
  }

  return null;
};
