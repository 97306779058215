import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import { ILocation } from '@/types/location';

import styles from './styles.module.scss';

export const Location: React.FC<ILocation> = ({ id, name, departmentId }) => {
  const locationFieldName = `departments.${departmentId}.locations.location_id=${id}`;

  const formContext = useFormContext();
  const state = formContext.watch(locationFieldName);

  useEffect(() => {
    if (state === undefined) {
      formContext.setValue(locationFieldName, false);
    }
  }, []);

  return (
    <div
      className={clsx(styles.container, 'flex flex-row items-center py-2 cursor-pointer', {
        [styles.checked]: state,
      })}
    >
      <Checkbox name={locationFieldName} className='mr-2 shrink-0' id={locationFieldName} />
      <label className={'cursor-pointer'} htmlFor={locationFieldName}>
        <Typography type='16-R' color='Primary'>
          {name}
        </Typography>
      </label>
    </div>
  );
};
