import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets/typings';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import {
  activityPlanningAppointmentParticipantsListActions,
  IActivityPlanningAppointmentParticipant,
  IAppointmentParticipantsListFetchPayload,
} from '@/core/redux/slices/functions/activityPlanning/appointmentParticipantsList/slice';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type AppointmentParticipantsListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/participant',
  'get',
  '200'
>;

type AppontmentParticipantsListRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/activity_planning/measure/appointment/participant',
  'get'
>;

function* fetchAppointmentParticipantsList(
  action: PayloadAction<IAppointmentParticipantsListFetchPayload>
): Generator<any, void, AppointmentParticipantsListResponse> {
  const { appointmentID, measureID } = action.payload;

  try {
    yield put(
      activityPlanningAppointmentParticipantsListActions.setAppointmentParticipantsListLock(
        LoadingStatus.LOADING
      )
    );

    const request: AppontmentParticipantsListRequest = {
      query: {
        appointment_id: appointmentID,
        measure_id: measureID,
      },
      ...authAdd(),
    };

    const response = yield call(
      restCall,
      '/activity_planning/measure/appointment/participant',
      'get',
      request
    );

    const participantsList: IActivityPlanningAppointmentParticipant[] =
      response.participants.map<IActivityPlanningAppointmentParticipant>((participant) => ({
        id: participant.id,
        name: participant.name,
        department: participant.department,
      }));

    yield put(
      activityPlanningAppointmentParticipantsListActions.setAppointmentParticipantsList(
        participantsList
      )
    );

    yield put(
      activityPlanningAppointmentParticipantsListActions.setAppointmentParticipantsListLock(
        LoadingStatus.LOADED
      )
    );
  } catch (error) {
    console.log('Error on activity planing measures list fetch', error);
    yield put(
      activityPlanningAppointmentParticipantsListActions.setAppointmentParticipantsListLock(
        LoadingStatus.ERROR
      )
    );
  }
}

export const activityPlanningAppointmentParticipantsListSagas = [
  takeLatest(
    activityPlanningAppointmentParticipantsListActions.fetchAppointmentParticipantsList,
    fetchAppointmentParticipantsList
  ),
];
