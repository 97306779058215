import React, { useEffect, useState } from 'react';

import { CompetenciesCharts } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CompetenciesCharts';
import { Questionnaire } from '@/components/functions/PerformanceProfile/Questionnaire/Questionaire';
import { QuestionnaireHistory } from '@/components/functions/PerformanceProfile/QuestionnaireHistory/QuestionnaireHistory';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { PerformanceProfileViews } from '@/core/enums/functions/performanceProfile/performanceProfileViewEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';

export const PerformanceProfile: React.FC = () => {
  const [viewMode, setViewMode] = useState<PerformanceProfileViews>(
    PerformanceProfileViews.QuestionnaireHistory
  );

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  const viewModeDetails = useAppSelector(performanceProfileSelectors.viewMode);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID || !viewModeDetails) {
      return;
    }

    if (selectedPersonID in viewModeDetails) {
      setViewMode(viewModeDetails[selectedPersonID]);
    }

    return () => {
      dispatch(performanceProfileActions.setQuestionnaire(null));
      dispatch(performanceProfileActions.setCompetenceChartsData(null));
      dispatch(performanceProfileActions.setHistory([]));
    };
  }, [selectedPerson, viewModeDetails]);

  const handleBackToHistory = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.setViewDetailsViewMode({
        personID: selectedPersonID,
        viewMode: PerformanceProfileViews.QuestionnaireHistory,
      })
    );
  };

  const onViewChange = (view: PerformanceProfileViews) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      performanceProfileActions.setViewDetailsViewMode({
        personID: selectedPersonID,
        viewMode: view,
      })
    );
  };

  const performanceProfileViews: Record<PerformanceProfileViews, React.ReactNode> = {
    [PerformanceProfileViews.QuestionnaireHistory]: (
      <QuestionnaireHistory onViewChange={onViewChange} />
    ),
    [PerformanceProfileViews.Charts]: (
      <CompetenciesCharts backToQuestionnaireHistory={handleBackToHistory} />
    ),
    [PerformanceProfileViews.Questionnaire]: (
      <Questionnaire navigateToQuestionnaires={handleBackToHistory} />
    ),
  };

  return (
    <FunctionWrapper>
      <FunctionContent>{performanceProfileViews[viewMode]}</FunctionContent>
    </FunctionWrapper>
  );
};
