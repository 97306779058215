import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultActions } from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';
import { LoadingStatus } from '@/types/loadingStatus';

export const ImportInternalDifferentiationModalContent: React.FC = () => {
  const { t: importInternalDifferentiationModalTranslations } = useTranslation(
    'importInternalDifferentiationModal'
  );

  const { importInternalDifferentiationLock, importInternalDifferentiation, payload } =
    useAppSelector(testingResultModalsSelectors.importInternalDifferentiationModal);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(testingResultModalsActions.closeImportInternalDifferentiationModal());
  };

  const handleSave = () => {
    if (!importInternalDifferentiation) {
      return;
    }

    dispatch(testingResultActions.setInternalDifferentiation(importInternalDifferentiation));
    dispatch(testingResultActions.setIsShouldUpdate(true));
    handleClose();
  };

  useEffect(() => {
    if (!payload || !payload.personID) {
      return;
    }

    dispatch(
      testingResultModalsActions.fetchImportInternalDifferentiation({
        personID: payload.personID,
      })
    );
  }, []);

  return (
    <Modal onExit={handleClose}>
      <div className={'flex flex-col gap-3 min-w-[400px]'}>
        <Skeleton trigger={importInternalDifferentiationLock === LoadingStatus.LOADING}>
          <Typography type={'H3'}>
            {importInternalDifferentiationModalTranslations('warning.label')}
          </Typography>
          <div className={'flex flex-row gap-3 justify-center mt-4'}>
            <Button
              onClick={handleSave}
              disabled={importInternalDifferentiationLock !== LoadingStatus.LOADED}
            >
              {importInternalDifferentiationModalTranslations('buttons.save.label')}
            </Button>
            <Button onClick={handleClose} buttonVariant={'Secondary'}>
              {importInternalDifferentiationModalTranslations('buttons.cancel.label')}
            </Button>
          </div>
        </Skeleton>
      </div>
    </Modal>
  );
};
