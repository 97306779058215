import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { IPersonRequirementTimeGroup } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export interface IPersonRequirementTimeGroupForm {
  sum?: number | null;
  items: IPersonRequirementTimeGroupItemForm[];
}

export interface IPersonRequirementTimeGroupItemForm {
  id: number;
  value?: number | null;
  comment?: string | null;
  minutesPerDay?: number | null;
  isInGroup?: boolean | null;
  cycleID?: number | null;
}

export interface IPersonRequirementTimeListForm {
  isOnlyActive?: boolean;
  sum?: number;
  personRequirementTimeListGroups: IPersonRequirementTimeGroupForm[];
}

export function usePersonRequirementTimeLIstForm(
  personRequirementTimeGroups: IPersonRequirementTimeGroup[]
) {
  const [totalMinutesSum, setTotalMinutesSum] = useState<number | null>(null);

  const form = useForm<IPersonRequirementTimeListForm>({
    defaultValues: {
      isOnlyActive: false,
      sum: 0,
      personRequirementTimeListGroups: [],
    },
  });

  const currentValues = form.watch();
  const formGroups = form.watch('personRequirementTimeListGroups');

  useEffect(() => {
    const newFormState: IPersonRequirementTimeListForm = {
      personRequirementTimeListGroups: personRequirementTimeGroups,
    };

    form.reset(newFormState);
  }, [personRequirementTimeGroups]);

  useEffect(() => {
    const groupsMinutesSum = formGroups
      ? formGroups.reduce<number>((accum, formGroup) => {
          return formGroup.sum ? accum + formGroup.sum : accum;
        }, 0)
      : 0;

    const formattedSum = groupsMinutesSum.toFixed(2);
    const parsedSum = parseFloat(formattedSum);

    if (parsedSum === totalMinutesSum) {
      return;
    }

    form.setValue('sum', parsedSum);
    setTotalMinutesSum(parsedSum);
  }, [currentValues]);

  return form;
}
