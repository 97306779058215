import React from 'react';

import { ActivityPlanningWizardContainer } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContainer/ActivityPlanningWizardContainer';
import {
  ActivityPlaningWizardProvider,
  IActivityPlaningWizardContext,
} from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardContext/ActivityPlanningContext';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';

export const ActivityPlanningWizardVersion: React.FC = () => {
  const initialState: IActivityPlaningWizardContext = {
    selectedMeasureID: null,
    selectedMeasureName: null,
    selectedAppointmentID: null,
    personLocation: null,
    wizardStep: 0,
  };

  return (
    <FunctionWrapper>
      <FunctionContent className={'p-5 pb-0'}>
        <ActivityPlaningWizardProvider state={initialState}>
          <ActivityPlanningWizardContainer />
        </ActivityPlaningWizardProvider>
      </FunctionContent>
    </FunctionWrapper>
  );
};
