import React from 'react';

import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Department } from '@/components/views/person/PersonPage/DepartmentView/Department/Department';
import useFetchedDepartments from '@/components/views/person/PersonPage/DepartmentView/DepartmentList/hooks/useFetchedDepartments';

import { usePersonPageStoreSelector } from '../../Context/PersonPageStoreContext';

export const DepartmentList: React.FC = () => {
  const { departments, isDepartmentsInProgress } = useFetchedDepartments();

  const [isExpandedAll] = usePersonPageStoreSelector((store) => store.allDepartmentsListExpanded);

  return (
    <Skeleton trigger={isDepartmentsInProgress} rows={4} className={'p-[30px]'}>
      {departments?.map((element) => (
        <Department
          name={element.name}
          departments={element.departments}
          id={element.id}
          key={element.id}
          isExpanded={isExpandedAll}
        />
      ))}
    </Skeleton>
  );
};
