import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IModal {
  isRender: boolean;
}

export interface IVocationalTrainingAreaDocumentsModalFetchPayload {
  measureID: number;
}

export interface IVocationalTrainingAreaDocument {
  id: number;
  name: string;
}

export interface IVocationalTrainingAreaDocumentsModal extends IModal {
  payload: IVocationalTrainingAreaDocumentsModalFetchPayload | null;
  vocationalTrainingAreaDocuments: IVocationalTrainingAreaDocument[] | null;
  vocationalTrainingAreaDocumentsLock: LoadingStatus;
}

export interface IVocationalTrainingAreaModalState {
  vocationalTrainingAreaDocumentsModal: IVocationalTrainingAreaDocumentsModal;
}

const initialState: IVocationalTrainingAreaModalState = {
  vocationalTrainingAreaDocumentsModal: {
    isRender: false,
    payload: null,
    vocationalTrainingAreaDocuments: null,
    vocationalTrainingAreaDocumentsLock: LoadingStatus.NEVER,
  },
};

const vocationalTrainingAreaModalSlice = createSlice({
  name: 'vocationalTrainingAreaModals',
  initialState,
  reducers: {
    openVocationalTrainingAreaDocuments: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocumentsModalFetchPayload>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        isRender: true,
        payload: action.payload,
      },
    }),
    closeVocationalTrainingAreaDocuments: (state) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        isRender: false,
        payload: null,
        vocationalTrainingAreaDocuments: null,
        vocationalTrainingAreaDocumentsLock: LoadingStatus.NEVER,
      },
    }),
    fetchVocationalTrainingAreaDocuments: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocumentsModalFetchPayload>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        payload: action.payload,
      },
    }),
    setVocationalTrainingAreaDocuments: (
      state,
      action: PayloadAction<IVocationalTrainingAreaDocument[] | null>
    ) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        vocationalTrainingAreaDocuments: action.payload,
      },
    }),
    setVocationalTrainingAreaDocumentsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      vocationalTrainingAreaDocumentsModal: {
        ...state.vocationalTrainingAreaDocumentsModal,
        vocationalTrainingAreaDocumentsLock: action.payload,
      },
    }),
  },
});

export const vocationalTrainingAreaModalReducer = vocationalTrainingAreaModalSlice.reducer;
export const vocationalTrainingAreaModalActions = vocationalTrainingAreaModalSlice.actions;
