import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { ReviewItem } from '@/components/forms/CompetenceAnalysis/ReviewItem/ReviewItem';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

export const SocialCommunicativeSkills: React.FC = () => {
  const { t: socialSkillTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'socialSkills',
  });

  return (
    <Accordion
      title={<Typography type={'H3'}>{socialSkillTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'grid grid-cols-[repeat(20,_minmax(0,_1fr))] items-center mt-4'}>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-6'}>
          {socialSkillTranslations('tableHeader.observedCompetencies.label')}
        </Typography>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-2 p-2'}>
          {socialSkillTranslations('tableHeader.review.label')}
        </Typography>
        <Typography color={'Secondary'} type={'14-M'} className={'col-span-12 p-2'}>
          {socialSkillTranslations('tableHeader.restrictions.label')}
        </Typography>
      </div>
      <div className={'flex flex-col gap-2'}>
        <ReviewItem
          title={socialSkillTranslations('fields.sociability.label')}
          fieldName={'sociability'}
        />
        <ReviewItem
          title={socialSkillTranslations('fields.cooperation.label')}
          fieldName={'cooperation'}
        />
        <ReviewItem title={socialSkillTranslations('fields.team.label')} fieldName={'team'} />
        <ReviewItem
          title={socialSkillTranslations('fields.takeCriticism.label')}
          fieldName={'takeCriticism'}
        />
      </div>
    </Accordion>
  );
};
