import { PayloadAction } from '@reduxjs/toolkit';
import type { NormalizeOAS, OASOutput } from 'fets';
import { OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { participantExpectationsChangeTypes } from '@/core/enums/participantExpectationsChangeTypesEnum';
import { qualificationDocumentationStatusEnum } from '@/core/enums/qualificationDocumentationStatusEnum';
import { integrationPlanActions } from '@/core/redux/slices/functions/integrationPlan/integrationPlanSlice';
import {
  IQualificationObjectivesDocumentationsItem,
  IStatusReport,
  IStatusReportFetchPayload,
  IStatusReportUpdatePayload,
  statusReportActions,
} from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { getSelectedOption } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type StatusReportResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/integration_plan/status_report',
  'get',
  '200'
>;
type StatusReportRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/integration_plan/status_report',
  'post'
>;

interface IQualificationObjectivesDocumentationsItemRequest {
  qualification_objective_id: number;
  is_not_started: boolean;
  not_started_reason: string | null;
}

interface IQualificationObjectivesDocumentationsItemResponse {
  qualification_objective_id: number;
  is_started: boolean;
  is_achieved: boolean;
  is_not_started: boolean;
  qualification_objective_name: string;
  not_started_reason?: string | null;
  extension?: string | null;
}

const mapQualificationsDocumentationResponse = (
  qualificationDocumentations?: IQualificationObjectivesDocumentationsItemResponse[]
): Record<string, IQualificationObjectivesDocumentationsItem> => {
  if (!qualificationDocumentations) {
    return {};
  }

  return qualificationDocumentations.reduce<
    Record<string, IQualificationObjectivesDocumentationsItem>
  >((accum, documentation) => {
    const statusBlock: Record<string, boolean> = {
      [qualificationDocumentationStatusEnum.isStarted]: documentation.is_started,
      [qualificationDocumentationStatusEnum.isAchieved]: documentation.is_achieved,
      [qualificationDocumentationStatusEnum.isNotStarted]: documentation.is_not_started,
    };

    return {
      ...accum,
      [documentation.qualification_objective_id]: {
        selectedOption: getSelectedOption(statusBlock),
        qualificationObjectiveStatusComment: documentation.not_started_reason,
        qualificationObjectiveName: documentation.qualification_objective_name,
        qualificationObjectiveExtension: documentation.extension,
      },
    };
  }, {});
};

const mapQualificationsDocumentationRequest = (
  qualificationDocumentations?: Record<string, IQualificationObjectivesDocumentationsItem>
): IQualificationObjectivesDocumentationsItemRequest[] => {
  if (!qualificationDocumentations) {
    return [];
  }

  const mappedQualificationsDocumentation: IQualificationObjectivesDocumentationsItemRequest[] = [];

  const qualificationsKeys = Object.keys(qualificationDocumentations);

  qualificationsKeys.forEach((qualificationKey) => {
    const newQualificationItem: IQualificationObjectivesDocumentationsItemRequest = {
      qualification_objective_id: parseInt(qualificationKey),
      is_not_started:
        qualificationDocumentations[qualificationKey].selectedOption ===
        qualificationDocumentationStatusEnum.isNotStarted,
      not_started_reason:
        qualificationDocumentations[qualificationKey].qualificationObjectiveStatusComment ?? null,
    };

    mappedQualificationsDocumentation.push(newQualificationItem);
  });

  return mappedQualificationsDocumentation;
};

function* fetchStatusReport(
  action: PayloadAction<IStatusReportFetchPayload>
): Generator<any, void, StatusReportResponse> {
  yield put(statusReportActions.setStatusReportLock(LoadingStatus.LOADING));

  const { integrationPlanID, personID } = action.payload;

  try {
    const response = yield call(restCall, '/integration_plan/status_report', 'get', {
      query: { integration_plan_id: integrationPlanID, person_id: personID },
      ...authAdd(),
    });

    const { status_report, qualification_objectives_documentations } = response;

    const participantExpectationsChangeBlock = {
      [participantExpectationsChangeTypes.Changed]:
        status_report.is_participant_expectation_changed_yes,
      [participantExpectationsChangeTypes.UnChanged]:
        status_report.is_participant_expectation_changed_no,
    };

    const statusReport: IStatusReport = {
      documentNumber: status_report.document_number,
      actionCourseTimestamp: toClientDateInput(status_report.action_course_timestamp),
      isFirstYearVocationalTraining: status_report.is_first_year_vocational_training,
      isSecondYearVocationalTraining: status_report.is_second_year_vocational_training,
      isFurtherIndividualAppointment: status_report.is_further_individual_appointment,
      furtherIndividualAppointmentReason: status_report.further_individual_appointment_reason,
      reportingPeriodFromTimestamp: toClientDateInput(
        status_report.reporting_period_from_timestamp
      ),
      reportingPeriodToTimestamp: toClientDateInput(status_report.reporting_period_to_timestamp),
      isCurrentlyNoQuestions: status_report.is_currently_no_questions,
      isQuestionsNeedClarification: status_report.is_questions_need_clarification,
      questionsText: status_report.questions_text,
      isCompetenceAnalysisUnchanged: status_report.is_competence_analysis_unchanged,
      isNewClassification: status_report.is_new_classification,
      isActivityOriented: status_report.is_activity_oriented,
      isOccupationalFieldOriented: status_report.is_occupational_field_oriented,
      isWorkplaceOriented: status_report.is_workplace_oriented,
      isJobProfileOriented: status_report.is_job_profile_oriented,
      isParticipantExpectationChanged: getSelectedOption(participantExpectationsChangeBlock),
      participantExpectationChangeText: status_report.participant_expectation_change_text,
      participantExpectationChangeMethod: status_report.participant_expectation_change_method,
      isAdditionalRequirements: status_report.is_additional_requirements,
      isContinued: status_report.is_continued,
      isVocationalTrainingPlaceRealistic: status_report.is_vocational_training_place_realistic,
      isTransitionToGeneralLaborMarketRealistic:
        status_report.is_transition_to_general_labor_market_realistic,
      isDiscussionRequired: status_report.is_discussion_required,
      qualificationObjectivesDocumentations: mapQualificationsDocumentationResponse(
        qualification_objectives_documentations
      ),
    };

    yield put(statusReportActions.setStatusReport(statusReport));

    yield put(statusReportActions.setStatusReportLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan status report fetching');
    yield put(statusReportActions.setStatusReportLock(LoadingStatus.ERROR));
  }
}

function* updateStatusReport(
  action: PayloadAction<IStatusReportUpdatePayload>
): Generator<any, void, any> {
  const { integrationPlanID, personID, statusReport } = action.payload;
  try {
    yield put(statusReportActions.setUpdateStatusReportLock(LoadingStatus.LOADING));

    const request: StatusReportRequest = {
      query: {
        person_id: personID,
        integration_plan_id: integrationPlanID,
      },
      json: {
        status_report: {
          is_occupational_field_oriented: statusReport?.isOccupationalFieldOriented,
          is_workplace_oriented: statusReport?.isWorkplaceOriented,
          is_job_profile_oriented: statusReport?.isJobProfileOriented,
          is_first_year_vocational_training: statusReport?.isFirstYearVocationalTraining,
          is_second_year_vocational_training: statusReport?.isSecondYearVocationalTraining,
          is_further_individual_appointment: statusReport?.isFurtherIndividualAppointment,
          is_currently_no_questions: statusReport?.isCurrentlyNoQuestions,
          is_questions_need_clarification: statusReport?.isQuestionsNeedClarification,
          is_competence_analysis_unchanged: statusReport?.isCompetenceAnalysisUnchanged,
          is_new_classification: statusReport?.isNewClassification,
          is_activity_oriented: statusReport?.isActivityOriented,
          is_participant_expectation_changed_no:
            statusReport?.isParticipantExpectationChanged ===
            participantExpectationsChangeTypes.UnChanged,
          is_participant_expectation_changed_yes:
            statusReport?.isParticipantExpectationChanged ===
            participantExpectationsChangeTypes.Changed,
          is_additional_requirements: statusReport?.isAdditionalRequirements,
          is_continued: statusReport?.isContinued,
          is_vocational_training_place_realistic: statusReport?.isVocationalTrainingPlaceRealistic,
          is_transition_to_general_labor_market_realistic:
            statusReport?.isTransitionToGeneralLaborMarketRealistic,
          is_discussion_required: statusReport?.isDiscussionRequired,
          further_individual_appointment_reason:
            statusReport.furtherIndividualAppointmentReason ?? null,
          action_course_timestamp: toBackendDate(statusReport.actionCourseTimestamp),
          participant_expectation_change_method:
            statusReport.participantExpectationChangeMethod ?? null,
          participant_expectation_change_text:
            statusReport.participantExpectationChangeText ?? null,
          questions_text: statusReport.questionsText ?? null,
          reporting_period_from_timestamp: toBackendDate(statusReport.reportingPeriodFromTimestamp),
          reporting_period_to_timestamp: toBackendDate(statusReport.reportingPeriodToTimestamp),
        },
        qualification_objectives_documentations: mapQualificationsDocumentationRequest(
          statusReport?.qualificationObjectivesDocumentations
        ),
      },
      ...authAdd(),
    };

    const updateResponse = yield call(restCall, '/integration_plan/status_report', 'post', {
      ...request,
    });

    yield put(
      integrationPlanActions.checkIntegrationPlanUpdate({
        integrationPlanID,
        updateResponse: updateResponse,
      })
    );

    yield put(statusReportActions.setUpdateStatusReportLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on integration plan status report updating');
    yield put(statusReportActions.setUpdateStatusReportLock(LoadingStatus.ERROR));
  }
}

export const statusReportSagas = [
  takeLatest(statusReportActions.fetchStatusReport, fetchStatusReport),
  takeLatest(statusReportActions.updateStatusReport, updateStatusReport),
];
