import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { IAddressType } from '@/core/redux/slices/functions/personGeneralData/addresses/addressesSlice';
import { colorTypes } from '@/styles/types';

export interface IAddressSelectType {
  addressTypes: IAddressType[];
}

export const AddressSelectType: React.FC<IAddressSelectType> = ({ addressTypes }) => {
  const { t: displayAddressModalTranslations } = useTranslation('displayAddressModal');

  const options: IRadioButtonGroupItem[] = addressTypes.map((addressType) => ({
    value: String(addressType.id),
    element: addressType.name,
  }));

  return (
    <div className='flex flex-col gap-6 pr-3'>
      <Typography type={'H3'} className={`border-b border-b-${colorTypes.Stroke} pb-default`}>
        {displayAddressModalTranslations('types.title.label')}
      </Typography>
      <RadioButtonGroup
        options={options}
        name={'addresseeTypeId'}
        className={'grid grid-cols-3 gap-3 gap-x-7 items-start'}
        itemClassName={'items-start'}
      />
    </div>
  );
};
