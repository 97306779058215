import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Input } from '@/components/Input/Input';
import { IPersonRequirementTimeListForm } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModalContent/hooks/usePersonRequirementTimeLIstForm';
import { Typography } from '@/components/Typography/Typography';

export const PersonRequirementTimeListFilters: React.FC = () => {
  const { t: personRequirementTimeListModalTranslations } = useTranslation(
    'personRequirementTimeListModal',
    { keyPrefix: 'filters' }
  );

  const { control } = useFormContext<IPersonRequirementTimeListForm>();

  return (
    <div className={'flex flex-row gap-5'}>
      <div className={'flex flex-row gap-2 items-center'}>
        <Checkbox name={'isOnlyActive'} id={'isOnlyActive'} />
        <label htmlFor={'isOnlyActive'}>
          <Typography>{personRequirementTimeListModalTranslations('active.label')}</Typography>
        </label>
      </div>
      <div className={'flex flex-row gap-2 items-center'}>
        <Typography>{personRequirementTimeListModalTranslations('sum.label')}</Typography>
        <Input
          control={control.register('sum')}
          readOnly={true}
          className={'w-[80px] p-2 text-center'}
        />
      </div>
    </div>
  );
};
