import { FormItem } from '@/components/FormRender/FormRender';

export const firstColumn: FormItem[] = [
  {
    name: 'gender',
    label: 'fields.gender.label',
    type: 'select',
    options: [
      {
        id: 1,
        name: 'One',
      },
      {
        id: 2,
        name: 'Two',
      },
    ],
  },
  {
    name: 'name',
    label: 'fields.name.label',
    type: 'input',
  },
  {
    name: 'surname',
    label: 'fields.surname.label',
    type: 'input',
  },
  {
    name: 'salutation',
    label: 'fields.salutation.label',
    type: 'select',
    options: [
      {
        id: 1,
        name: 'One',
      },
      {
        id: 2,
        name: 'Two',
      },
    ],
  },
  {
    name: 'roles',
    label: 'fields.roles.label',
    type: 'checkbox_group',
    layout: 'vertical',
    options: [
      {
        id: 1,
        name: 'name1',
      },
      {
        id: 2,
        name: 'name2',
      },
      {
        id: 3,
        name: 'name3',
      },
      {
        id: 4,
        name: 'name4',
      },
      {
        id: 5,
        name: 'name5',
      },
    ],
  },
  {
    name: 'is_educational_supporter',
    label: 'fields.is_educational_supporter.label',
    type: 'checkbox',
    className: 'w-1/2',
  },
  {
    name: 'is_group_manager',
    label: 'fields.is_group_manager.label',
    type: 'checkbox',
    className: 'w-1/2',
  },
  {
    name: 'is_internship_supervisor',
    type: 'checkbox',
    label: 'fields.is_internship_supervisor.label',
    className: 'w-1/2',
  },
  {
    name: 'is_jobcoach',
    label: 'fields.is_jobcoach.label',
    type: 'checkbox',
    className: 'w-1/2',
  },
  {
    name: 'is_manager',
    type: 'checkbox',
    label: 'fields.is_manager.label',
    className: 'w-1/2',
  },
  {
    name: 'is_social_service_worker',
    type: 'checkbox',
    label: 'fields.is_social_service_worker.label',
    className: 'w-1/2',
  },
  {
    name: 'is_specialist',
    label: 'fields.is_specialist.label',
    type: 'checkbox',
    className: 'w-1/2',
  },
];

export const secondColumn: FormItem[] = [
  {
    type: 'datepicker',
    name: 'birth_date',
    label: 'fields.birth_date.label',
  },
  {
    name: 'telephone_number',
    label: 'fields.telephone_number.label',
    type: 'input',
  },
  {
    name: 'email',
    label: 'fields.email.label',
    type: 'input',
  },
  {
    name: 'target_catalog_types',
    label: 'fields.target_catalog_types.label',
    type: 'checkbox_group',
    layout: 'vertical',
    options: [
      {
        id: 1,
        name: '1',
      },
      {
        id: 2,
        name: '2',
      },
      {
        id: 3,
        name: '3',
      },
      {
        id: 4,
        name: '4',
      },
      {
        id: 5,
        name: '5',
      },
    ],
  },
  {
    name: 'profession',
    label: 'fields.profession.label',
    type: 'textarea',
  },
  {
    name: 'profession_since_timestamp',
    label: 'fields.profession_since_timestamp.label',
    type: 'datepicker',
  },
];
