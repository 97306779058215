import fp from 'lodash/fp';

import { IDepartment } from '@/types/department';
import { ILocation } from '@/types/location';
import { SortingBy } from '@/types/sortingBy';

export function searchByName(departments: IDepartment[], searchValue: string): IDepartment[] {
  if (fp.isEmpty(searchValue)) {
    return departments;
  }

  const search = searchValue.toLowerCase();
  const filteredArray = departments.map((department) => {
    if (department.name.toLowerCase().includes(search)) {
      return department;
    }

    const filteredLocations = department.departments.filter((department) =>
      department.name.toLowerCase().includes(search)
    );

    return { ...department, locations: filteredLocations };
  });

  return filteredArray.filter((department) => department.departments.length > 0);
}

export function sortLocations(department: IDepartment, sortBy: SortingBy) {
  const copiedLocations = [...department.departments];
  const sortedLocations = copiedLocations.sort((a, b) => sortByName(a, b, sortBy));

  return {
    ...department,
    departments: sortedLocations,
  };
}

export function sortByName(
  a: IDepartment | ILocation,
  b: IDepartment | ILocation,
  sortBy: SortingBy
) {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();

  return sortBy === SortingBy.nameAscend ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
}
