import React from 'react';

import { UpdateOrganizationalUnitModalContent } from '@/components/Modals/Modals/functions/organizationalUnitsModals/UpdateOrganizationalUnitModal/UpdateOrganizationalUnitModalContent/UpdateOrganizationalUnitModalContent';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export const UpdateOrganizationalUnitModal: React.FC = () => {
  const { isRender: isOpened, payload } = useAppSelector(
    organizationalUnitsModalsSelectors.updateOrganizationalUnitModal
  );

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(organizationalUnitsModalsActions.closeUpdateOrganizationalUnitModal());
  };

  return (
    isOpened &&
    payload && (
      <UpdateOrganizationalUnitModalContent
        personID={payload.personID}
        organizationalUnit={payload.organizationalUnit}
        handleExit={handleExit}
      />
    )
  );
};
