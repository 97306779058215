import React, { useEffect } from 'react';

import { VocationalTrainingAreaGroupedListGroup } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaGroupedList/VocationalTrainingAreaGroupedListGroup/VocationalTrainingAreaGroupedListGroup';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaSelectors } from '@/core/redux/slices/functions/vocationalTrainingArea/selector';
import { vocationalTrainingAreaActions } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';

export const VocationalTrainingAreaGroupedList: React.FC = () => {
  const vocationalTrainingAreaGroupedList = useAppSelector(
    vocationalTrainingAreaSelectors.vocationalTrainingAreaGroups
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(vocationalTrainingAreaActions.setVocationalTrainingAreaGroups(null));
    };
  }, []);

  return (
    <div>
      {vocationalTrainingAreaGroupedList &&
        vocationalTrainingAreaGroupedList.map((vocationalTrainingAreaGroup) => (
          <VocationalTrainingAreaGroupedListGroup
            {...vocationalTrainingAreaGroup}
            id={vocationalTrainingAreaGroup.id}
            layer={0}
          />
        ))}
    </div>
  );
};
