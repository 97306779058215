import React from 'react';

import { useDialog } from '@/components/Dialog/hooks/useDialog';

import { DialogContext } from './DialogContext/DialogContext';

export interface IDialog {
  onConfirm?: () => void;
  onCancel?: () => void;
}

export interface DialogOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export function Dialog({
  children,
  ...options
}: {
  children: React.ReactNode;
} & DialogOptions) {
  const dialog = useDialog(options);
  return <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>;
}
