import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import FormRender from '@/components/FormRender/FormRender';
import { Typography } from '@/components/Typography/Typography';

import { characteristicForm, disabilityForm } from './helpers/formTemplates';

export interface IDisability {
  personID?: number | null;
}

export const Disability: React.FC<IDisability> = ({ personID }) => {
  const { t: diagnosticsDisabilityModal } = useTranslation('diagnosticsDisabilityModal');

  const methods = useForm<any>();
  const onSubmit = (data: any) => console.log('data');
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className='flex flex-col gap-4'>
          <div>
            <FormRender list={disabilityForm} translationContext={diagnosticsDisabilityModal} />
          </div>
          <div className='flex flex-col gap-2'>
            <Typography type='H3'>
              {diagnosticsDisabilityModal('characteristics.title.label')}
            </Typography>
            <FormRender list={characteristicForm} translationContext={diagnosticsDisabilityModal} />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
