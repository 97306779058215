/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  addressesActions,
  IAddressCreatePayload,
  IAddressData,
  IAddressDataFetchPayload,
  IAddressInfo,
  IAddressInfoFetchPayload,
  IAddressType,
  IAddressUpdatePayload,
} from './addressesSlice';

type AddressDataResponse = OASOutput<NormalizeOAS<typeof oas>, '/person/addressee', 'get', '200'>;
type AddressInfoResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/person/addressee/info',
  'get',
  '200'
>;
type AddressCreateRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/person/addressee', 'post'>;
type AddressUpdateRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/person/addressee', 'put'>;
type AddressDeleteRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/person/addressee',
  'delete'
>;

function* fetchAddressData(
  action: PayloadAction<IAddressDataFetchPayload>
): Generator<any, void, AddressDataResponse> {
  yield put(addressesActions.setAddressDataLock(LoadingStatus.LOADING));

  const { personID } = action.payload;

  try {
    const response = yield call(restCall, '/person/addressee', 'get', {
      query: { person_id: personID },
      ...authAdd(),
    });

    const { addressees } = response;

    const addressesData: IAddressData[] = toCamelCase(addressees);

    yield put(addressesActions.setAddressData(addressesData));

    yield put(addressesActions.setAddressDataLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on person general data fetching');
    yield put(addressesActions.setAddressDataLock(LoadingStatus.ERROR));
  }
}

function* fetchAddressInfo(
  action: PayloadAction<IAddressInfoFetchPayload>
): Generator<any, void, AddressInfoResponse> {
  yield put(addressesActions.setAddressInfoLock(LoadingStatus.LOADING));

  const { addressID, personID } = action.payload;

  try {
    const response = yield call(restCall, '/person/addressee/info', 'get', {
      query: { addressee_id: addressID, person_id: personID },
      ...authAdd(),
    });

    const { addressee } = response;

    const addressInfo: IAddressInfo = {
      address: addressee.address,
      cellPhoneNumber: addressee.cell_phone_number,
      email: addressee.email,
      isLegalGuardian: addressee.is_legal_guardian,
      name: addressee.name,
      telephoneNumber: addressee.telephone_number,
      location: addressee.location,
      surname: addressee.surname,
      notes: addressee.notes,
      salutationId: addressee.salutation?.id,
      addresseeTypeId: String(
        addressee.addressee_types.find((item: IAddressType) => item.is_checked)?.id
      ),
      plz: addressee.plz,
      fax: addressee.fax,
    };

    const addressTypes: IAddressType[] = addressee.addressee_types;

    yield put(addressesActions.setAddressInfo(addressInfo));
    yield put(addressesActions.setAddressTypes(addressTypes));

    yield put(addressesActions.setAddressInfoLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on person general data fetching');
    yield put(addressesActions.setAddressInfoLock(LoadingStatus.ERROR));
  }
}

function* updateAddress(action: PayloadAction<IAddressUpdatePayload>): Generator<any, void, any> {
  const { personID, addressID, addressInfo } = action.payload;

  try {
    yield put(addressesActions.setUpdateAddressLock(LoadingStatus.LOADING));

    const request: AddressUpdateRequest = {
      json: {
        id: addressID,
        address: addressInfo.address!,
        cell_phone_number: addressInfo.cellPhoneNumber!,
        email: addressInfo.email!,
        is_legal_guardian: addressInfo.isLegalGuardian!,
        name: addressInfo.name!,
        telephone_number: addressInfo.telephoneNumber!,
        location: addressInfo.location!,
        surname: addressInfo.surname!,
        notes: addressInfo.notes!,
        addressee_type_id: Number(addressInfo.addresseeTypeId!),
        plz: addressInfo.plz!,
        fax: addressInfo.fax!,
        salutation_id: addressInfo.salutationId!,
      },
      ...authAdd(),
    };

    yield call(restCall, '/person/addressee', 'put', request);

    yield put(addressesActions.setUpdateAddressLock(LoadingStatus.LOADED));

    yield put(
      addressesActions.fetchAddressData({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on address update', error);
    yield put(addressesActions.setUpdateAddressLock(LoadingStatus.ERROR));
  }
}

function* createAddress(action: PayloadAction<IAddressCreatePayload>): Generator<any, void, any> {
  const { personID, addressInfo } = action.payload;

  try {
    yield put(addressesActions.setCreateAddressLock(LoadingStatus.LOADING));

    const request: AddressCreateRequest = {
      json: {
        person_id: personID,
        address: addressInfo.address!,
        cell_phone_number: addressInfo.cellPhoneNumber!,
        email: addressInfo.email!,
        is_legal_guardian: addressInfo.isLegalGuardian!,
        name: addressInfo.name!,
        telephone_number: addressInfo.telephoneNumber!,
        location: addressInfo.location!,
        surname: addressInfo.surname!,
        notes: addressInfo.notes!,
        addressee_type_id: Number(addressInfo.addresseeTypeId!),
        plz: addressInfo.plz!,
        fax: addressInfo.fax!,
        salutation_id: addressInfo.salutationId!,
      },
      ...authAdd(),
    };

    yield call(restCall, '/person/addressee', 'post', request);

    yield put(addressesActions.setCreateAddressLock(LoadingStatus.LOADED));

    yield put(
      addressesActions.fetchAddressData({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on address create', error);
    yield put(addressesActions.setCreateAddressLock(LoadingStatus.ERROR));
  }
}

function* deleteAddress(action: PayloadAction<any>): Generator<any, void, any> {
  const { addressID, personID } = action.payload;

  try {
    yield put(addressesActions.setDeleteAddressLock(LoadingStatus.LOADING));

    const request: AddressDeleteRequest = {
      query: { addressee_id: addressID },
      ...authAdd(),
    };

    yield call(restCall, '/person/addressee', 'delete', request);

    yield put(addressesActions.setDeleteAddressLock(LoadingStatus.LOADED));

    yield put(
      addressesActions.fetchAddressData({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on address delete', error);
    yield put(addressesActions.setCreateAddressLock(LoadingStatus.ERROR));
  }
}

export const addressesDataSagas = [
  takeLatest(addressesActions.fetchAddressData, fetchAddressData),
  takeLatest(addressesActions.fetchAddressInfo, fetchAddressInfo),
  takeLatest(addressesActions.createAddress, createAddress),
  takeLatest(addressesActions.updateAddress, updateAddress),
  takeLatest(addressesActions.deleteAddress, deleteAddress),
];
