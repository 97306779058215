import React from 'react';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip';
import { v4 } from 'uuid';

import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { FUNCTIONS_URL } from '@/core/constants/urls';
import { useAppDispatch } from '@/core/redux/hooks';
import { functionPageActions } from '@/core/redux/slices/functionPage/functionPageSlice';
import { commonModalsActions } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { twMerge } from '@/core/utils/tailwindUtil';
import { IShortcutFunctionsItem } from '@/types/person';

import styles from './styles.module.scss';

interface IFunctionsShortcuts {
  modalContext: boolean;
  functions?: IShortcutFunctionsItem[];
  personID: number;
  isHovered?: boolean;
  className?: string;
}

export const PersonFunctionsShortcuts: React.FC<IFunctionsShortcuts> = ({
  modalContext,
  functions,
  personID,
  isHovered,
  className,
}) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleOpenFunction = (personID: number, functionItem: IShortcutFunctionsItem) => {
    const tabID = v4();

    dispatch(
      functionPageActions.addShortcutTab({
        personID: personID,
        tabID: tabID,
        functionID: String(functionItem.id),
      })
    );

    if (modalContext) {
      dispatch(commonModalsActions.closeAddPersonModal());
    }

    navigate(`${FUNCTIONS_URL}?personID=${personID}&activeTabID=${tabID}`);
  };

  return (
    <div className={twMerge('flex gap-4', className)}>
      {isHovered &&
        functions &&
        functions.map((item) => (
          <div key={item.id}>
            <div
              data-tooltip-id={String(item.id)}
              data-tooltip-content={item.tooltip}
              key={item.id}
              onClick={() => handleOpenFunction(personID, item)}
            >
              <PrivateImage
                id={item.iconFilenameDisk}
                alt={item.name}
                className={twMerge(styles.icon, 'w-4 h-4 cursor-pointer')}
              />
            </div>
            <Tooltip id={String(item.id)} style={{ zIndex: 100000 }} />
          </div>
        ))}
    </div>
  );
};
