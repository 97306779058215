import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.vocationalTrainingAreaModals;

const vocationalTrainingAreaDocumentsModal = createSelector(
  [selector],
  (state) => state.vocationalTrainingAreaDocumentsModal
);

export const vocationalTrainingAreaModalSelectors = {
  vocationalTrainingAreaDocumentsModal,
};
