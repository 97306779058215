/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toBackendDateTime, toClientDateInput } from '@/core/utils/dateTimeUtil';
import { readStreamAsBlob } from '@/core/utils/fileUtils';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IMedication,
  IMedicationCreatePayload,
  IMedicationDetails,
  IMedicationDetailsFetchPayload,
  IMedicationDownloadDocumentPayload,
  IMedicationsFetchPayload,
  IMedicationUpdatePayload,
  medicationActions,
} from './medicationSlice';

type MedicationsResponse = OASOutput<NormalizeOAS<typeof oas>, '/medication', 'get', '200'>;
type MedicationDetailsResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/medication/details',
  'get',
  '200'
>;
type MedicationCreateRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/medication', 'post'>;
type MedicationUpdateRequest = OASRequestParams<NormalizeOAS<typeof oas>, '/medication', 'put'>;
type StopAdministratingRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/medication',
  'delete'
>;

function* fetchMedications(
  action: PayloadAction<IMedicationsFetchPayload>
): Generator<any, void, MedicationsResponse> {
  yield put(medicationActions.setMedicationsLock(LoadingStatus.LOADING));

  const { personID, isActive } = action.payload;

  try {
    const response = yield call(restCall, '/medication', 'get', {
      query: { person_id: personID, is_active: isActive },
      ...authAdd(),
    });

    const medications: IMedication[] = toCamelCase(response.medications);

    yield put(medicationActions.setMedications(medications));

    yield put(medicationActions.setMedicationsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on medications data fetching');
    yield put(medicationActions.setMedicationsLock(LoadingStatus.ERROR));
  }
}

function* fetchMedicationDetails(
  action: PayloadAction<IMedicationDetailsFetchPayload>
): Generator<any, void, MedicationDetailsResponse> {
  yield put(medicationActions.setMedicationDetailsLock(LoadingStatus.LOADING));

  const { personID, medicationID } = action.payload;

  try {
    const response = yield call(restCall, '/medication/details', 'get', {
      query: {
        person_id: personID,
        medication_id: medicationID,
      },
      ...authAdd(),
    });

    const initialMedicationDetails = toCamelCase(response.medication);

    const medicationDetails: IMedicationDetails = {
      ...initialMedicationDetails,
      dosageFormId: initialMedicationDetails.dosageForm?.id,
      orderedFromTimestamp: toClientDateInput(initialMedicationDetails.orderedFromTimestamp),
      orderedToTimestamp: toClientDateInput(initialMedicationDetails.orderedToTimestamp),
      prescriptionFile: {
        fileName: response.medication.document_link,
      },
    };

    yield put(medicationActions.setMedicationDetails(medicationDetails));

    yield put(medicationActions.setMedicationDetailsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on medication details data fetching');
    yield put(medicationActions.setMedicationDetailsLock(LoadingStatus.ERROR));
  }
}

function* downloadMedicationDocument(
  action: PayloadAction<IMedicationDownloadDocumentPayload>
): Generator<any, void, any> {
  yield put(medicationActions.setDownloadMedicationDocumentLock(LoadingStatus.LOADING));

  const { medicationID } = action.payload;

  try {
    const response = yield call(
      restCall,
      '/medication/prescription',
      'get',
      {
        query: { medication_id: medicationID },
        ...authAdd(),
      },
      undefined,
      true
    );

    if (response.body) {
      const blob = yield readStreamAsBlob(response.body);
      const contentDisposition = response.headers.get('content-disposition');
      const fileName = contentDisposition.split("filename*=UTF-8''")[1];

      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = blobUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
    }

    yield put(medicationActions.setDownloadMedicationDocumentLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on medications document download');
    yield put(medicationActions.setDownloadMedicationDocumentLock(LoadingStatus.ERROR));
  }
}

function* updateMedication(
  action: PayloadAction<IMedicationUpdatePayload>
): Generator<any, void, any> {
  const { personID, medicationID, medicationData } = action.payload;

  try {
    yield put(medicationActions.setUpdateMedicationLock(LoadingStatus.LOADING));

    const request: MedicationUpdateRequest = {
      json: {
        id: medicationID,
        name: medicationData.name!,
        is_not_dispensed: medicationData.isNotDispensed!,
        is_remarked_on_giving: medicationData.isRemarkedOnAwarding,
        dosage_form_id: medicationData.dosageFormId,
        dosages: medicationData.dosages.map((item) => ({
          id: item.id!,
          in_workshop: item.inWorkshop!,
          dosage: item.dosage!,
        })),
        ordered_by: medicationData.orderedBy,
        ordered_from_timestamp: toBackendDate(medicationData.orderedFromTimestamp),
        ordered_to_timestamp: toBackendDate(medicationData.orderedToTimestamp),
        note: medicationData.note,
        document: medicationData.prescriptionFile
          ? {
              name: medicationData.prescriptionFile.fileName,
              content: medicationData.prescriptionFile.fileContent,
            }
          : null,
      },
      ...authAdd(),
    };

    yield call(restCall, '/medication', 'put', request);

    yield put(medicationActions.setUpdateMedicationLock(LoadingStatus.LOADED));

    yield put(
      medicationActions.fetchMedications({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on medication update', error);
    yield put(medicationActions.setUpdateMedicationLock(LoadingStatus.ERROR));
  }
}

function* createMedication(
  action: PayloadAction<IMedicationCreatePayload>
): Generator<any, void, any> {
  const { personID, medicationData } = action.payload;

  try {
    yield put(medicationActions.setCreateMedicationLock(LoadingStatus.LOADING));

    const request: MedicationCreateRequest = {
      json: {
        person_id: personID,
        name: medicationData.name!,
        is_not_dispensed: medicationData.isNotDispensed!,
        is_demanded: medicationData.isDemanded,
        is_remarked_on_giving: medicationData.isRemarkedOnAwarding,
        dosage_form_id: medicationData.dosageFormId,
        dosages: medicationData.dosages.map((item) => ({
          id: item.id!,
          in_workshop: item.inWorkshop!,
          dosage: item.dosage!,
        })),
        ordered_by: medicationData.orderedBy,
        ordered_from_timestamp: toBackendDate(medicationData.orderedFromTimestamp),
        ordered_to_timestamp: toBackendDate(medicationData.orderedToTimestamp),
        note: medicationData.note,
        document: medicationData.prescriptionFile
          ? {
              name: medicationData.prescriptionFile.fileName,
              content: medicationData.prescriptionFile.fileContent,
            }
          : null,
      },
      ...authAdd(),
    };

    yield call(restCall, '/medication', 'post', request);

    yield put(medicationActions.setCreateMedicationLock(LoadingStatus.LOADED));

    yield put(
      medicationActions.fetchMedications({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on medication update', error);
    yield put(medicationActions.setCreateMedicationLock(LoadingStatus.ERROR));
  }
}

function* stopAdministrating(action: PayloadAction<any>): Generator<any, void, any> {
  const { medicationID, personID, date } = action.payload;

  const queryDate = toBackendDateTime(date);

  try {
    yield put(medicationActions.setStopAdministratingLock(LoadingStatus.LOADING));

    const request: StopAdministratingRequest = {
      query: { medication_id: medicationID, date: queryDate },
      ...authAdd(),
    };

    yield call(restCall, '/medication', 'delete', request);

    yield put(medicationActions.setStopAdministratingLock(LoadingStatus.LOADED));

    yield put(
      medicationActions.fetchMedications({
        personID: personID,
      })
    );
  } catch (error) {
    console.log('Error on address delete', error);
    yield put(medicationActions.setStopAdministratingLock(LoadingStatus.ERROR));
  }
}

export const medicationSagas = [
  takeLatest(medicationActions.fetchMedications, fetchMedications),
  takeLatest(medicationActions.downloadMedicationDocument, downloadMedicationDocument),
  takeLatest(medicationActions.fetchMedicationDetails, fetchMedicationDetails),
  takeLatest(medicationActions.createMedication, createMedication),
  takeLatest(medicationActions.updateMedication, updateMedication),
  takeLatest(medicationActions.stopAdministrating, stopAdministrating),
];
