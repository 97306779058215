import React, { useEffect } from 'react';

import { VocationalTrainingAreaListItem } from '@/components/functions/VocationalTrainingArea/VocationalTrainingAreaTable/VocationalTrainingAreaList/VocationalTrainingAreaListItem/VocationalTrainingAreaListItem';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { vocationalTrainingAreaSelectors } from '@/core/redux/slices/functions/vocationalTrainingArea/selector';
import { vocationalTrainingAreaActions } from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

export const VocationalTrainingAreaList: React.FC = () => {
  const vocationalTrainingAreaItems = useAppSelector(
    vocationalTrainingAreaSelectors.vocationalTrainingAreaList
  );

  const oddColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemOddColor);
  const evenColor = useAppSelector(parametersSelectors.vocationalTrainingAreaItemEvenColor);

  const selectedTrainingArea = useAppSelector(
    vocationalTrainingAreaSelectors.selectedVocationalTrainingArea
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(vocationalTrainingAreaActions.setVocationalTrainingAreaList(null));
    };
  }, []);

  return (
    <div>
      {vocationalTrainingAreaItems &&
        vocationalTrainingAreaItems.map((item, index) => (
          <VocationalTrainingAreaListItem
            {...item}
            key={item.id}
            bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
            isSelected={selectedTrainingArea?.id === item.id}
          />
        ))}
    </div>
  );
};
