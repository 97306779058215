import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TextArea } from '@/components/TextArea/TextArea';

export interface ICommentItem {
  label?: React.ReactNode;
  fieldName: string;
  readOnly?: boolean;
}

export const CommentItem: React.FC<ICommentItem> = ({ label, fieldName, readOnly }) => {
  const { control } = useFormContext();

  return (
    <div className={'grid grid-cols-3 gap-4'}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <TextArea
        control={control.register(fieldName)}
        id={fieldName}
        containerClassName={'flex-grow col-span-2'}
        readOnly={readOnly}
      />
    </div>
  );
};
