import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { applyTimeStampMask } from '@/components/WorkingTimePlanner/WorkingTimePlannerContainer/WorkingTimePlannerContainerDaysList/WorkingTimePlannerContainerDay/helpers/applyTimeStampMask';
import { IWorkingTimeDayItem } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';

interface IWorkingTimePlannerContainerDay {
  day: IWorkingTimeDayItem;
}

export const WorkingTimePlannerContainerDay: React.FC<IWorkingTimePlannerContainerDay> = ({
  day,
}) => {
  const { t: workingTimePlannerTranslations } = useTranslation('workingTimePlanner', {
    keyPrefix: 'daysList.days',
  });

  const formContext = useFormContext();

  const getDayName = useCallback(
    (day: IWorkingTimeDayItem) => {
      switch (day.name) {
        case 'Montag':
          return workingTimePlannerTranslations('monday.label');
        case 'Dienstag':
          return workingTimePlannerTranslations('tuesday.label');
        case 'Mittwoch':
          return workingTimePlannerTranslations('wednesday.label');
        case 'Donnerstag':
          return workingTimePlannerTranslations('thursday.label');
        case 'Freitag':
          return workingTimePlannerTranslations('friday.label');
        case 'Samstag':
          return workingTimePlannerTranslations('saturday.label');
        case 'Sonntag':
          return workingTimePlannerTranslations('sunday.label');
        default:
          day.name;
      }
    },
    [day, workingTimePlannerTranslations]
  );

  const fromTimeStampFieldName = `workingWeek.workingDays.${day.id}.fromDateStamp`;
  const toTimeStampFieldName = `workingWeek.workingDays.${day.id}.toDateStamp`;

  const fillEmptyDigits = (fieldName: string) => {
    const value: string | null | undefined = formContext.getValues(fieldName);

    if (!value) {
      return;
    }

    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length > 0 && numericValue.length < 4) {
      const diff = 4 - numericValue.length;
      const zeros = '0'.repeat(diff);
      formContext.setValue(fieldName, applyTimeStampMask(value, numericValue + zeros));
    }

    formContext.trigger();
  };

  const parseNumbers = (previousValue: string, newValue: string) => {
    return newValue.replace(/\D/g, '');
  };

  return (
    <>
      <Typography className={'col-span-3 justify-self-start'}>{getDayName(day)}</Typography>
      <Input
        control={formContext.register(fromTimeStampFieldName)}
        inputVariant={'Outline'}
        onBlur={() => fillEmptyDigits(fromTimeStampFieldName)}
        handleChange={applyTimeStampMask}
        className={'text-center'}
      />
      <Input
        control={formContext.register(toTimeStampFieldName)}
        inputVariant={'Outline'}
        onBlur={() => fillEmptyDigits(toTimeStampFieldName)}
        handleChange={applyTimeStampMask}
        className={'text-center'}
      />
      <Input
        control={formContext.register(`workingWeek.workingDays.${day.id}.pauseDuration`)}
        inputVariant={'Outline'}
        className={'text-center'}
        type={'number'}
        handleChange={parseNumbers}
      />
    </>
  );
};
