import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const WorkingTimePlannerContainerHeader: React.FC = () => {
  const { t: workingTimePlannerTranslations } = useTranslation('workingTimePlanner', {
    keyPrefix: 'daysList.header',
  });

  return (
    <>
      <Typography className={'col-span-3 justify-self-start'}>
        {workingTimePlannerTranslations('weekDay.label')}
      </Typography>
      <Typography>{workingTimePlannerTranslations('fromTimestamp.label')}</Typography>
      <Typography>{workingTimePlannerTranslations('toTimestamp.label')}</Typography>
      <Typography>{workingTimePlannerTranslations('pauseDuration.label')}</Typography>
    </>
  );
};
