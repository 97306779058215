import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { colorTypes } from '@/styles/types';

import { CodesICD } from './CodesICD/CodesICD';
import { CommentItem } from './CommentItem/CommentItem';
import { Disability } from './Disability/Disability';
import { ItemsICF } from './ItemsICF/ItemsICF';

enum DiagnosticsTabs {
  CodesICD = 'codesICD',
  ItemsICF = 'itemsICF',
  Disability = 'disability',
}

interface IDiagnosticsViews {
  title: string;
  view: React.ReactNode;
  type: DiagnosticsTabs;
}

export const Diagnostics: React.FC = () => {
  const { t: diagnosticsTranslations } = useTranslation('diagnostics');

  const [activeTab, setActiveTab] = useState<DiagnosticsTabs>(DiagnosticsTabs.CodesICD);

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const personID = selectedPerson?.id;

  const views: Record<DiagnosticsTabs, IDiagnosticsViews> = useMemo(
    () => ({
      [DiagnosticsTabs.CodesICD]: {
        title: diagnosticsTranslations('tabs.icdCodes.label'),
        view: <CodesICD personID={personID} />,
        type: DiagnosticsTabs.CodesICD,
      },
      [DiagnosticsTabs.ItemsICF]: {
        title: diagnosticsTranslations('tabs.icfItems.label'),
        view: <ItemsICF personID={personID} />,
        type: DiagnosticsTabs.ItemsICF,
      },
      [DiagnosticsTabs.Disability]: {
        title: diagnosticsTranslations('tabs.disability.label'),
        view: <Disability personID={personID} />,
        type: DiagnosticsTabs.Disability,
      },
    }),
    [personID]
  );

  const methods = useForm();

  const handleTabClick = (activeTabType: DiagnosticsTabs) => {
    setActiveTab(activeTabType);
  };

  return (
    <FunctionWrapper>
      <FunctionContent className={'p-5'}>
        <div className={`flex gap-2 border-b border-b-${colorTypes.Stroke}`}>
          {Object.values(views).map((item) => (
            <Button
              buttonVariant={activeTab === item.type ? 'Default' : 'Secondary'}
              onClick={() => handleTabClick(item.type)}
              key={item.type}
              className={'rounded-b-none px-4 pt-2 pb-[7px] justify-start text-sm overflow-hidden '}
            >
              {item.title}
            </Button>
          ))}
        </div>
        <div className='w-full flex flex-col'>
          <div className='py-5 flex-grow'>{views[activeTab].view}</div>
          <FormProvider {...methods}>
            <div className='flex flex-col gap-2'>
              <Typography type='H3' className='mb-2'>
                {diagnosticsTranslations('note.label')}
              </Typography>
              <CommentItem fieldName={'note'} />
            </div>
          </FormProvider>
        </div>
      </FunctionContent>
    </FunctionWrapper>
  );
};
