import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput } from 'fets';
import { call, debounce, put } from 'redux-saga/effects';

import { restCall } from '@/core/clients/rest';
import { VocationalTrainingAreaType } from '@/core/enums/functions/vocationalTrainingArea/vocationalTrainingAreaTypesEnum';
import {
  IVocationalTrainingAreaFetchPayload,
  IVocationalTrainingAreaGroup,
  IVocationalTrainingAreaItem,
  vocationalTrainingAreaActions,
} from '@/core/redux/slices/functions/vocationalTrainingArea/slice';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';
import { isEnumValue } from '@/core/utils/enumUtils';
import oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

type VocationalTrainingAreaListResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/vocational_training_area/measure',
  'get',
  '200'
>;

interface IVocationalTrainingAreaItemResponse {
  id: number;
  name: string;
  nearest_activity_timestamp?: string | null;
  last_activity_timestamp?: string | null;
  type?: string | null;
  has_documents: boolean;
}

interface IVocationalTrainingAreaGroupResponse {
  id: number;
  name: string;
  child_groups?: IVocationalTrainingAreaGroupResponse[] | null;
  items?: IVocationalTrainingAreaItemResponse[] | null;
}

interface IVocationalTrainingAreaResponse {
  measure_groups: IVocationalTrainingAreaGroupResponse[];
}

function mapVocationalTrainingAreaResponseToGroup(
  response: IVocationalTrainingAreaResponse
): IVocationalTrainingAreaGroup[] | null {
  if (!response || !response.measure_groups) {
    return null;
  }

  return response.measure_groups.map((groupResponse) => {
    return {
      id: groupResponse.id,
      name: groupResponse.name,
      childGroup: groupResponse.child_groups
        ? mapVocationalTrainingAreaResponseToGroup({ measure_groups: groupResponse.child_groups })
        : null,
      items: groupResponse.items
        ? groupResponse.items.map((item) => {
            return {
              id: item.id,
              name: item.name,
              nearestActivity: toClientDateInput(item.nearest_activity_timestamp),
              lastActivity: toClientDateInput(item.last_activity_timestamp),
              type: isEnumValue(VocationalTrainingAreaType, item.type)
                ? item.type
                : VocationalTrainingAreaType.Default,
              hasDocuments: item.has_documents,
            };
          })
        : null,
    };
  });
}

function* fetchVocationalTrainingAreaGroups(
  action: PayloadAction<IVocationalTrainingAreaFetchPayload>
): Generator<any, void, IVocationalTrainingAreaResponse> {
  const { personID, searchValue, selectedType, isFinished } = action.payload;

  yield put(
    vocationalTrainingAreaActions.setVocationalTrainingAreaGroupsLock(LoadingStatus.LOADING)
  );

  try {
    const response = yield call(restCall, '/vocational_training_area/measure/groups', 'get', {
      query: {
        person_id: personID,
        ...(searchValue && { search: searchValue }),
        ...(selectedType &&
          selectedType !== VocationalTrainingAreaType.Default && {
            measure_type: selectedType,
          }),
        is_finished: isFinished,
      },
    });

    const vocationalTrainingAreaGroups = mapVocationalTrainingAreaResponseToGroup(response);

    yield put(
      vocationalTrainingAreaActions.setVocationalTrainingAreaGroups(vocationalTrainingAreaGroups)
    );

    yield put(
      vocationalTrainingAreaActions.setVocationalTrainingAreaGroupsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    yield put(
      vocationalTrainingAreaActions.setVocationalTrainingAreaGroupsLock(LoadingStatus.ERROR)
    );
    console.log('Error on fetching vocational training area groups', error);
  }
}

function* fetchVocationalTrainingAreaList(
  action: PayloadAction<IVocationalTrainingAreaFetchPayload>
): Generator<any, void, VocationalTrainingAreaListResponse> {
  const { personID, searchValue, selectedType, isFinished } = action.payload;

  yield put(
    vocationalTrainingAreaActions.setVocationalTrainingAreaGroupsLock(LoadingStatus.LOADING)
  );

  try {
    const response = yield call(restCall, '/vocational_training_area/measure', 'get', {
      query: {
        person_id: personID,
        ...(searchValue && { search: searchValue }),
        ...(selectedType &&
          selectedType !== VocationalTrainingAreaType.Default && {
            measure_type: selectedType,
          }),
        is_finished: isFinished,
      },
    });

    const vocationalTrainingAreaList = response.measures.map<IVocationalTrainingAreaItem>(
      (measure) => ({
        id: measure.id,
        name: measure.name,
        type: isEnumValue(VocationalTrainingAreaType, measure.type) ? measure.type : null,
        lastActivity: measure.last_activity_timestamp,
        nearestActivity: measure.nearest_activity_timestamp,
        hasDocuments: measure.has_documents,
      })
    );

    yield put(
      vocationalTrainingAreaActions.setVocationalTrainingAreaList(vocationalTrainingAreaList)
    );

    yield put(
      vocationalTrainingAreaActions.setVocationalTrainingAreaGroupsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    yield put(
      vocationalTrainingAreaActions.setVocationalTrainingAreaGroupsLock(LoadingStatus.ERROR)
    );
    console.log('Error on fetching vocational training area list', error);
  }
}

export const vocationalTrainingAreaSagas = [
  debounce(
    500,
    vocationalTrainingAreaActions.fetchVocationalTrainingAreaGroups,
    fetchVocationalTrainingAreaGroups
  ),
  debounce(
    500,
    vocationalTrainingAreaActions.fetchVocationalTrainingAreaList,
    fetchVocationalTrainingAreaList
  ),
];
