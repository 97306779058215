import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { DatePicker } from '@/components/Datepicker/DatePicker';
import { IMobilityForm } from '@/components/functions/Mobility/hooks/useMobilityForm';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { useAppSelector } from '@/core/redux/hooks';
import { MobilityFormsContext } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { mobilitySelectors } from '@/core/redux/slices/functions/workingTimeMobility/mobility/selectors';
import { colorTypes } from '@/styles/types';

interface ICarServiceForm {
  context: MobilityFormsContext;
}

export const CarServiceForm: React.FC<ICarServiceForm> = ({ context }) => {
  const [selectID, setSelectID] = useState<string | undefined>(undefined);

  const { t: mobilityCarServiceTranslations } = useTranslation('mobility', {
    keyPrefix: 'forms.carService',
  });

  const formContext = useFormContext<IMobilityForm>();

  const mobility = useAppSelector(mobilitySelectors.mobility);
  const availableTransportTypes = mobility?.availableTransportServices ?? [];

  const formPath = context === 'arrival' ? 'arrivalForm.carService' : 'departureForm.carService';

  const formTransportServiceID = formContext.getValues(`${formPath}.type.id`);

  const selectOptions: ISelectItem[] = availableTransportTypes.map<ISelectItem>(
    (transportType) => ({
      id: transportType.type?.id?.toString() || '1',
      component: transportType.type?.name,
    })
  );

  const handleSelectChange = (id: string) => {
    formContext.clearErrors(`${formPath}.type.id`);

    if (!availableTransportTypes) {
      return;
    }

    const foundElement = availableTransportTypes.find(
      (transportType) => transportType.type?.id === Number(id)
    );

    if (!foundElement) {
      return;
    }

    formContext.setValue(`${formPath}`, foundElement, { shouldDirty: true });
  };

  useEffect(() => {
    if (!formTransportServiceID) {
      return;
    }

    setSelectID(formTransportServiceID.toString());
  }, [formTransportServiceID]);

  const isValidateError = useMemo(
    () => formContext.getFieldState(`${formPath}.type.id`).error,
    [context, formContext, formContext.formState.errors]
  );

  return (
    <>
      <div className={'flex flex-col gap-6 w-full'}>
        <div className={'flex flex-col gap-3'}>
          <div className={'flex flex-row gap-3'}>
            <Typography>{mobilityCarServiceTranslations('carServiceName.label')}</Typography>
          </div>
          <Select
            values={selectOptions}
            onChange={handleSelectChange}
            selectedExternal={selectID}
            setSelectedExternal={setSelectID}
            isFlexible={true}
            selectedContainerClassName={twMerge(
              isValidateError && `border-${colorTypes.Error} focus:border-${colorTypes.Error}`
            )}
          />
          {isValidateError && (
            <Typography type={'14-R'} color={'Error'} className={'mt-1'}>
              {mobilityCarServiceTranslations('carServiceName.error.label')}
            </Typography>
          )}
        </div>
        <div className={'flex flex-col gap-3'}>
          <Typography>{mobilityCarServiceTranslations('contacts.label')}</Typography>
          <TextArea
            readOnly={true}
            control={formContext.register(
              context === 'arrival'
                ? 'arrivalForm.carService.contacts'
                : 'departureForm.carService.contacts'
            )}
          />
        </div>
        <div className={'flex flex-col gap-3'}>
          <Typography>{mobilityCarServiceTranslations('appliesUntil.label')}</Typography>
          <DatePicker
            control={formContext.register(
              context === 'arrival'
                ? 'arrivalForm.carService.date'
                : 'departureForm.carService.date'
            )}
          />
        </div>
      </div>
    </>
  );
};
