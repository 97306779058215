import { TFunction } from 'i18next';
import fp from 'lodash/fp';
import * as yup from 'yup';

import { WorkingTimeType } from '@/core/enums/functions/workingTimeMobility/employmentTypesEnum';
import { WorkingTimePartTimeReason } from '@/core/enums/functions/workingTimeMobility/partTimeReasonsEnum';
import { IWorkingTime } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';

export const workingTimeFormValidatorBuilder = (
  translations: TFunction<'workingTime', undefined>
): yup.ObjectSchema<IWorkingTime> => {
  const timeValidationSchema = yup.object().shape({
    fromDateStamp: yup
      .string()
      .nullable()
      .test(
        'is-earlier-than-toDateStamp',
        translations('workingTimePlanner.errors.fromTimeAfterToTime.label'),
        function (value) {
          const { toDateStamp } = this.parent;
          return value && toDateStamp
            ? new Date(`1970-01-01T${value}:00Z`) <= new Date(`1970-01-01T${toDateStamp}:00Z`)
            : true;
        }
      )
      .test(
        'is-required-when-toDateStamp',
        translations('workingTimePlanner.errors.requiredField.label'),
        function (value) {
          const { toDateStamp } = this.parent;
          return toDateStamp ? !fp.isEmpty(value) : true;
        }
      ),
    toDateStamp: yup
      .string()
      .nullable()
      .test(
        'is-later-than-fromDateStamp',
        translations('workingTimePlanner.errors.toTimeBeforeFromTime.label'),
        function (value) {
          const { fromDateStamp } = this.parent;
          return fromDateStamp && value
            ? new Date(`1970-01-01T${fromDateStamp}:00Z`) <= new Date(`1970-01-01T${value}:00Z`)
            : true;
        }
      )
      .test(
        'is-required-when-fromDateStamp',
        translations('workingTimePlanner.errors.requiredField.label'),
        function (value) {
          const { fromDateStamp } = this.parent;
          return fromDateStamp ? !fp.isEmpty(value) : true;
        }
      ),
    pauseDuration: yup
      .number()
      .typeError('Must be a number')
      .test(
        'is-within-interval',
        translations('workingTimePlanner.errors.pauseDurationBiggerThenInterval.label'),
        function (value) {
          if (value === undefined || value === 0 || Number.isNaN(value)) {
            return true;
          }
          const { fromDateStamp, toDateStamp } = this.parent;
          if (fromDateStamp && toDateStamp) {
            const from = new Date(`1970-01-01T${fromDateStamp}:00Z`).getTime();
            const to = new Date(`1970-01-01T${toDateStamp}:00Z`).getTime();
            const interval = (to - from) / 1000 / 60;
            debugger;
            return value < interval;
          }
          return true;
        }
      ),
  });

  return yup.object().shape({
    appliesFromTimeStamp: yup.string().required(),
    workingTimeType: yup
      .mixed<WorkingTimeType>()
      .oneOf([WorkingTimeType.FullTime, WorkingTimeType.PartTime])
      .required(),
    partTimeReasonType: yup
      .mixed<WorkingTimePartTimeReason>()
      .nullable()
      .when('workingTimeType', {
        is: WorkingTimeType.PartTime,
        then: () =>
          yup
            .mixed<WorkingTimePartTimeReason>()
            .required(translations('fields.partTime.errors.required.label')),
        otherwise: () => yup.mixed<WorkingTimePartTimeReason>().nullable(),
      }),
    otherPartTimeReasonText: yup.string().nullable(),
    workingWeek: yup.object().shape({
      sumGross: yup.number().nullable().min(0),
      sumNet: yup.number().nullable().min(0),
      sumPauseDuration: yup.number().nullable().min(0),
      workingDaysCount: yup.number().nullable().min(0),
      workingDays: yup.object().shape({
        1: timeValidationSchema,
        2: timeValidationSchema,
        3: timeValidationSchema,
        4: timeValidationSchema,
        5: timeValidationSchema,
        6: timeValidationSchema,
        7: timeValidationSchema,
      }),
    }),
  });
};
