import { boolean, mixed, ObjectSchema, string } from 'yup';

import { IStatusReport } from '@/core/redux/slices/functions/integrationPlan/statusReport/statusReportSlice';
import { yup } from '@/core/utils/commonUtils';

const requiredByCheck = (checkFieldName: string) =>
  string()
    .optional()
    .when(checkFieldName, {
      is: true,
      then: () => string().required('must be filled'),
      otherwise: () => string().optional(),
    });

export const statusReportResolver: ObjectSchema<IStatusReport> = yup.object().shape({
  documentNumber: string().optional(),
  actionCourseTimestamp: string().optional().nullable(),
  isFirstYearVocationalTraining: boolean().required(),
  isSecondYearVocationalTraining: boolean().required(),
  isFurtherIndividualAppointment: boolean().required(),
  furtherIndividualAppointmentReason: requiredByCheck('isFurtherIndividualAppointment'),
  reportingPeriodFromTimestamp: string().optional().nullable(),
  reportingPeriodToTimestamp: string().optional().nullable(),
  isCurrentlyNoQuestions: boolean().required(),
  isQuestionsNeedClarification: boolean().required(),
  questionsText: requiredByCheck('isQuestionsNeedClarification'),
  isCompetenceAnalysisUnchanged: boolean().required(),
  isNewClassification: boolean().required(),
  isActivityOriented: boolean().required(),
  isOccupationalFieldOriented: boolean().required(),
  isWorkplaceOriented: boolean().required(),
  isJobProfileOriented: boolean().required(),
  isParticipantExpectationChanged: string().required(),
  participantExpectationChangeText: string().optional(),
  participantExpectationChangeMethod: string().optional(),
  isAdditionalRequirements: boolean().required(),
  isContinued: boolean().required(),
  isVocationalTrainingPlaceRealistic: boolean().required(),
  isTransitionToGeneralLaborMarketRealistic: boolean().required(),
  isDiscussionRequired: boolean().required(),
  qualificationObjectivesDocumentations: mixed().required(),
});
