import { useEffect } from 'react';

import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  efficacyAssessmentActions,
  IAppointment,
} from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { efficacyAssessmentSelectors } from '@/core/redux/slices/efficacyAssessment/selectors';
import { vocationalTrainingAreaModalSelectors } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/selectors';
import { vocationalTrainingAreaModalActions } from '@/core/redux/slices/modalsSlice/functions/vocationalTrainingArea/slice';

interface IUseFecthedDocuments {
  measureID: number | undefined;
}

export function useFecthedDocuments({ measureID }: IUseFecthedDocuments) {
  const dispatch = useAppDispatch();
  const documents = useAppSelector(
    vocationalTrainingAreaModalSelectors.vocationalTrainingAreaDocumentsModal
  );

  useEffect(() => {
    if (!measureID) {
      return;
    }

    dispatch(
      vocationalTrainingAreaModalActions.fetchVocationalTrainingAreaDocuments({
        measureID,
      })
    );
  }, [dispatch, measureID]);

  return { documents };
}
