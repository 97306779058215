import { PayloadAction } from '@reduxjs/toolkit';
import { delay, put, takeEvery } from 'redux-saga/effects';
import { v4 } from 'uuid';

import { DEFAULT_NOTIFICATION_TIMEOUT } from '@/core/constants/settings';

import { IShowNotificationPayload, notificationsActions } from './notificationsSlice';

function* addNotification(action: PayloadAction<IShowNotificationPayload>) {
  const { payload } = action;

  const id = v4();

  yield put(
    notificationsActions.addNotification({
      id,
      notification: action.payload.notification,
    })
  );

  if (!payload.notification?.permanent) {
    yield delay(payload.notification?.delay || DEFAULT_NOTIFICATION_TIMEOUT);
    yield put(notificationsActions.hideNotification(id));
  }
}

export const notificationsSagas = [
  takeEvery(notificationsActions.showNotification, addNotification),
];
