import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IOrganizationalUnit {
  id: number;
  name?: string | null;
  comment?: string | null;
  fromTimestamp?: string | null;
  toTimestamp?: string | null;
  type: OrganizationalUnitsTypes;
}

export interface IOrganizationalUnitsFetchPayload {
  personID: number;
}

export interface IOrganizationalUnitsDeletePayload extends IOrganizationalUnitsFetchPayload {
  organizationalUnitID: number;
}

export interface IAdditionalPersonRequirementAssistance {
  id: number;
  name?: string | null;
}

export interface IAdditionalPersonRequirementResponsible {
  id: number;
  value?: string | null;
}

export interface IPersonRequirement {
  id: number;
  assistance: IAdditionalPersonRequirementAssistance;
  responsible?: IAdditionalPersonRequirementResponsible | null;
  appliedTimestamp?: string | null;
  approvedFromTimestamp?: string | null;
  approvedToTimestamp?: string | null;
  minutesPerDay?: number | null;
  comment?: string | null;
}

export interface IAdditionalPersonRequirement {
  personRequirements: IPersonRequirement[];
  isNewRequirementAvailable?: boolean;
  isEditable: boolean;
  responsible?: IAdditionalPersonRequirementResponsible | null;
}

export interface IAdditionalPersonRequirementPayload {
  personID: number;
}

export interface IAdditionalPersonRequirementDeletePayload {
  additionalPersonRequirementID: number;
  personID: number;
}

export interface IAdditionalPersonRequirementAssistance {
  id: number;
  name?: string | null;
}

export interface IAdditionalPersonRequirementResponsible {
  id: number;
  value?: string | null;
}

export interface IPersonRequirement {
  id: number;
  assistance: IAdditionalPersonRequirementAssistance;
  responsible?: IAdditionalPersonRequirementResponsible | null;
  appliedTimestamp?: string | null;
  approvedFromTimestamp?: string | null;
  approvedToTimestamp?: string | null;
  minutesPerDay?: number | null;
  comment?: string | null;
}

export interface IAdditionalPersonRequirement {
  personRequirements: IPersonRequirement[];
  isNewRequirementAvailable?: boolean;
  isEditable: boolean;
  responsible?: IAdditionalPersonRequirementResponsible | null;
}

export interface IAdditionalPersonRequirementPayload {
  personID: number;
}

export interface IAdditionalPersonRequirementDeletePayload {
  additionalPersonRequirementID: number;
  personID: number;
}

export interface IOrganizationalUnitsState {
  organizationalUnitsList: IOrganizationalUnit[];
  organizationalUnitsListLock: LoadingStatus;
  additionalPersonRequirement: IAdditionalPersonRequirement | null;
  additionalPersonRequirementLock: LoadingStatus;
}

const initialState: IOrganizationalUnitsState = {
  organizationalUnitsList: [],
  organizationalUnitsListLock: LoadingStatus.NEVER,
  additionalPersonRequirement: null,
  additionalPersonRequirementLock: LoadingStatus.NEVER,
};

export const organizationalUnitsSlice = createSlice({
  name: 'organizationalUnits',
  initialState,
  reducers: {
    //organizational units
    fetchOrganizationalUnits: (state, action: PayloadAction<IOrganizationalUnitsFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setOrganizationalUnits: (state, action: PayloadAction<IOrganizationalUnit[]>) => ({
      ...state,
      organizationalUnitsList: action.payload,
    }),
    setOrganizationalUnitsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      organizationalUnitsListLock: action.payload,
    }),
    deleteOrganizationalUnit: (
      state,
      action: PayloadAction<IOrganizationalUnitsDeletePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),

    //additional person requirement
    fetchAdditionalPersonRequirementUnits: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAdditionalPersonRequirementUnits: (
      state,
      action: PayloadAction<IAdditionalPersonRequirement | null>
    ) => ({
      ...state,
      additionalPersonRequirement: action.payload,
    }),
    setAdditionalPersonRequirementUnitsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      additionalPersonRequirementLock: action.payload,
    }),
    deleteAdditionalPersonRequirement: (
      state,
      action: PayloadAction<IAdditionalPersonRequirementDeletePayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
  },
});

export const organizationalUnitsReducer = organizationalUnitsSlice.reducer;
export const organizationalUnitsActions = organizationalUnitsSlice.actions;
