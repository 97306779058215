import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MainDashWrapper } from '@/components/wrapper/MainDashWrapper/MainDashWrapper';
import DashboardRootRoute from '@/pages/dashboard/DashboardRootRoute';
import MedicationRootRoute from '@/pages/medication/MedicationRootRoute';
import PersonRootRoute from '@/pages/persons/PersonRootRoute';
import ReportsRootRoute from '@/pages/reports/ReportsRootRoute';

const PrivateRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<MainDashWrapper />}>
        <Route path={'/dashboard/*'} element={<DashboardRootRoute />} />
        <Route path={'/persons/*'} element={<PersonRootRoute />} />
        <Route path={'/medication/*'} element={<MedicationRootRoute />} />
        <Route path={'/reports/*'} element={<ReportsRootRoute />} />
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
