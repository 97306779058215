import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IMobilityForm } from '@/components/functions/Mobility/hooks/useMobilityForm';
import { Input } from '@/components/Input/Input';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { MobilityRefundVariant } from '@/core/enums/functions/workingTimeMobility/mobilityRefundVariantsEnum';
import { MobilityFormsContext } from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';

interface ISelfDriverForm {
  context: MobilityFormsContext;
}

export const SelfDriverForm: React.FC<ISelfDriverForm> = ({ context }) => {
  const { t: mobilitySelfDriverFormTranslations } = useTranslation('mobility', {
    keyPrefix: 'forms.selfDriver',
  });

  const formPath = context === 'arrival' ? 'arrivalForm.selfDriver' : 'departureForm.selfDriver';

  const formContext = useFormContext<IMobilityForm>();

  const options: IRadioButtonGroupItem[] = [
    {
      value: MobilityRefundVariant.NonRefundable,
      element: <Typography>{mobilitySelfDriverFormTranslations('noRefund.label')}</Typography>,
    },
    {
      value: MobilityRefundVariant.TicketPrice,
      element: (
        <Typography>{mobilitySelfDriverFormTranslations('refundTicketPrice.label')}</Typography>
      ),
    },
    {
      value: MobilityRefundVariant.ByMileage,
      element: (
        <Typography>{mobilitySelfDriverFormTranslations('refundTicketPrice.label')}</Typography>
      ),
    },
  ];

  const isValidateError = useMemo(
    () => formContext.getFieldState(`${formPath}.refundVariant`).error,
    [context, formContext, formContext.formState.errors]
  );

  return (
    <>
      <RadioButtonGroup
        options={options}
        name={
          context === 'arrival'
            ? 'arrivalForm.selfDriver.refundVariant'
            : 'departureForm.selfDriver.refundVariant'
        }
        itemClassName={'flex flex-row gap-3 items-start'}
      />
      {isValidateError && (
        <Typography type={'14-R'} color={'Error'} className={'mt-1'}>
          {mobilitySelfDriverFormTranslations('refundVariant.error.label')}
        </Typography>
      )}
      <div className={'flex flex-col items-start gap-3 mt-3'}>
        <Typography>{'kilometer'}</Typography>
        <Input
          control={formContext.register(
            context === 'departure'
              ? 'departureForm.selfDriver.kilometer'
              : 'arrivalForm.selfDriver.kilometer'
          )}
          className={'max-w-[200px]'}
          type={'number'}
        />
      </div>
    </>
  );
};
