import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Accordion } from '@/components/Accordion/Accordion';
import {
  IQuestionFormValues,
  IQuestionnaireForm,
} from '@/components/functions/PerformanceProfile/Questionnaire/hooks/useQuestionnaireQuestionsListForm';
import { useQuestionnaireStoreSelector } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireContext/QuestionnaireContext';
import { QuestionnaireQuestionItem } from '@/components/functions/PerformanceProfile/Questionnaire/QuestionnaireQuestionItem/QuestionnaireQuestionItem';
import { CircleFilledIcon } from '@/components/Icons/CircleFilledIcon';
import { CircleOutlineIcon } from '@/components/Icons/CircleOutlineIcon';
import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireQuestionsGroupStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireQustionsGroupStatusEnum';
import { IQuestionnaireQuestion } from '@/core/redux/slices/functions/performanceProfile/slice';
import { colorTypes } from '@/styles/types';

export const statusIcons: Record<QuestionnaireQuestionsGroupStatus, React.ReactNode> = {
  [QuestionnaireQuestionsGroupStatus.Completed]: (
    <CircleFilledIcon className={`text-${colorTypes.Tiffany}`} />
  ),
  [QuestionnaireQuestionsGroupStatus.Intermediate]: (
    <CircleOutlineIcon className={`text-${colorTypes.Tiffany}`} />
  ),
  [QuestionnaireQuestionsGroupStatus.Uncompleted]: (
    <CircleOutlineIcon className={`text-${colorTypes.Orange}`} />
  ),
};

interface IQuestionnaireQuestionsGroup {
  id: number;
  name: string;
  index: number;
  questionsList: IQuestionnaireQuestion[];
}

export const QuestionnaireQuestionsGroup: React.FC<IQuestionnaireQuestionsGroup> = ({
  name,
  id,
  index,
  questionsList,
}) => {
  const [status, setStatus] = useState<QuestionnaireQuestionsGroupStatus>(
    QuestionnaireQuestionsGroupStatus.Uncompleted
  );
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const [collapseTrigger] = useQuestionnaireStoreSelector((store) => store.collapseTrigger);

  const formContext = useFormContext<IQuestionnaireForm>();
  const currentValues = formContext.watch();

  useEffect(() => {
    if (currentValues[id]) {
      const questionsReviews = Object.values<IQuestionFormValues>(currentValues[id].questions);

      if (questionsReviews.every((value) => value.review === null)) {
        setStatus(QuestionnaireQuestionsGroupStatus.Uncompleted);
        return;
      }

      if (questionsReviews.every((value) => value.review !== null)) {
        setStatus(QuestionnaireQuestionsGroupStatus.Completed);
        return;
      }

      setStatus(QuestionnaireQuestionsGroupStatus.Intermediate);
    }
  }, [currentValues]);

  const questionsGroupTitle = (
    <div className={`flex flex-row gap-3 items-center`}>
      {statusIcons[status]}
      <Typography type={'16-Sb'}>{`${index}. ${name}`}</Typography>
    </div>
  );

  useEffect(() => {
    setIsExpanded(false);
  }, [collapseTrigger]);

  return (
    <Accordion
      title={questionsGroupTitle}
      className={'w-full'}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      headerClassName={`bg-${colorTypes.Fields} px-[30px] py-3`}
    >
      {questionsList.map((question, childIndex) => (
        <QuestionnaireQuestionItem
          id={question.id}
          parentID={id}
          parentIndex={index}
          index={childIndex + 1}
          name={question.name}
          reviewsList={question.reviews}
          reviewsHistory={question.reviewsHistory}
          review={question.review}
          description={question.description}
          key={question.id}
        />
      ))}
    </Accordion>
  );
};
