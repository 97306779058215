import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Button } from '@/components/Button/Button';
import {
  IOrganizationalUnitBBBForm,
  OrganizationalUnitBBB,
} from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/BBB/OrganizationalUnitBBB';
import { OrganizationUnitDefault } from '@/components/Modals/Modals/functions/organizationalUnitsModals/OrganizationalUnitForms/Default/OrganizationUnitDefault';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { OrganizationalUnitsTypes } from '@/core/enums/functions/organizationalUnits/organizationalUnitsTypesEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { IOrganizationalUnit } from '@/core/redux/slices/functions/organizationalUnits/slice';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface ICreateOrganizationalUnitModalContent {
  personID: number;
  handleExit: () => void;
}

interface ICreateOrganizationalUnitForm {
  bbb?: IOrganizationalUnitBBBForm;
}

export const CreateOrganizationalUnitModalContent: React.FC<ICreateOrganizationalUnitModalContent> =
  ({ personID, handleExit }) => {
    const { t: createOrganizationalUnitModalTranslations } = useTranslation(
      'createOrganizationalUnitModal'
    );

    const [selectFormType, setSelectFormType] = useState<IOrganizationalUnit | null>(null);

    const { availableOrganizationUnits, availableOrganizationUnitsLock } = useAppSelector(
      organizationalUnitsModalsSelectors.createOrganizationalUnitModal
    );

    const dispatch = useAppDispatch();

    const form = useForm<ICreateOrganizationalUnitForm>();

    useEffect(() => {
      dispatch(
        organizationalUnitsModalsActions.fetchAvailableOrganizationalUnit({
          personID: personID,
        })
      );
    }, [personID]);

    useEffect(() => {
      if (!availableOrganizationUnits) {
        return;
      }

      setSelectFormType(availableOrganizationUnits[0]);
    }, [availableOrganizationUnits]);

    const selectValues = availableOrganizationUnits?.map<ISelectItem>((organizationUnit) => ({
      id: organizationUnit.id.toString(),
      component: organizationUnit.name,
    }));

    const organizationalUnitForms: Record<OrganizationalUnitsTypes, React.ReactNode> = {
      [OrganizationalUnitsTypes.BBB]: <OrganizationalUnitBBB />,
      [OrganizationalUnitsTypes.Default]: <OrganizationUnitDefault />,
    };

    const handleSelect = (id: string) => {
      const foundElement = availableOrganizationUnits?.find(
        (organizationalUnit) => organizationalUnit.id === Number(id)
      );

      if (!foundElement) {
        return;
      }

      setSelectFormType(foundElement);
    };

    const handleCreate = () => {
      switch (selectFormType?.type) {
        case OrganizationalUnitsTypes.BBB: {
          const bbbFormValues = fp.cloneDeep(form.getValues('bbb'));

          if (!bbbFormValues) {
            return;
          }

          dispatch(
            organizationalUnitsModalsActions.setOrganizationalUnitBBBFormValues(bbbFormValues)
          );

          dispatch(
            organizationalUnitsModalsActions.createOrganizationalUnit({
              personID: personID,
              type: selectFormType.type,
              fromTimestamp: bbbFormValues.startDate,
            })
          );

          break;
        }
        case OrganizationalUnitsTypes.Default: {
          break;
        }
      }
    };

    const footer = (
      <div className={'flex flex-row gap-3'}>
        <Button onClick={handleCreate}>
          {createOrganizationalUnitModalTranslations('buttons.save.label')}
        </Button>
        <Button buttonVariant={'Secondary'} onClick={handleExit}>
          {createOrganizationalUnitModalTranslations('buttons.cancel.label')}
        </Button>
      </div>
    );

    return (
      <Sidebar position={'right'} onExit={handleExit} className={'min-w-[40vw]'} footer={footer}>
        <div className={'p-4'}>
          <Typography type={'H3'}>
            {createOrganizationalUnitModalTranslations('title.label')}
          </Typography>
          <FormProvider {...form}>
            <div className={'flex flex-col gap-3 mt-3'}>
              <Typography>
                {createOrganizationalUnitModalTranslations('fields.organizationalUnit.label')}
              </Typography>
              <Select
                values={selectValues}
                isFlexible={true}
                onChange={handleSelect}
                initialValueID={availableOrganizationUnits?.[0].id.toString()}
              />
              <Skeleton trigger={availableOrganizationUnitsLock === LoadingStatus.LOADING}>
                {selectFormType && organizationalUnitForms[selectFormType.type]}
              </Skeleton>
            </div>
          </FormProvider>
        </div>
      </Sidebar>
    );
  };
