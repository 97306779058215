import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireAnswerOptions } from '@/core/enums/functions/selfAssessment/questionnaireAnswersEnum';
import {
  ISelfAssessmentAnswer,
  ISelfAssessmentQuestion,
} from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';

interface IDefaultQuestionnaireItem {
  question: ISelfAssessmentQuestion;
  answer: ISelfAssessmentAnswer | null;
  bgColor?: string;
  handleAnswer: (
    question: ISelfAssessmentQuestion,
    answer: ISelfAssessmentAnswer | null,
    selectedOption: QuestionnaireAnswerOptions
  ) => void;
}

export const DefaultQuestionnaireItem: React.FC<IDefaultQuestionnaireItem> = ({
  question,
  answer,
  bgColor,
  handleAnswer,
}) => {
  const { t: selfAssesmentTranslations } = useTranslation('selfAssesment');

  const { watch, setValue } = useFormContext();

  const selectedAnswer = watch(`question.${question.sefID}.selectedAnswer`);

  useEffect(() => {
    if (!answer) {
      return;
    }
    setValue(`question.${question.sefID}.selectedAnswer`, `${answer.answer}`);
  }, [question, answer]);

  useEffect(() => {
    if (!selectedAnswer) {
      return;
    }

    handleAnswer(question, answer, Number(selectedAnswer));
  }, [selectedAnswer]);

  const options: IRadioButtonGroupItem[] = [
    {
      value: `${QuestionnaireAnswerOptions.YES}`,
      element: <Typography>{selfAssesmentTranslations('fields.yes.label')}</Typography>,
    },
    {
      value: `${QuestionnaireAnswerOptions.NO}`,
      element: <Typography>{selfAssesmentTranslations('fields.no.label')}</Typography>,
    },
    {
      value: `${QuestionnaireAnswerOptions.DUNNO}`,
      element: <Typography>{selfAssesmentTranslations('fields.dunno.label')}</Typography>,
    },
  ];

  if (question) {
    return (
      <tr key={question?.sefID} style={{ backgroundColor: bgColor }}>
        <td className={'p-4'}>
          <Typography className='font-normal'>{question.name}</Typography>
        </td>
        <td className={'p-4'}>
          <RadioButtonGroup
            options={options}
            name={`question.${question.sefID}.selectedAnswer`}
            className={'flex flex-wrap float-right'}
            itemClassName={''}
            layout={'horizontal'}
          />
        </td>
      </tr>
    );
  }

  return null;
};
