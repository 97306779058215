import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Typography } from '@/components/Typography/Typography';
import { DepartmentList } from '@/components/views/person/PersonPage/DepartmentView/DepartmentList/DepartmentList';
import { useDepartmentListForm } from '@/components/views/person/PersonPage/DepartmentView/DepartmentList/hooks/useDepartmentListForm';
import { DepartmentSearch } from '@/components/views/person/PersonPage/DepartmentView/DepartmentSearch/DepartmentSearch';
import { DepartmentSelectAll } from '@/components/views/person/PersonPage/DepartmentView/DepartmentSelectAll/DepartmentSelectAll';
import { DepartmentSorting } from '@/components/views/person/PersonPage/DepartmentView/DepartmentSorting/DepartmentSorting';
import { useAppSelector } from '@/core/redux/hooks';
import { userSettingsSelectors } from '@/core/redux/slices/userSettings/selectors';

import { usePersonPageStoreSelector } from '../Context/PersonPageStoreContext';

import { DepartmentListExpand } from './DepartmentListExpand/DepartmentListExpand';

import styles from './styles.module.scss';

export const DepartmentView: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.title' });
  const form = useDepartmentListForm();

  const userSelectedDepartments = useAppSelector(userSettingsSelectors.userSelectedDepartments);

  const defaultSelectedDepartments = useMemo(
    () => (userSelectedDepartments ? JSON.parse(userSelectedDepartments) : {}),
    [userSelectedDepartments]
  );

  const [locationsCheckState, setLocationCheckState] = usePersonPageStoreSelector(
    (store) => store.locationsChecksState
  );

  useEffect(() => {
    if (!locationsCheckState) {
      setLocationCheckState(defaultSelectedDepartments);
      form.reset(defaultSelectedDepartments);
    }
  }, [locationsCheckState, defaultSelectedDepartments]);

  return (
    <div className={clsx(styles.departments, 'flex flex-col items-start justify-start')}>
      <Typography type='H2' color='Primary' className={styles.title}>
        {t('departments')}
      </Typography>

      <DepartmentSearch />
      <div className={clsx(styles.departmentContainer, 'w-full flex-grow')}>
        <FormProvider {...form}>
          <div
            className={clsx(
              styles.filterContainer,
              'flex flex-row items-center justify-between w-full'
            )}
          >
            <DepartmentSelectAll />
            <div className='flex gap-4'>
              <DepartmentListExpand />
              <DepartmentSorting />
            </div>
          </div>
          <DepartmentList />
        </FormProvider>
      </div>
    </div>
  );
};
