import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { parse } from 'date-fns';
import { ObjectSchema } from 'yup';

import { Button } from '@/components/Button/Button';
import { RecordingEffictivenessForm } from '@/components/forms/EfficacyAssessment/RecordingEffictiveness';
import { Sidebar } from '@/components/Overlays/Sidebar/Sidebar';
import { Typography } from '@/components/Typography/Typography';
import { FRONTEND_DATE_FORMAT } from '@/core/constants/dateFormat';
import { useSelectedPersonLocation } from '@/core/hooks/useSelectedPersonLocation';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { efficacyAssessmentActions } from '@/core/redux/slices/efficacyAssessment/efficacyAssessmentSlice';
import { efficacyAssessmentSelectors } from '@/core/redux/slices/efficacyAssessment/selectors';
import {
  efficacyAssessmentModalActions,
  IRecordingEffectivenessModalPayload,
} from '@/core/redux/slices/modalsSlice/efficacyAssessment/efficacyAssessmentModalSlice';
import { efficacyAssessmentModalSelectors } from '@/core/redux/slices/modalsSlice/efficacyAssessment/selectors';
import { yup } from '@/core/utils/commonUtils';
import { toClientDateInput } from '@/core/utils/dateTimeUtil';

interface IModalContent {
  payload: IRecordingEffectivenessModalPayload;
}

export interface IRecordEffectivenessFormValues {
  types: {
    isParticipated: boolean;
    isUnderDirection: boolean;
    isAlmostIndependent: boolean;
    isIndependent: boolean;
  };
  fromTimestamp: string;
  toTimestamp: string;
  hours: number;
  location: string;
  appointmentID?: number;
  performerID?: number;
  note?: string;
}

const schema: ObjectSchema<IRecordEffectivenessFormValues> = yup.object().shape({
  types: yup.object().shape({
    isParticipated: yup.boolean().required(),
    isUnderDirection: yup.boolean().required(),
    isAlmostIndependent: yup.boolean().required(),
    isIndependent: yup.boolean().required(),
  }),
  fromTimestamp: yup.string().required(),
  salutationId: yup.number(),
  toTimestamp: yup.string().required(),
  hours: yup.number().required(),
  location: yup.string().required(),
  appointmentID: yup.number(),
  performerID: yup.number(),
  note: yup.string(),
});

const ModalContent: React.FC<IModalContent> = ({ payload }) => {
  const { t: recordingEffectivenessModalTranslations } = useTranslation(
    'recordingEffectivenessModal'
  );

  const { selectedPersonLocation } = useSelectedPersonLocation();

  const dispatch = useAppDispatch();

  const form = useForm<IRecordEffectivenessFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      fromTimestamp: toClientDateInput(new Date().toISOString()) ?? '',
      toTimestamp: toClientDateInput(new Date().toISOString()) ?? '',
      location: selectedPersonLocation ? String(selectedPersonLocation.id) : undefined,
      hours: 0.0,
      types: {
        isParticipated: false,
        isUnderDirection: false,
        isAlmostIndependent: false,
        isIndependent: false,
        [payload.selectedType]: true,
      },
    },
  });

  const handleExit = () => {
    dispatch(efficacyAssessmentModalActions.closeEfficacyAssessmentModal());
    dispatch(efficacyAssessmentModalActions.closeRecordingEffectivenessModal());
  };

  const selectedMeasure = useAppSelector(efficacyAssessmentSelectors.selectedMeasure);

  const onSubmit = (data: IRecordEffectivenessFormValues) => {
    if (!selectedMeasure) {
      return;
    }

    dispatch(
      efficacyAssessmentActions.createEfficacyAssessment({
        personID: payload.personID,
        measureID: payload.measureID,
        measureTitle: selectedMeasure.name,
        isParticipated: data.types.isParticipated,
        isUnderDirection: data.types.isUnderDirection,
        isAlmostIndependent: data.types.isAlmostIndependent,
        isIndependent: data.types.isIndependent,
        appointmentID: data.appointmentID,
        level: payload.levelID,
        fromTimestamp: data.fromTimestamp,
        toTimestamp: data.toTimestamp,
        hours: data.hours,
        location: data.location,
        implementationAidsIDs: [],
        note: data.note,
      })
    );

    handleExit();
  };

  const fromTimestamp = form.watch('fromTimestamp');
  const toTimestamp = form.watch('toTimestamp');

  useEffect(() => {
    const fromTimestampDate = parse(fromTimestamp, FRONTEND_DATE_FORMAT, new Date());
    const toTimestampDate = parse(toTimestamp, FRONTEND_DATE_FORMAT, new Date());

    if (fromTimestampDate && toTimestampDate && fromTimestampDate > toTimestampDate) {
      form.setValue('toTimestamp', fromTimestamp);
    }
  }, [fromTimestamp, toTimestamp]);

  const renderFooter = (
    <div className='flex gap-2.5 justify-end'>
      <Button type='submit' onClick={form.handleSubmit(onSubmit)}>
        {recordingEffectivenessModalTranslations('buttons.save.label')}
      </Button>
      <Button
        type='button'
        onClick={() => dispatch(efficacyAssessmentModalActions.closeRecordingEffectivenessModal())}
        buttonVariant='Secondary'
      >
        {recordingEffectivenessModalTranslations('buttons.close.label')}
      </Button>
    </div>
  );

  return (
    <Sidebar
      onExit={handleExit}
      isNested
      className='w-[1200px]'
      footer={renderFooter}
      position='right'
      onBackTo={() => dispatch(efficacyAssessmentModalActions.closeRecordingEffectivenessModal())}
    >
      <div className='flex flex-col gap-4 p-7'>
        <Typography type='H3'>{selectedMeasure?.name}</Typography>
        <FormProvider {...form}>
          <RecordingEffictivenessForm onSubmit={onSubmit} />
        </FormProvider>
      </div>
    </Sidebar>
  );
};

export const RecordingEffectivenessModal: React.FC = () => {
  const { isRender: isOpened, payload } = useAppSelector(
    efficacyAssessmentModalSelectors.recordingEffectivenessModal
  );

  if (isOpened && payload) {
    return <ModalContent payload={payload} />;
  }

  return null;
};
