import React, { useState } from 'react';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { PinnedIcon, UnPinnedIcon } from '@/components/Icons/PinIcons';
import { PrivateImage } from '@/components/PrivateImage/PrivateImage';
import { Typography } from '@/components/Typography/Typography';
import { functionViews } from '@/components/views/functions/FunctionContainer/FunctionContainer';
import { FunctionTypeTag } from '@/components/views/functions/FunctionContainer/FunctionTypeTag/FunctionTypeTag';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageActions } from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { commonModalsActions } from '@/core/redux/slices/modalsSlice/common/commonModalsSlice';
import { FunctionTypes, IFunctionItem } from '@/types/functions';

import styles from './styles.module.scss';

export const FunctionItem: React.FC<IFunctionItem> = ({
  iconUrl,
  name,
  functionType,
  isPinned,
  id,
  functionID,
}) => {
  const [pinned, setPinned] = useState<boolean>(isPinned);
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const activeTabId = useAppSelector(functionPageSelectors.activeTabId);
  const dispatch = useAppDispatch();

  const handleOnClick = () => {
    const foundView = functionViews[functionID];

    if (foundView?.wizardView && functionType === FunctionTypes.none) {
      dispatch(commonModalsActions.openChooseFunctionTypeModal(id));
      return;
    }

    if (selectedPerson?.id && activeTabId) {
      const payload = {
        userId: selectedPerson.id,
        tabId: activeTabId,
        functionItem: {
          id: functionID,
          functionType,
          iconUrl,
          name,
        },
      };
      dispatch(functionPageActions.setTabFunction(payload));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleOnClick();
    }
  };

  const handlePin = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(functionPageActions.updatePinned({ id, is_pinned: !pinned }));
    setPinned((prev) => !prev);
  };

  return (
    <div
      className={clsx(
        styles.container,
        'flex flex-row items-center justify-start w-full py-3 h-[52px]'
      )}
      onClick={handleOnClick}
      role='button'
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <PrivateImage id={iconUrl} alt={`${name}_icon`} className={clsx(styles.icon, 'w-5 h-5')} />
      <Typography type='16-R' color='Primary' className={clsx(styles.name, 'flex-grow ms-2')}>
        {name}
      </Typography>
      {functionType !== FunctionTypes.none && (
        <FunctionTypeTag functionType={functionType} id={id} className='shrink-0' />
      )}
      <Button
        buttonVariant={'Icon'}
        buttonStyle={'Icon'}
        className={clsx(styles.button, 'ms-2 flex items-center')}
        onClick={handlePin}
      >
        {pinned ? (
          <PinnedIcon className={styles.pinnedIcon} />
        ) : (
          <UnPinnedIcon className={styles.unPinnedIcon} />
        )}
      </Button>
    </div>
  );
};
