import React from 'react';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { twMerge } from '@/core/utils/tailwindUtil';

export interface ICheckboxItem {
  label: React.ReactNode;
  fieldName: string;
  labelPosition?: 'right' | 'left';
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}
export interface ICheckboxGroup {
  options: CheckboxOption[];
  layout?: 'vertical' | 'horizontal';
  name?: string;
}

export type CheckboxOption = { id: number; name: string };

export const CheckboxItem: React.FC<ICheckboxItem> = ({
  label,
  fieldName,
  className,
  labelPosition = 'left',
  onClick,
  disabled,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    if (onClick) {
      event.preventDefault();
      return;
    }
  };

  return (
    <div
      className={twMerge(
        'flex gap-2',
        labelPosition === 'left' ? 'flex-row' : 'flex-row-reverse justify-end',
        disabled && 'pointer-events-none',
        className
      )}
    >
      <label htmlFor={fieldName} onClick={handleClick}>
        {label}
      </label>
      <Checkbox
        name={fieldName}
        id={fieldName}
        onClick={onClick}
        className={'shrink-0'}
        disabled={disabled}
      />
    </div>
  );
};

export const CheckboxGroup: React.FC<ICheckboxGroup> = ({ options, name, layout = 'vertical' }) => {
  return (
    <div
      className={twMerge(
        'flex gap-1',
        layout === 'vertical' && 'flex-col',
        layout === 'horizontal' && 'flex-row'
      )}
    >
      {options.map((option: CheckboxOption) => {
        const id = String(option.id);
        return (
          <CheckboxItem
            key={option.id}
            label={option.name}
            fieldName={name ? `${name}.${id}` : id}
            labelPosition={layout === 'vertical' ? 'right' : 'left'}
          />
        );
      })}
    </div>
  );
};
