import React from 'react';

import { Internships } from './Internships/Internships';
import { Permissions } from './Permissions/Permissions';

export const IndividualAuthorizations: React.FC = () => {
  return (
    <div className='flex gap-6'>
      <div className='w-1/2'>
        <Permissions />
      </div>
      <div className='w-1/2'>
        <Internships />
      </div>
    </div>
  );
};
