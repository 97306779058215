import { createSelector } from '@reduxjs/toolkit';

import { IRootState } from '@/core/redux/types';

const selector = (state: IRootState) => state.modals.functions.organizationalUnitsModals;

const createOrganizationalUnitModal = createSelector(
  [selector],
  (state) => state.createOrganizationalUnitModal
);
const updateOrganizationalUnitModal = createSelector(
  [selector],
  (state) => state.updateOrganizationalUnitModal
);
const organizationalUnitBBB = createSelector(
  [selector],
  (state) => state.organizationalUnitsForms.organizationalUnitBBB
);
const additionalPersonRequirementModal = createSelector(
  [selector],
  (state) => state.additionalPersonRequirementModal
);
const additionalPersonRequirementTimeListModal = createSelector(
  [selector],
  (state) => state.additionalPersonRequirementTimeListModal
);

const additionalPersonRequirementAssessmentPrognosisModal = createSelector(
  [selector],
  (state) => state.additionalPersonRequirementAssessmentPrognosisModal
);

export const organizationalUnitsModalsSelectors = {
  createOrganizationalUnitModal,
  updateOrganizationalUnitModal,
  organizationalUnitBBB,
  additionalPersonRequirementModal,
  additionalPersonRequirementTimeListModal,
  additionalPersonRequirementAssessmentPrognosisModal,
};
