import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IAppointmentParticipantsListFetchPayload {
  appointmentID: number;
  measureID: number;
}

export interface IActivityPlanningAppointmentParticipant {
  id: number;
  name: string;
  department: string;
}

export interface IActivityPlanningAppointmentParticipantsList {
  participantsList: IActivityPlanningAppointmentParticipant[];
  participantsListLock: LoadingStatus;
}

const initialState: IActivityPlanningAppointmentParticipantsList = {
  participantsList: [],
  participantsListLock: LoadingStatus.NEVER,
};

export const activityPlanningAppointmentParticipantsList = createSlice({
  name: 'measuresList',
  initialState,
  reducers: {
    fetchAppointmentParticipantsList: (
      state,
      action: PayloadAction<IAppointmentParticipantsListFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAppointmentParticipantsList: (
      state,
      action: PayloadAction<IActivityPlanningAppointmentParticipant[]>
    ) => ({
      ...state,
      participantsList: action.payload,
    }),
    setAppointmentParticipantsListLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      participantsListLock: action.payload,
    }),
  },
});

export const activityPlanningAppointmentParticipantsListReducers =
  activityPlanningAppointmentParticipantsList.reducer;
export const activityPlanningAppointmentParticipantsListActions =
  activityPlanningAppointmentParticipantsList.actions;
