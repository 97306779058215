import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';
import { QuestionnaireStatus } from '@/core/enums/functions/performanceProfile/questionnaires/questionnaireStatusEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface IQuestionnaireStatus {
  status?: QuestionnaireStatus | null;
}

export const QuestionnaireStatusContainer: React.FC<IQuestionnaireStatus> = ({ status }) => {
  const { t: questionnaireTranslations } = useTranslation('questionnaire', { keyPrefix: 'status' });

  const updateLoadingStatus = useAppSelector(performanceProfileSelectors.updateQuestionnaireLock);
  const dispatch = useAppDispatch();

  const questionnaireStatuses: Record<QuestionnaireStatus, React.ReactNode> = {
    [QuestionnaireStatus.Started]: (
      <div className={`flex items-center py-1 px-3 bg-${colorTypes.Orange} rounded-full`}>
        <Typography color={'White'} type={'14-M'}>
          {questionnaireTranslations('questionnaireStatus.started.label')}
        </Typography>
      </div>
    ),
    [QuestionnaireStatus.ForApproval]: (
      <div className={`flex items-center py-1 px-3 bg-${colorTypes.Tiffany} rounded-full`}>
        <Typography color={'White'} type={'14-M'}>
          {questionnaireTranslations('questionnaireStatus.forApproval.label')}
        </Typography>
      </div>
    ),
    [QuestionnaireStatus.Release]: (
      <div className={`flex items-center py-1 px-3 bg-${colorTypes.Blue} rounded-full`}>
        <Typography color={'White'} type={'14-M'}>
          {questionnaireTranslations('questionnaireStatus.release.label')}
        </Typography>
      </div>
    ),
  };

  const updateQuestionnaireStatuses: Record<LoadingStatus, React.ReactNode> = {
    [LoadingStatus.LOADED]: (
      <Typography color={'Secondary'}>
        {questionnaireTranslations('updateLoadingStatus.saved.label')}
      </Typography>
    ),
    [LoadingStatus.NEVER]: null,
    [LoadingStatus.LOADING]: (
      <Typography color={'Secondary'}>
        {questionnaireTranslations('updateLoadingStatus.saving.label')}
      </Typography>
    ),
    [LoadingStatus.ERROR]: (
      <Typography color={'Secondary'}>
        {questionnaireTranslations('updateLoadingStatus.error.label')}
      </Typography>
    ),
    [LoadingStatus.LOADED_EMPTY]: null,
  };

  useEffect(() => {
    return () => {
      dispatch(performanceProfileActions.setUpdateQuestionnaireLock(LoadingStatus.NEVER));
    };
  }, []);

  return (
    <div
      className={`flex flex-row items-center gap-2 border-y py-2 border-${colorTypes.Stroke} px-[30px]`}
    >
      <Typography type={'14-R'} color={'Secondary'}>
        {questionnaireTranslations('questionnaireStatus.label')}
      </Typography>
      {status && questionnaireStatuses[status]}
      <div className={'ml-5'}>
        {updateLoadingStatus && updateQuestionnaireStatuses[updateLoadingStatus]}
      </div>
    </div>
  );
};
