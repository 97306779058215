import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsSelectors } from '@/core/redux/slices/functions/organizationalUnits/selectors';
import { organizationalUnitsActions } from '@/core/redux/slices/functions/organizationalUnits/slice';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IOrganizationalUnitsForm {
  comment?: string | null;
}

export const OrganizationalUnits: React.FC = () => {
  const { t: organizationalUnitsTranslations } = useTranslation('organizationalUnits');

  const [selectedUnitID, setSelectedUnitID] = useState<number | null>(null);

  const organizationalUnits = useAppSelector(organizationalUnitsSelectors.organizationalUnits);
  const loadingStatus = useAppSelector(organizationalUnitsSelectors.organizationalUnitsLock);

  const { selectedPersonID } = useSelectedPerson();

  const form = useForm<IOrganizationalUnitsForm>();

  const dispatch = useAppDispatch();

  const columns: ITableColumn[] = [
    {
      accessor: 'name',
      header: organizationalUnitsTranslations('table.header.name.label'),
    },
    {
      accessor: 'fromTimestamp',
      header: organizationalUnitsTranslations('table.header.from.label'),
    },
    { accessor: 'toTimestamp', header: organizationalUnitsTranslations('table.header.to.label') },
  ];

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsActions.fetchOrganizationalUnits({
        personID: selectedPersonID,
      })
    );

    return () => {
      dispatch(organizationalUnitsActions.setOrganizationalUnits([]));
    };
  }, [selectedPersonID]);

  useEffect(() => {
    if (!organizationalUnits) {
      return;
    }

    const foundElement = organizationalUnits.find((unit) => unit.id === selectedUnitID);

    if (!foundElement) {
      return;
    }

    form.reset({
      comment: foundElement.comment,
    });
  }, [organizationalUnits, selectedUnitID]);

  const handleSelect = (id: string) => {
    setSelectedUnitID(selectedUnitID === Number(id) ? null : Number(id));
  };

  const handleCreate = () => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.openCreateOrganizationalUnitModal({
        personID: selectedPersonID,
      })
    );
  };
  const handleEdit = () => {
    if (!selectedPersonID) {
      return;
    }

    const foundOrganizationalUnit = organizationalUnits.find(
      (organizationalUnit) => organizationalUnit.id === selectedUnitID
    );

    if (!foundOrganizationalUnit) {
      return;
    }

    dispatch(
      organizationalUnitsModalsActions.openUpdateOrganizationalUnitModal({
        personID: selectedPersonID,
        organizationalUnit: foundOrganizationalUnit,
      })
    );
  };

  const handleDelete = () => {
    if (!selectedPersonID || !selectedUnitID) {
      return;
    }

    dispatch(
      organizationalUnitsActions.deleteOrganizationalUnit({
        personID: selectedPersonID,
        organizationalUnitID: selectedUnitID,
      })
    );
  };

  return (
    <FunctionWrapper>
      <FunctionContent withFooter={true}>
        <div className={'flex flex-col mt-5 gap-3'}>
          <Typography type={'H3'} className={'px-7'}>
            {organizationalUnitsTranslations('title.label')}
          </Typography>
          <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
            <div className={'flex-grow'}>
              <Table
                columns={columns}
                data={organizationalUnits}
                initialSelectedState={[selectedUnitID]}
                singleSelect={true}
                onSingleSelectChange={handleSelect}
              />
            </div>
            <div className={'px-7'}>
              <FormProvider {...form}>
                <TextArea control={form.register('comment')} />
              </FormProvider>
            </div>
          </Skeleton>
        </div>
      </FunctionContent>
      <FunctionFooter>
        <Button onClick={handleCreate}>
          {organizationalUnitsTranslations('buttons.create.label')}
        </Button>
        <Button onClick={handleEdit}>
          {organizationalUnitsTranslations('buttons.edit.label')}
        </Button>
        <Button onClick={handleDelete}>
          {organizationalUnitsTranslations('buttons.delete.label')}
        </Button>
      </FunctionFooter>
    </FunctionWrapper>
  );
};
