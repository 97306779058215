import React from 'react';

import { ITableColumn, Table } from '@/components/Table/Table';

const data = [
  {
    id: '1',
    name: 'Center first',
  },
  {
    id: '2',
    name: 'Center second',
  },
  {
    id: '3',
    name: 'Center third',
  },
];

export const CostCenters: React.FC = () => {
  const columns: ITableColumn[] = [{ header: 'Name', accessor: 'name' }];

  return (
    <>
      <Table data={data} columns={columns} />
    </>
  );
};
