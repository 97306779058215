import React from 'react';

import { AdditionalPersonRequirementModalContent } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModalContent/AdditionalPersonRequirementModalContent';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { organizationalUnitsModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/selectors';
import { organizationalUnitsModalsActions } from '@/core/redux/slices/modalsSlice/functions/organizationalUnits/slice';

export const AdditionalPersonRequirementModal: React.FC = () => {
  const { isRender: isOpened } = useAppSelector(
    organizationalUnitsModalsSelectors.additionalPersonRequirementModal
  );

  const dispatch = useAppDispatch();

  const handleExit = () => {
    dispatch(organizationalUnitsModalsActions.closeAdditionalPersonRequirementModal());
  };

  return isOpened ? (
    <Modal onExit={handleExit} className={'min-w-[700px]'}>
      <AdditionalPersonRequirementModalContent handleExit={handleExit} />
    </Modal>
  ) : null;
};
