import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { LoadingStatus } from '@/types/loadingStatus';

interface IQuestionaireFooter {
  isQuestionnaireCompleted: boolean;
  competenceAnalysisID?: number | null;
  completeLoadingStatus: LoadingStatus;
}

export const QuestionnaireFooter: React.FC<IQuestionaireFooter> = ({
  isQuestionnaireCompleted,
  competenceAnalysisID,
  completeLoadingStatus,
}) => {
  const { t: questionnaireTranslations } = useTranslation('questionnaire');

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  const dispatch = useAppDispatch();

  const handleComplete = () => {
    if (!selectedPersonID || !competenceAnalysisID) {
      return;
    }

    dispatch(
      performanceProfileActions.completeQuestionnaire({
        personID: selectedPersonID,
        competenceAnalysisID: competenceAnalysisID,
      })
    );
  };

  return (
    <div className='shadow-[0px_2px_10px_0px_rgba(110,123,148,0.20)] w-full px-8 py-[10px] flex items-center'>
      <Button
        className={'flex gap-2.5 items-center'}
        disabled={!isQuestionnaireCompleted}
        onClick={handleComplete}
      >
        <span>{questionnaireTranslations('buttons.completeQuestionnaire.label')}</span>
        {completeLoadingStatus === LoadingStatus.LOADING && (
          <SpinnerIcon className='w-[22px] h-[22px]' />
        )}
      </Button>
    </div>
  );
};
