import React from 'react';
import clsx from 'clsx';
import {
  addDays,
  eachDayOfInterval,
  format,
  isAfter,
  isSameDay,
  isToday,
  startOfWeek,
} from 'date-fns';

import { CalendarMonthSelect } from '@/components/Datepicker/Calendar/CalendarMonthSelect/CalendarMonthSelect';
import { CalendarYearSelect } from '@/components/Datepicker/Calendar/CalendarYearSelect/CalendarYearSelect';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { colorTypes } from '@/styles/types';

import { useDatePickerCalendar } from '../hooks/useDatePickerCalendar';

interface CalendarProps {
  onDateChange: (date: Date) => void;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  prevMonth?: boolean;
  nextMonth?: boolean;
  isFutureSelectionLocked?: boolean;
}

export const Calendar: React.FC<CalendarProps> = ({
  prevMonth,
  nextMonth,
  selectedStartDate,
  selectedEndDate,
  onDateChange,
  isFutureSelectionLocked,
}) => {
  const {
    startDate,
    endDate,
    allDays,
    selectedYear,
    selectedMonth,
    setSelectedYear,
    setSelectedMonth,
  } = useDatePickerCalendar(selectedStartDate);
  const start = startOfWeek(new Date(), { weekStartsOn: 1 });
  const weekDays = eachDayOfInterval({ start, end: addDays(start, 6) });

  const daysOfWeek = weekDays.map((day) => format(day, 'EEE'));
  const isCurrentMonth = selectedMonth === new Date().getMonth();

  const handleNextMonth = () => {
    setSelectedMonth((prev) => {
      if (isCurrentMonth) {
        return prev;
      }

      if (prev === 11) {
        setSelectedYear((prev) => prev + 1);
        return 0;
      }

      return prev + 1;
    });
  };

  const handlePrevMonth = () => {
    setSelectedMonth((prev) => {
      if (prev === 0) {
        setSelectedYear((prev) => prev - 1);
        return 11;
      }
      return prev - 1;
    });
  };

  return (
    <div className='inline-block min-w-[264px]'>
      <div className='flex items-center justify-between'>
        <div>
          {prevMonth && (
            <div onClick={handlePrevMonth} className='cursor-pointer'>
              <ArrowRightIcon className={`rotate-180 text-${colorTypes.Grey}`} />
            </div>
          )}
        </div>
        <CalendarMonthSelect
          setSelectedMonth={setSelectedMonth}
          selectedMonth={selectedMonth}
          isFutureSelectionLocked={isFutureSelectionLocked}
        />
        <CalendarYearSelect
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          isFutureSelectionLocked={isFutureSelectionLocked}
        />
        <div>
          {nextMonth && (
            <div
              onClick={handleNextMonth}
              className={isCurrentMonth ? 'cursor-default' : 'cursor-pointer'}
            >
              <ArrowRightIcon
                className={`text-${isCurrentMonth ? 'transparent' : colorTypes.Grey}`}
              />
            </div>
          )}
        </div>
      </div>
      <div className='grid grid-cols-7 pb-4 pt-6 text-center'>
        {daysOfWeek.map((item?: string) => (
          <span key={item} className='text-onyx-50'>
            {item}
          </span>
        ))}
      </div>
      <div className='grid grid-cols-7'>
        {allDays.map((day) => {
          const isTodayCheck = isToday(day);
          const isActive =
            (selectedStartDate && isSameDay(day, selectedStartDate)) ||
            (selectedEndDate && isSameDay(day, selectedEndDate));
          const isCurrentMonth = day >= startDate && day <= endDate;
          const isInFuture = isAfter(day, new Date());

          return (
            <button
              type='button'
              key={day.toISOString()}
              className={clsx(
                'h-[30px] w-[30px] mx-1 my-1',
                isTodayCheck && `border-${colorTypes.Blue} border-b-[1.5px]`,
                isActive &&
                  `bg-${colorTypes.Blue} rounded-lg text-${colorTypes.White} border-b-[1.5px] border-transparent`,
                (!isCurrentMonth || (isFutureSelectionLocked && isInFuture)) &&
                  `text-${colorTypes.Disabled} border-b-[1.5px] border-transparent`
              )}
              onClick={() => onDateChange(day)}
            >
              {format(day, 'dd')}
            </button>
          );
        })}
      </div>
    </div>
  );
};
