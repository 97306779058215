import React, { useState } from 'react';

import { PersonFavoriteButton } from '@/components/PersonFavoriteButton/PersonFavoriteButton';
import { PersonFunctionsShortcuts } from '@/components/PersonFunctionsShortcuts/PersonFunctionsShortcuts';
import { Typography } from '@/components/Typography/Typography';
import { IFavoritePerson } from '@/core/redux/slices/dashboard/dashboardSlice';
import { generateName } from '@/core/utils/commonUtils';
import { colorTypes } from '@/styles/types';

export const FavoritePerson: React.FC<IFavoritePerson> = ({ id, name, surname, functions }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`p-2 grid grid-cols-12 gap-2 justify-between items-center border-l border-transparent hover:bg-${colorTypes.HoverLight} hover:border-${colorTypes.Blue}`}
    >
      <Typography type={'16-R'} className={'col-span-5'}>
        {generateName({ name: name, surname: surname })}
      </Typography>
      <PersonFunctionsShortcuts
        modalContext={false}
        personID={id}
        functions={functions}
        isHovered={isHovered}
        className={'col-span-5 justify-self-center'}
      />
      <PersonFavoriteButton
        isHovered={isHovered}
        personID={id}
        className={'col-span-2 justify-self-end'}
      />
    </div>
  );
};
