import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Typography } from '@/components/Typography/Typography';

export const EndOfSecondYear: React.FC = () => {
  const { t: endOfSecondYearTranslations } = useTranslation('summaryFA', {
    keyPrefix: 'endOfSecondYear',
  });

  return (
    <div className={'flex flex-col gap-3'}>
      <Typography type={'H3'}>{endOfSecondYearTranslations('title.label')}</Typography>
      <CheckboxItem
        label={endOfSecondYearTranslations('fields.isEndeBBB21.label')}
        fieldName={'isEndeBBB21'}
        className={'justify-between'}
      />
      <div className={'ps-[50px] flex flex-col gap-3'}>
        <CheckboxItem
          label={endOfSecondYearTranslations('fields.isEndeBBB22.label')}
          fieldName={'isEndeBBB22'}
          className={'justify-between'}
        />
        <CheckboxItem
          label={endOfSecondYearTranslations('fields.isEndeBBB23.label')}
          fieldName={'isEndeBBB23'}
          className={'justify-between'}
        />
        <CheckboxItem
          label={endOfSecondYearTranslations('fields.isEndeBBB24.label')}
          fieldName={'isEndeBBB24'}
          className={'justify-between'}
        />
        <CheckboxItem
          label={endOfSecondYearTranslations('fields.isEndeBBB25.label')}
          fieldName={'isEndeBBB25'}
          className={'justify-between'}
        />
      </div>
      <CheckboxItem
        label={endOfSecondYearTranslations('fields.isEndeBBB26.label')}
        fieldName={'isEndeBBB26'}
        className={'justify-between'}
      />
    </div>
  );
};
