import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Select } from '@/components/Select/Select';
import { ISelectItem } from '@/components/Select/SelectItem/SelectItem';
import { Typography } from '@/components/Typography/Typography';
import { DropdownItemsByFetch } from '@/core/enums/common/DropdownItemsByFetchEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { testingResultModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/testingResult/selectors';
import { testingResultModalsActions } from '@/core/redux/slices/modalsSlice/functions/testingResult/testingResultModalsSlice';

export const TestProcedureSelect: React.FC = () => {
  const [selected, setSelected] = useState<string | undefined>();

  const testProcedureList = useAppSelector(testingResultModalsSelectors.testProcedureList);

  const formContext = useFormContext();
  const formIsDirty = useFormContext().formState.isDirty;
  const selectedID = formContext.watch('procedure.id');

  const selectItems: ISelectItem[] = testProcedureList.map((testPerformer) => ({
    id: testPerformer.id.toString(),
    component: <Typography>{testPerformer.value}</Typography>,
    showCheckIcon: false,
  }));

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      testingResultModalsActions.fetchTestProceduresList(DropdownItemsByFetch.TEST_PROCEDURE)
    );
  }, []);

  const handleChange = (id: string) => {
    formContext.setValue('procedure.id', id, { shouldDirty: true });
  };

  useEffect(() => {
    if (!formIsDirty) {
      setSelected(formContext.watch('procedure.id'));
    }
  }, [formIsDirty]);

  return (
    <Select
      className={'w-full'}
      values={selectItems}
      onChange={handleChange}
      initialValueID={selectedID}
      selectedExternal={selected}
      setSelectedExternal={setSelected}
    />
  );
};
