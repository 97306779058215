import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISchema, ObjectSchema } from 'yup';

import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { MobilityRefundVariant } from '@/core/enums/functions/workingTimeMobility/mobilityRefundVariantsEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import {
  ISelfDrive,
  ITransportTypeService,
  mobilityActions,
} from '@/core/redux/slices/functions/workingTimeMobility/mobility/mobilitySlice';
import { mobilitySelectors } from '@/core/redux/slices/functions/workingTimeMobility/mobility/selectors';
import { yup } from '@/core/utils/commonUtils';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IMobilityForm {
  arrivalType: MobilityFormTypes;
  arrivalFormID: number;
  arrivalForm: {
    carService?: ITransportTypeService | null;
    selfDriver?: ISelfDrive | null;
  };
  departureType: MobilityFormTypes;
  departureFormID: number;
  departureForm: {
    carService?: ITransportTypeService | null;
    selfDriver?: ISelfDrive | null;
  };
}

const transportTypeServiceSchemaRequired: ObjectSchema<ITransportTypeService> = yup.object().shape({
  type: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().nullable(),
  }),
  contacts: yup.string().nullable(),
  date: yup.string().nullable(),
});

const transportTypeServiceSchema: ObjectSchema<ITransportTypeService> = yup.object().shape({
  type: yup.object().shape({
    id: yup.number(),
    name: yup.string().nullable(),
  }),
  contacts: yup.string().nullable(),
  date: yup.string().nullable(),
});

const selfDriveSchema: ObjectSchema<ISelfDrive> = yup.object().shape({
  refundVariant: yup.mixed<MobilityRefundVariant>().oneOf(Object.values(MobilityRefundVariant)),
  kilometer: yup.number().nullable(),
});

const selfDriveSchemaRequired: ObjectSchema<ISelfDrive> = yup.object().shape({
  refundVariant: yup
    .mixed<MobilityRefundVariant>()
    .oneOf(Object.values(MobilityRefundVariant))
    .required(),
  kilometer: yup.number().nullable(),
});

const arrivalFormValidation = (values: string[]): ISchema<any, any, any, any> => {
  const arrivalType = values[0];

  switch (arrivalType) {
    case MobilityFormTypes.CarService:
      return yup.object().shape({
        carService: transportTypeServiceSchemaRequired.nullable(),
        selfDriver: selfDriveSchema.nullable(),
      });
    case MobilityFormTypes.SelfDriver:
      return yup.object().shape({
        carService: transportTypeServiceSchema.nullable(),
        selfDriver: selfDriveSchemaRequired.nullable(),
      });
    default:
      return yup.object().shape({
        carService: transportTypeServiceSchema.nullable(),
        selfDriver: selfDriveSchema.nullable(),
      });
  }
};

const departureFormValidation = (values: string[]): ISchema<any, any, any, any> => {
  const departureType = values[0];

  switch (departureType) {
    case MobilityFormTypes.CarService:
      return yup.object().shape({
        carService: transportTypeServiceSchemaRequired.nullable(),
        selfDriver: selfDriveSchema.nullable(),
      });
    case MobilityFormTypes.CarService:
      return yup.object().shape({
        carService: transportTypeServiceSchema.nullable(),
        selfDriver: selfDriveSchemaRequired.nullable(),
      });
    default:
      return yup.object().shape({
        carService: transportTypeServiceSchema.nullable(),
        selfDriver: selfDriveSchema.nullable(),
      });
  }
};

const mobilityFormSchema: ObjectSchema<IMobilityForm> = yup.object().shape({
  arrivalType: yup.mixed<MobilityFormTypes>().oneOf(Object.values(MobilityFormTypes)).required(),
  arrivalFormID: yup.number().required(),
  arrivalForm: yup.object().when('arrivalType', arrivalFormValidation),
  departureType: yup.mixed<MobilityFormTypes>().oneOf(Object.values(MobilityFormTypes)).required(),
  departureFormID: yup.number().required(),
  departureForm: yup.object().when('departureType', departureFormValidation),
});

const FORM_DEFAULT_VALUES: IMobilityForm = {
  departureFormID: -1,
  arrivalFormID: -1,
  departureForm: {
    carService: {
      type: {
        id: -1,
        name: 'Default',
      },
      date: null,
      contacts: null,
    },
    selfDriver: {
      refundVariant: MobilityRefundVariant.Default,
      kilometer: 0,
    },
  },
  arrivalForm: {
    carService: {
      type: {
        id: -1,
        name: 'Default',
      },
      date: null,
      contacts: null,
    },
    selfDriver: {
      refundVariant: MobilityRefundVariant.Default,
      kilometer: 0,
    },
  },
  departureType: MobilityFormTypes.CarService,
  arrivalType: MobilityFormTypes.CarService,
};

export const useMobilityForm = () => {
  const mobility = useAppSelector(mobilitySelectors.mobility);

  const form = useForm<IMobilityForm>({
    mode: 'onChange',
    resolver: yupResolver(mobilityFormSchema),
  });

  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(mobilityActions.setMobility(null));
    dispatch(mobilityActions.setMobilityLock(LoadingStatus.NEVER));

    dispatch(mobilityActions.fetchMobility({ personID: selectedPersonID }));
  }, [selectedPersonID]);

  useEffect(() => {
    if (!mobility) {
      form.reset(FORM_DEFAULT_VALUES);
      return;
    }

    const { arrival, departure } = mobility;

    const initialValues: IMobilityForm = {
      arrivalType: arrival.type.type || MobilityFormTypes.Default,
      arrivalFormID: arrival.type?.id,
      arrivalForm: {
        selfDriver: arrival.selfDrive
          ? {
              refundVariant: arrival.selfDrive.refundVariant,
              kilometer: arrival.selfDrive.kilometer,
            }
          : undefined,
        carService: arrival.transportService
          ? {
              type: arrival.transportService.type,
              contacts: arrival.transportService.contacts,
              date: arrival.transportService.date,
            }
          : undefined,
      },
      departureType: departure.type.type || MobilityFormTypes.Default,
      departureFormID: departure.type?.id,
      departureForm: {
        selfDriver: departure.selfDrive
          ? {
              refundVariant: departure.selfDrive.refundVariant,
              kilometer: departure.selfDrive.kilometer,
            }
          : undefined,
        carService: departure.transportService
          ? {
              type: departure.transportService.type,
              contacts: departure.transportService.contacts,
              date: departure.transportService.date,
            }
          : undefined,
      },
    };

    form.reset(initialValues);
  }, [mobility]);

  return { form };
};
