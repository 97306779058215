import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { Typography } from '@/components/Typography/Typography';
import styles from '@/components/views/person/PersonPage/DepartmentView/styles.module.scss';

const CHECK_ALL_FIELD_NAME = 'departments_check_all';
export const DepartmentSelectAll: React.FC = () => {
  const { t } = useTranslation('tms', { keyPrefix: 'ui.label' });
  const formContext = useFormContext();
  const selectAll = formContext.watch(CHECK_ALL_FIELD_NAME);

  useEffect(() => {
    if (selectAll === undefined) {
      formContext.setValue(CHECK_ALL_FIELD_NAME, false);
    }
  }, []);

  return (
    <div className='flex flex-row items-center'>
      <Checkbox
        name={CHECK_ALL_FIELD_NAME}
        className={clsx(styles.checkBox, 'mr-2')}
        id={CHECK_ALL_FIELD_NAME}
      />
      <label className={'cursor-pointer'} htmlFor={CHECK_ALL_FIELD_NAME}>
        <Typography type='16-R' color='Primary'>
          {t('allDepartments')}
        </Typography>
      </label>
    </div>
  );
};
