import React, {
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useImperativeHandle,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import fp from 'lodash/fp';

import { Checkbox } from '@/components/Checkbox/Checkbox';
import { EndeEV } from '@/components/forms/SummaryFA/EndeEV/EndeEV';
import { EndOfFirstYear } from '@/components/forms/SummaryFA/EndOfFirstYear/EndOfFirstYear';
import { EndOfSecondYear } from '@/components/forms/SummaryFA/EndOfSecondYear/EndOfSecondYear';
import { useSelectedPersonForm } from '@/components/functions/IntegrationPlan/hooks/useSelectedPersonForm';
import { IntegrationPlanFormActions } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { IIntegrationPlanFormProps } from '@/components/functions/IntegrationPlan/IntegrationPlanFormsContainer/IntegrationPlanFormsContainer';
import { Input } from '@/components/Input/Input';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { summaryFASelectors } from '@/core/redux/slices/functions/integrationPlan/summaryFA/selectors';
import {
  ISummaryFA,
  summaryFAActions,
} from '@/core/redux/slices/functions/integrationPlan/summaryFA/summaryFASlice';

export const SummaryFA: ForwardRefExoticComponent<
  Omit<IIntegrationPlanFormProps, 'ref'> & RefAttributes<IntegrationPlanFormActions>
> = forwardRef((props, ref) => {
  const { integrationPlanID, personID } = props;

  const { t: summaryFATranslations } = useTranslation('summaryFA');

  const initialValues = useAppSelector(summaryFASelectors.summaryFA);

  const form = useForm<ISummaryFA>();

  useSelectedPersonForm({ form, integrationPlanID, initialValues });

  const dispatch = useAppDispatch();

  const onBeforeExitCheck = () => {
    const currentValues = form.watch();

    if (fp.isEqual(currentValues, initialValues)) {
      return true;
    }

    if (!personID || integrationPlanID === null) {
      return true;
    }

    dispatch(
      summaryFAActions.updateSummaryFA({
        personID: personID,
        integrationPlanID: integrationPlanID,
        summaryFA: currentValues,
      })
    );

    dispatch(summaryFAActions.setSummaryFA(null));

    return true;
  };

  useImperativeHandle(ref, () => ({
    onBeforeExitCheck: onBeforeExitCheck,
  }));

  useEffect(() => {
    if (!integrationPlanID || !personID) {
      return;
    }

    dispatch(summaryFAActions.fetchSummaryFA({ integrationPlanID: integrationPlanID }));
  }, [integrationPlanID, personID]);

  return (
    <FormProvider {...form}>
      <form className={'flex flex-col gap-[30px]'} onSubmit={form.handleSubmit(() => {})}>
        <div className={'flex flex-row justify-between'}>
          <label htmlFor='isPersonnelRequired'>
            <Typography type={'H3'}>
              {summaryFATranslations('isPersonnelRequired.label')}
            </Typography>
          </label>
          <Checkbox name={'isPersonnelRequired'} id={'isPersonnelRequired'} />
        </div>
        <EndeEV />
        <EndOfFirstYear />
        <EndOfSecondYear />
        <div className={'flex flex-row justify-between'}>
          <label htmlFor='isUnsuitableWfbM'>
            <Typography type={'H3'}>{summaryFATranslations('isUnsuitableWfbM.label')}</Typography>
          </label>
          <Checkbox name={'isUnsuitableWfbM'} id={'isUnsuitableWfbM'} />
        </div>
        <div className={'flex flex-col gap-2'}>
          <Typography type={'H3'}>{summaryFATranslations('notes.title.label')}</Typography>
          <Input control={form.control.register('notes')} />
        </div>
      </form>
    </FormProvider>
  );
});
