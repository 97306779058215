import React from 'react';

export const SmallDraggableIcon = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='2' y='3' width='5' height='4' rx='1' fill='currentColor' />
    <rect x='9' y='3' width='5' height='4' rx='1' fill='currentColor' />
    <rect x='9' y='10' width='5' height='4' rx='1' fill='currentColor' />
    <rect x='2' y='10' width='5' height='4' rx='1' fill='currentColor' />
  </svg>
);
