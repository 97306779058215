import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { competenceAnalysisRecommendationsTypes } from '@/core/enums/competenceAnalysisRecommendationsTypesEnum';
import { colorTypes } from '@/styles/types';

export const Recommendations: React.FC = () => {
  const { t: competenceAnalysisTranslations } = useTranslation('competenceAnalysis', {
    keyPrefix: 'recommendations',
  });

  const options: IRadioButtonGroupItem[] = [
    {
      value: competenceAnalysisRecommendationsTypes.ActivityOriented,
      element: (
        <Typography>{competenceAnalysisTranslations('fields.isActivityOriented.label')}</Typography>
      ),
    },
    {
      value: competenceAnalysisRecommendationsTypes.OccupationalFieldOriented,
      element: (
        <Typography>
          {competenceAnalysisTranslations('fields.isOccupationalFieldOriented.label')}
        </Typography>
      ),
    },
    {
      value: competenceAnalysisRecommendationsTypes.WorkplaceOriented,
      element: (
        <Typography>
          {competenceAnalysisTranslations('fields.isWorkplaceOriented.label')}
        </Typography>
      ),
    },
    {
      value: competenceAnalysisRecommendationsTypes.JobProfileOriented,
      element: (
        <Typography>
          {competenceAnalysisTranslations('fields.isJobProfileOriented.label')}
        </Typography>
      ),
    },
  ];

  return (
    <Accordion
      title={<Typography type={'H3'}>{competenceAnalysisTranslations('title.label')}</Typography>}
      className={'flex flex-col gap-3'}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <Typography className={'ms-[30px]'} type={'16-M'}>
        {competenceAnalysisTranslations('subText.label')}
      </Typography>
      <RadioButtonGroup
        options={options}
        name={'recommendations'}
        className={'grid grid-cols-[max-content,max-content] grid-rows-2 gap-3 ms-[30px]'}
        itemClassName={'flex flex-row gap-3'}
      />
    </Accordion>
  );
};
