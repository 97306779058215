import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DefaultQuestionnaireDetails } from '@/components/Modals/Modals/functions/selfAssesmentModals/DefaultQuestionnaireDetails/DefaultQuestionnaireDetails';
import { Modal } from '@/components/Overlays/Modal/Modal';
import { QuestionnaireAnswerOptions } from '@/core/enums/functions/selfAssessment/questionnaireAnswersEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { simpleSelfAssesmentSelectors } from '@/core/redux/slices/functions/simpleSelfAssesment/selectors';
import {
  ISelfAssessmentAnswer,
  ISelfAssessmentQuestion,
  ISelfAssessmentQuestionnaire,
  simpleSelfAssesmentActions,
} from '@/core/redux/slices/functions/simpleSelfAssesment/simpleSelfAssesmentSlice';
import { selfAssesmentModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/selfAssesment/selector';
import { selfAssesmentModalsActions } from '@/core/redux/slices/modalsSlice/functions/selfAssesment/selfAssesmentModalsSlice';
import { parametersSelectors } from '@/core/redux/slices/parameters/selectors';

import { DefaultQuestionnaireItem } from './DefaultQuestionnaireItem/DefaultQuestionnaireItem';

const ModalContent: React.FC = () => {
  const m2sID = useAppSelector(selfAssesmentModalsSelectors.m2sID);
  const availableQuestionnaires = useAppSelector(
    simpleSelfAssesmentSelectors.availableQuestionnaires
  );
  const personQuestionnaireAnswers = useAppSelector(
    simpleSelfAssesmentSelectors.personQuestionnaireAnswers
  );
  const personQuestionnaire = useAppSelector(simpleSelfAssesmentSelectors.personQuestionnaire);
  const evenColor = useAppSelector(parametersSelectors.selfAssessmentEvenBackgroundColor);
  const oddColor = useAppSelector(parametersSelectors.selfAssessmentOddBackgroundColor);

  const [questionnaire, setQuestionnaire] = useState<ISelfAssessmentQuestionnaire | null>(null);
  const [answers, setAnswers] = useState<ISelfAssessmentAnswer[]>([]);

  const dispatch = useAppDispatch();

  const form = useForm();

  useEffect(() => {
    if (!m2sID) {
      return;
    }
    dispatch(
      simpleSelfAssesmentActions.fetchPersonQuestionnaireAnswers({
        variables: {
          m2sID,
        },
      })
    );
  }, [m2sID]);

  useEffect(() => {
    if (!personQuestionnaireAnswers) {
      return;
    }
    setAnswers(personQuestionnaireAnswers);
  }, [personQuestionnaireAnswers]);

  useEffect(() => {
    if (!m2sID || !availableQuestionnaires || !personQuestionnaireAnswers) {
      return;
    }
    dispatch(
      simpleSelfAssesmentActions.fetchPersonQuestionnaire({
        variables: {
          m2sID,
        },
        availableQuestionnaires,
        personQuestionnaireAnswers,
      })
    );
  }, [m2sID, availableQuestionnaires, personQuestionnaireAnswers]);

  useEffect(() => {
    if (!personQuestionnaire || !personQuestionnaire.questionnaire) {
      return;
    }
    setQuestionnaire(personQuestionnaire.questionnaire);
  }, [personQuestionnaire]);

  const handleExit = () => {
    dispatch(selfAssesmentModalsActions.closeDefaultQuestionnaireModal());
    personQuestionnaire &&
      dispatch(
        simpleSelfAssesmentActions.fetchPersonAnswers({
          variables: {
            maID: personQuestionnaire.personID,
          },
        })
      );
  };

  const handleAnswer = (
    question: ISelfAssessmentQuestion,
    answer: ISelfAssessmentAnswer | null,
    selectedOption: QuestionnaireAnswerOptions
  ) => {
    if (!question || !m2sID) {
      return;
    }
    if (answer?.answer && answer.answer === selectedOption) {
      return;
    }
    if (answer?.seaID) {
      dispatch(
        selfAssesmentModalsActions.updateSelfAssessmentAnswer({
          variables: {
            seaID: answer?.seaID,
            data: {
              antwort: selectedOption,
            },
          },
        })
      );
    } else {
      dispatch(
        selfAssesmentModalsActions.createSelfAssessmentAnswer({
          variables: {
            sefID: Number(question.sefID),
            answer: selectedOption,
            note: '',
          },
          m2sID: m2sID,
        })
      );
    }
  };

  return (
    <Modal className='w-[1200px]' title={questionnaire?.name} onExit={handleExit}>
      <div className='mb-5'>
        <DefaultQuestionnaireDetails
          personQuestionnaire={personQuestionnaire}
        ></DefaultQuestionnaireDetails>
      </div>
      <div className='max-h-[600px] overflow-x overflow-y-auto'>
        <FormProvider {...form}>
          <form>
            <table className='table-auto w-full min-w-max text-left'>
              <tbody>
                {questionnaire?.questions?.map((question, index) => {
                  const foundAnswer = answers.find(
                    (answer) => answer.m2sID === m2sID && answer.sefID === question?.sefID
                  );
                  return (
                    <DefaultQuestionnaireItem
                      key={question.sefID}
                      question={question}
                      answer={foundAnswer ? foundAnswer : null}
                      handleAnswer={handleAnswer}
                      bgColor={(index + 1) % 2 == 0 ? evenColor : oddColor}
                    ></DefaultQuestionnaireItem>
                  );
                })}
              </tbody>
            </table>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export const DefaultQuestionnaireModal: React.FC = () => {
  const { defaultQuestionnaireModal } = useAppSelector(
    selfAssesmentModalsSelectors.defaultQuestionnaireModalState
  );

  if (defaultQuestionnaireModal.isRender) {
    return <ModalContent />;
  }

  return null;
};
