import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMobilityForm } from '@/components/functions/Mobility/hooks/useMobilityForm';
import { MobilityFormTypes } from '@/core/enums/functions/workingTimeMobility/mobilityFormTypesEnum';
import { MobilityRefundVariant } from '@/core/enums/functions/workingTimeMobility/mobilityRefundVariantsEnum';
import { LoadingStatus } from '@/types/loadingStatus';

export type MobilityFormsContext = 'arrival' | 'departure';

export interface IMobilityUpdatePayload {
  formValues: IMobilityForm;
  personID: number;
}

export interface IMobilityFormType {
  id: number;
  name?: string | null;
  type: MobilityFormTypes;
}

export interface IMobilityType {
  type: IMobilityFormType;
  transportService?: ITransportTypeService | null;
  selfDrive?: ISelfDrive | null;
}

export interface ITransportTypeService {
  type: {
    id?: number;
    name?: string | null;
  } | null;
  contacts?: string | null;
  date?: string | null;
}

export interface ISelfDrive {
  refundVariant?: MobilityRefundVariant;
  kilometer?: number | null;
}

export interface IMobility {
  arrival: IMobilityType;
  departure: IMobilityType;
  availableTransportServices?: ITransportTypeService[] | null;
  availableMobilityTypes: {
    id: number;
    name?: string | null;
    type: MobilityFormTypes;
  }[];
}

export interface IMobilityFetchPayload {
  personID: number;
}

export interface IMobilityState {
  mobility: IMobility | null;
  mobilityLock: LoadingStatus;
}

const initialState: IMobilityState = {
  mobility: null,
  mobilityLock: LoadingStatus.NEVER,
};

export const mobilitySlice = createSlice({
  name: 'mobility',
  initialState,
  reducers: {
    updateMobility: (state, action: PayloadAction<IMobilityUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    fetchMobility: (state, action: PayloadAction<IMobilityFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setMobility: (state, action: PayloadAction<IMobility | null>) => ({
      ...state,
      mobility: action.payload,
    }),
    setMobilityLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      mobilityLock: action.payload,
    }),
  },
});

export const mobilityReducer = mobilitySlice.reducer;
export const mobilityActions = mobilitySlice.actions;
