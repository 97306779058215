import React from 'react';

import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';

import { GeneralData } from './GeneralData/GeneralData';
import { IndividualAuthorizations } from './IndividualAuthorizations/IndividualAuthorizations';

export const SpecialistGeneralData: React.FC = () => {
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const userID = selectedPerson?.id;

  return (
    <FunctionWrapper>
      <FunctionContent className={'px-5 pb-5'}>
        {userID && (
          <div className='flex flex-col gap-4'>
            <GeneralData userID={userID} />
            <IndividualAuthorizations />
          </div>
        )}
      </FunctionContent>
    </FunctionWrapper>
  );
};
