import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { Namespace, TFunction } from 'i18next';

import { Input } from '@/components/Input/Input';
import { SelectForm } from '@/components/Select/form/SelectForm';
import { TextArea } from '@/components/TextArea/TextArea';
import { Typography } from '@/components/Typography/Typography';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';

import { CheckboxGroup, CheckboxItem, CheckboxOption } from '../CheckboxItem/CheckboxItem';
import { DatePicker } from '../Datepicker/DatePicker';
import { ExternalSelectForm } from '../Select/form/ExternalSelectForm';
import { ISelectItem } from '../Select/SelectItem/SelectItem';

export interface FormItem {
  id?: string;
  name: string;
  type: string;
  label?: string;
  options?: any[];
  className?: string;
  tableName?: string;
  layout?: 'horizontal' | 'vertical';
  isReversed?: boolean;
}

interface Props {
  title?: string;
  className?: string;
  list: FormItem[];
  translationContext?: TFunction<Namespace, undefined>;
}

const FormRender: React.FC<Props> = ({ title, list, className, translationContext }) => {
  const { control } = useFormContext();

  return (
    <div className={clsx('flex flex-col gap-4')}>
      {title && (
        <div className={`mb-5 border-b border-b-${colorTypes.StrokeLight} pb-2.5`}>
          <Typography type='H3'>{title}</Typography>
        </div>
      )}

      <div className={twMerge('flex flex-wrap gap-y-6', className)}>
        {list.map((item) => {
          const {
            name,
            type,
            options,
            tableName,
            label,
            className,
            layout = 'horizontal',
            isReversed,
          } = item;
          return (
            <div className={clsx(twMerge('w-full flex flex-col gap-2', className))} key={name}>
              {label && (
                <Typography as='label' htmlFor={name} className='truncate h-6'>
                  {translationContext ? translationContext(label as any) : label}
                </Typography>
              )}
              <div className={twMerge(isReversed && '-order-1')}>
                {(() => {
                  switch (type) {
                    case 'input':
                      return <Input control={control.register(name)}/>;
                    case 'number':
                      return <Input control={control.register(name)} type='number'/>;
                    case 'textarea':
                      return <TextArea control={control.register(name)} />;
                    case 'select':
                      const selectOptions: ISelectItem[] | undefined = options?.map(
                        (item): ISelectItem => ({
                          ...item,
                          component: item.name,
                        })
                      );

                      return (
                        <SelectForm
                          values={selectOptions}
                          control={control.register(name)}
                          className='w-full'
                          isFlexible
                        />
                      );
                    case 'external-select':
                      return (
                        <ExternalSelectForm
                          tableName={tableName}
                          control={control.register(name)}
                          className='w-full'
                          isFlexible
                        />
                      );
                    case 'datepicker':
                      return <DatePicker control={control.register(name)} />;
                    case 'checkbox':
                      return <CheckboxItem fieldName={name} label={undefined} />;
                    case 'checkbox_group':
                      const translatedOptions = options?.map((item) => ({
                        ...item,
                        name: translationContext ? translationContext(item.name as any) : item.name,
                      }));

                      return (
                        <CheckboxGroup
                          name={name}
                          options={translatedOptions as CheckboxOption[]}
                          layout={layout}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FormRender;
