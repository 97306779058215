import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { workingTimeFormValidatorBuilder } from '@/components/functions/WorkingTime/helpers/workingTimeFormValidator';
import { WorkingTimeContainer } from '@/components/functions/WorkingTime/WorkingTimeContainer/WorkingTimeContainer';
import { WorkingTimeContainerFooter } from '@/components/functions/WorkingTime/WorkingTimeContainer/WorkingTimeContainerFooter/WorkingTimeContainerFooter';
import { Skeleton } from '@/components/Skeleton/Skeleton';
import { Typography } from '@/components/Typography/Typography';
import { WorkingTimePlanner } from '@/components/WorkingTimePlanner/WorkingTimePlanner';
import { FunctionContent } from '@/components/wrapper/FunctionWrapper/FunctionContent/FunctionContent';
import { FunctionFooter } from '@/components/wrapper/FunctionWrapper/FunctionFooter/FunctionFooter';
import { FunctionWrapper } from '@/components/wrapper/FunctionWrapper/FunctionWrapper';
import { WorkingTimeType } from '@/core/enums/functions/workingTimeMobility/employmentTypesEnum';
import { useSelectedPerson } from '@/core/hooks/useSelectedPerson';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { workingTimeSelectors } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/selectors';
import {
  IWorkingTime,
  workingTimeActions,
} from '@/core/redux/slices/functions/workingTimeMobility/workingTime/slice';
import { LoadingStatus } from '@/types/loadingStatus';

export const WorkingTime = () => {
  const { t: workingTimeTranslations } = useTranslation('workingTime');

  const workingTime = useAppSelector(workingTimeSelectors.workingTime);
  const workingTimeDaysList = useAppSelector(workingTimeSelectors.workingTimeDaysList);
  const workingTimeLock = useAppSelector(workingTimeSelectors.workingTimeLock);

  const updatedWorkingTime = useAppSelector(workingTimeSelectors.updatedWorkingTime);

  const { selectedPersonID } = useSelectedPerson();

  const dispatch = useAppDispatch();

  const form = useForm<IWorkingTime>({
    defaultValues: {
      appliesFromTimeStamp: '',
      workingTimeType: WorkingTimeType.Default,
      partTimeReasonType: null,
      otherPartTimeReasonText: '',
      workingWeek: {
        sumNet: null,
        workingDaysCount: null,
        sumGross: null,
        sumPauseDuration: null,
        workingDays: {},
      },
    },
    resolver: yupResolver(workingTimeFormValidatorBuilder(workingTimeTranslations)),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (workingTime && !updatedWorkingTime) {
      form.reset(workingTime, { keepDirty: false });
      return;
    }

    if (updatedWorkingTime) {
      form.setValue('workingWeek', updatedWorkingTime.workingWeek, { shouldDirty: true });
      form.setValue('appliesFromTimeStamp', updatedWorkingTime.appliesFromTimeStamp, {
        shouldDirty: true,
      });
      form.setValue('workingTimeType', WorkingTimeType.Default);
    }
  }, [workingTime, updatedWorkingTime]);

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(workingTimeActions.fetchWorkingTime({ personID: selectedPersonID }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(workingTimeActions.setUpdatedWorkingTime(null));
    };
  }, []);

  const handleSubmit = (data: IWorkingTime) => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(
      workingTimeActions.updateWorkingTime({
        personID: selectedPersonID,
        workingTime: data,
      })
    );
  };

  return (
    <FunctionWrapper>
      <FormProvider {...form}>
        <FunctionContent className={'p-5'} withFooter={true}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Skeleton trigger={workingTimeLock === LoadingStatus.LOADING}>
              <Typography type={'H3'}>{workingTimeTranslations('title.label')}</Typography>
              <div className={'grid grid-cols-12 gap-7'}>
                <WorkingTimePlanner
                  className={'col-span-7'}
                  workingTimeDaysList={workingTimeDaysList}
                />
                <WorkingTimeContainer className={'col-span-5'} />
              </div>
            </Skeleton>
          </form>
        </FunctionContent>
        <FunctionFooter>
          <WorkingTimeContainerFooter />
        </FunctionFooter>
      </FormProvider>
    </FunctionWrapper>
  );
};
