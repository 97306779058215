import React, { useRef } from 'react';
import clsx from 'clsx';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import {
  IHorizontalScrollableContainer,
  ScrollDirection,
} from '@/types/horizontalScrollableContainer';

import styles from './styles.module.scss';

const SCROLL_STEP = 250;

export const HorizontalScrollableContainer: React.FC<IHorizontalScrollableContainer> = ({
  children,
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleScroll = (direction: ScrollDirection) => {
    if (contentRef.current) {
      const scrollStep = direction === ScrollDirection.right ? SCROLL_STEP : -SCROLL_STEP;
      contentRef.current.scrollBy({
        left: scrollStep,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={clsx('flex items-center relative z-10', className)}>
      <Button
        className='absolute left-0 rotate-180 w-[40px] px-0 py-0'
        onClick={() => handleScroll(ScrollDirection.left)}
        buttonVariant={'Default'}
        buttonStyle='Circle'
      >
        <ArrowRightIcon />
      </Button>
      <div
        ref={contentRef}
        className={clsx(styles.content, 'flex overflow-hidden ms-[50px] me-[30px]')}
      >
        {children}
      </div>
      <Button
        className='absolute right-0 w-[40px] px-0 py-0'
        onClick={() => handleScroll(ScrollDirection.right)}
        buttonVariant={'Default'}
        buttonStyle='Circle'
      >
        <ArrowRightIcon />
      </Button>
    </div>
  );
};
