import React from 'react';

import { WorkingTimePlannerContainerDaysList } from '@/components/WorkingTimePlanner/WorkingTimePlannerContainer/WorkingTimePlannerContainerDaysList/WorkingTimePlannerContainerDaysList';
import { WorkingTimePlannerContainerHeader } from '@/components/WorkingTimePlanner/WorkingTimePlannerContainer/WorkingTimePlannerContainerHeader/WorkingTimePlannerContainerHeader';
import { useAppSelector } from '@/core/redux/hooks';
import { workingTimeSelectors } from '@/core/redux/slices/functions/workingTimeMobility/workingTime/selectors';
import { workingTimeMobilityModalsSelectors } from '@/core/redux/slices/modalsSlice/functions/workingTimeMobility/selectors';

interface IWorkingTimePlannerContainer {
  context: 'function' | 'modal';
}

export const WorkingTimePlannerContainer: React.FC<IWorkingTimePlannerContainer> = ({
  context,
}) => {
  const workingTimeDaysList = useAppSelector(
    context === 'function'
      ? workingTimeSelectors.workingTimeDaysList
      : workingTimeMobilityModalsSelectors.workingTimeDaysListModal
  );

  return (
    <div className={'grid grid-cols-6 justify-items-center gap-5'}>
      <WorkingTimePlannerContainerHeader />
      <WorkingTimePlannerContainerDaysList workingTimeDaysList={workingTimeDaysList} />
    </div>
  );
};
