import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadingStatus } from '@/types/loadingStatus';

export interface IGeneralData {
  name?: string | null;
  surname?: string | null;
  profession?: string | null;
  gender?: number;
  email?: string | null;
  id: number;
  salutation?: number;
  roles: { [key: number]: boolean };
  target_catalog_types: { [key: number]: boolean };
  is_social_service_worker: boolean;
  is_educational_supporter: boolean;
  is_group_manager: boolean;
  is_internship_supervisor: boolean;
  telephone_number?: string | null;
  cell_phone_number?: string | null;
  is_specialist?: boolean | null;
  is_jobcoach?: boolean | null;
  is_manager?: boolean | null;
}

export interface IAdminister {
  id: number;
  administer: string;
}

export interface IAdministerFetchPayload {
  date: string | null;
}

export interface IGeneralDataFetchPayload {
  userID: number;
}

export interface IPersonDataUpdatePayload {
  generalData: IGeneralData;
  userID: number;
}

export interface ISpecialistGeneralDataState {
  generalData: IGeneralData | null;
  generalDataLock: LoadingStatus;
  administers: IAdminister[];
  administersLock: LoadingStatus;
}

const initialState: ISpecialistGeneralDataState = {
  generalData: null,
  generalDataLock: LoadingStatus.NEVER,
  administers: [],
  administersLock: LoadingStatus.NEVER,
};

export const specialistGeneralDataSlice = createSlice({
  name: 'specialistGeneralData',
  initialState,
  reducers: {
    //general data
    fetchGeneralData: (state, action: PayloadAction<IGeneralDataFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    updateGeneralData: (state, action: PayloadAction<IPersonDataUpdatePayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setGeneralDataLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      generalDataLock: action.payload,
    }),
    setGeneralData: (state, action: PayloadAction<IGeneralData | null>) => ({
      ...state,
      generalData: action.payload ?? null,
    }),
    //administer
    fetchAdministers: (state, action: PayloadAction<IAdministerFetchPayload>) => ({
      ...state,
      payload: action.payload,
    }),
    setAdministers: (state, action: PayloadAction<IAdminister[]>) => ({
      ...state,
      administers: action.payload,
    }),
    setAdministersLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      administersLock: action.payload,
    }),
  },
});

export const specialistGeneralDataReducers = specialistGeneralDataSlice.reducer;
export const specialistGeneralDataActions = specialistGeneralDataSlice.actions;
