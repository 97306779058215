import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { formatOptionsToSchema } from '@/core/utils/commonUtils';
import { toBackendDate } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IAdministerFetchPayload,
  IGeneralData,
  IGeneralDataFetchPayload,
  specialistGeneralDataActions,
} from './specialistGeneralDataSlice';

type GeneralDataResponse = OASOutput<NormalizeOAS<typeof oas>, '/user/general_data', 'get', '200'>;
type AdministerResponse = OASOutput<NormalizeOAS<typeof oas>, '/user/administer', 'get', '200'>;

function* fetchGeneralData(
  action: PayloadAction<IGeneralDataFetchPayload>
): Generator<any, void, GeneralDataResponse> {
  yield put(specialistGeneralDataActions.setGeneralDataLock(LoadingStatus.LOADING));

  const { userID } = action.payload;

  try {
    const response = yield call(restCall, '/user/general_data', 'get', {
      query: {
        user_id: userID,
      },
      ...authAdd(),
    });
    const { general_data } = response;

    const generalData: IGeneralData = {
      ...general_data,
      gender: general_data.gender?.id,
      salutation: general_data.salutation?.id,
      roles: formatOptionsToSchema(general_data.roles),
      target_catalog_types: formatOptionsToSchema(general_data.target_catalog_types),
    };

    yield put(specialistGeneralDataActions.setGeneralData(generalData));

    yield put(specialistGeneralDataActions.setGeneralDataLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on specialist general data fetching');
    yield put(specialistGeneralDataActions.setGeneralDataLock(LoadingStatus.ERROR));
  }
}

function* fetchAdministers(
  action: PayloadAction<IAdministerFetchPayload>
): Generator<any, void, AdministerResponse> {
  yield put(specialistGeneralDataActions.setAdministersLock(LoadingStatus.LOADING));

  const { date } = action.payload;

  try {
    const response = yield call(restCall, '/user/administer', 'get', {
      query: {
        date: toBackendDate(date),
      },
      ...authAdd(),
    });
    const { administers } = response;

    yield put(specialistGeneralDataActions.setAdministers(administers));

    yield put(specialistGeneralDataActions.setAdministersLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on specialist general data fetching');
    yield put(specialistGeneralDataActions.setAdministersLock(LoadingStatus.ERROR));
  }
}

export const specialistGeneralDataSagas = [
  takeLatest(specialistGeneralDataActions.fetchGeneralData, fetchGeneralData),
  takeLatest(specialistGeneralDataActions.fetchAdministers, fetchAdministers),
];
