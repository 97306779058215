import { PayloadAction } from '@reduxjs/toolkit';
import { put, take, takeLeading } from 'redux-saga/effects';

import { Parameter } from '@/core/enums/parametersEnum';
import { appActions, IAppState } from '@/core/redux/slices/app/appSlice';
import { authActions } from '@/core/redux/slices/auth/authSlice';
import { userSettingsActions } from '@/core/redux/slices/userSettings/userSettingsSlice';

import { parametersActions } from '../parameters/parametersSlice';

function* setInitialization(action: PayloadAction<IAppState | null>): any {
  const accessToken = action.payload?.accessToken;
  if (accessToken) {
    yield put(authActions.setAToken(accessToken));
    yield put(authActions.fetchSelfProfile());
    yield put(userSettingsActions.fetchUserSettings());
    yield put(
      parametersActions.fetchParameters([
        Parameter.TableEvenBackground,
        Parameter.TableOddBackground,
        Parameter.SelfAssessmentEvenBackgroundColor,
        Parameter.SelfAssessmentOddBackgroundColor,
        Parameter.InternalDifferentiationItemEvenBackground,
        Parameter.InternalDifferentiationItemOddBackground,
        Parameter.VocationalTrainingAreaEvenItemBackgroundColor,
        Parameter.VocationalTrainingAreaOddItemBackgroundColor,
        Parameter.AdditionalPersonRequirementConfirmCreatingText,
        Parameter.AdditionalPersonRequirementResponsibleLeaderRequired,
      ])
    );
    yield take(authActions.setUser);
  }
  yield put(appActions.setAppInitializationSuccess({ isAppInitializationInProgress: false }));
}

export const appSagas = [takeLeading(appActions.setAppInitialization, setInitialization)];
