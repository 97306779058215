import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { ArrowRightIcon } from '@/components/Icons/ArrowRightIcon';
import { Typography } from '@/components/Typography/Typography';
import { colorTypes } from '@/styles/types';

interface IQuestionnaireNavigation {
  navigateToQuestionnaires: () => void;
}

export const QuestionnaireNavigation: React.FC<IQuestionnaireNavigation> = ({
  navigateToQuestionnaires,
}) => {
  const { t: questionnaireTranslations } = useTranslation('questionnaire', {
    keyPrefix: 'buttons',
  });

  return (
    <Button
      onClick={navigateToQuestionnaires}
      buttonStyle={'WithIcon'}
      buttonVariant={'Transparent'}
      className={'w-fit gap-2'}
    >
      <ArrowRightIcon className={`text-${colorTypes.Primary} rotate-180`} />
      <Typography type={'14-M'} color={'Secondary'}>
        {questionnaireTranslations('backToQuestionnaires.label')}
      </Typography>
    </Button>
  );
};
