import React, { useMemo, useState } from 'react';

import { Button } from '@/components/Button/Button';
import { Typography } from '@/components/Typography/Typography';
import { textTypes } from '@/styles/types';

import { CostCenters } from './CostCenters/CostCenters';
import { OrganizationUnits } from './OrganizationUnits/OrganizationUnits';

enum PermissionsTabs {
  CostCenters = 'costCenters',
  OrganizationUnits = 'organizationUnits',
}

interface IPermissionsViews {
  title: string;
  view: React.ReactNode;
  type: PermissionsTabs;
}

export const Permissions: React.FC = () => {
  const [activeTab, setActiveTab] = useState<PermissionsTabs>(PermissionsTabs.CostCenters);

  const views: Record<PermissionsTabs, IPermissionsViews> = useMemo(
    () => ({
      [PermissionsTabs.CostCenters]: {
        title: 'Cost Centers',
        view: <CostCenters />,
        type: PermissionsTabs.CostCenters,
      },
      [PermissionsTabs.OrganizationUnits]: {
        title: 'Organization Units',
        view: <OrganizationUnits />,
        type: PermissionsTabs.OrganizationUnits,
      },
    }),
    []
  );

  const handleTabClick = (activeTabType: PermissionsTabs) => {
    setActiveTab(activeTabType);
  };

  return (
    <div className='flex flex-col gap-4'>
      <Typography type='H3'>Permissions</Typography>
      <div className='flex gap-2'>
        {Object.values(views).map((item) => (
          <Button
            buttonVariant={activeTab === item.type ? 'Default' : 'SelectedPerson'}
            onClick={() => handleTabClick(item.type)}
            key={item.type}
            className={`${textTypes['12-R']}`}
          >
            {item.title}
          </Button>
        ))}
      </div>
      <div>{views[activeTab].view}</div>
    </div>
  );
};
