import React, { useRef, useState } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button/Button';
import { CrossIcon } from '@/components/Icons/CrossIcon';
import { SpinnerIcon } from '@/components/Icons/SpinnerIcon';
import { Typography } from '@/components/Typography/Typography';
import { uploadZoneVariants } from '@/components/UploadFileZone/helpers/uploadZoneVariants';
import { twMerge } from '@/core/utils/tailwindUtil';
import { colorTypes } from '@/styles/types';
import { LoadingStatus } from '@/types/loadingStatus';

interface IUploadFileZone {
  control: UseControllerProps;
  handleUpload?: (uploadedFile: UploadFileFormField | null) => void;
  singleFile?: boolean;
  loadingStatus?: LoadingStatus;
  className?: string;
}

export type UploadFileFormField = {
  fileName?: string;
  filesCount?: number;
  fileLink?: string;
  files?: FileList;
  uploadStatus?: LoadingStatus;
};

export const UploadFileZone: React.FC<IUploadFileZone> = ({
  control,
  handleUpload,
  className,
  loadingStatus,
  singleFile = true,
}) => {
  const { t: uploadZoneTranslations } = useTranslation('uploadZone');

  const {
    field: { onChange: controllerOnChange, value },
    fieldState: { error, invalid },
  } = useController(control);

  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const isLoading = loadingStatus === LoadingStatus.LOADING;
  const isError = loadingStatus === LoadingStatus.ERROR || invalid;
  const isSuccess = loadingStatus === LoadingStatus.LOADED;

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (files: FileList | null) => {
    if (!files || files.length < 1) {
      return;
    }

    controllerOnChange({
      files: files,
      fileName: files[0].name,
      filesCount: files.length,
    });

    handleUpload?.(value);

    setIsDragOver(false);
  };

  const handleInputSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.files);
  };

  const handleFileDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    handleChange(event.dataTransfer.files);
  };

  const handleClick = () => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setIsDragOver(true);
    }
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setIsDragOver(false);
    }
  };

  const handleResetFile = () => {
    controllerOnChange(null);
    handleUpload?.(null);

    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = '';
  };

  const getDisplayMessage = (
    isError: boolean,
    singleFile: boolean,
    fileName: string | undefined,
    filesCount: number | undefined
  ) => {
    if (isError) {
      return error?.message || uploadZoneTranslations('errorMessage.label');
    }

    if (singleFile) {
      return fileName || uploadZoneTranslations('description.label');
    }

    if (filesCount) {
      return uploadZoneTranslations('multipleFiles.label', { count: filesCount });
    }

    return uploadZoneTranslations('description.label');
  };

  return (
    <div
      className={twMerge(
        uploadZoneVariants.Default,
        className,
        isDragOver && uploadZoneVariants.DragOver,
        isLoading && uploadZoneVariants.Loading,
        isError && uploadZoneVariants.Error,
        isSuccess && uploadZoneVariants.Success
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <input
        type={'file'}
        className={'hidden'}
        multiple={!singleFile}
        onChange={handleInputSelect}
        ref={inputRef}
      />
      <div className={'flex flex-row justify-between items-center gap-3'}>
        <div className={'flex flex-row gap-2 justify-start items-center max-w-[400px]'}>
          <div className='truncate'>
            <Typography>
              {getDisplayMessage(isError, singleFile, value?.fileName, value?.filesCount)}
            </Typography>
          </div>
          {(value?.fileName || value?.filesCount) && (
            <Button buttonVariant={'Icon'} buttonStyle={'Icon'} onClick={handleResetFile}>
              <CrossIcon className={`text-${colorTypes.Primary}`} />
            </Button>
          )}
        </div>
        <Button onClick={handleClick} className={'shrink-0'}>
          {uploadZoneTranslations('buttons.chooseFile.label')}
        </Button>
      </div>
      <div
        className={twMerge(isDragOver ? 'absolute inset-0 z-30' : 'hidden')}
        onDrop={handleFileDrop}
      />
      {isLoading && (
        <div className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'}>
          <SpinnerIcon className={`text-${colorTypes.Blue}`} />
        </div>
      )}
    </div>
  );
};
