import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Dropdown } from '@/components/Dropdown/Dropdown';
import { UnionIcon } from '@/components/Icons/UnionIcon';
import { useAppDispatch } from '@/core/redux/hooks';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';

export interface ICopyPopup {
  clipboard: any[];
  rowData: any;
}

export interface IClipboard {
  label: string;
  copyTemplate: (rowData: any) => string | null | undefined;
}

export const CopyPopup: React.FC<ICopyPopup> = ({ clipboard, rowData }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const copyToClipBoard = async (copyString: string) => {
    try {
      await navigator.clipboard.writeText(copyString);
      dispatch(
        notificationsActions.showNotification({
          notification: {
            type: 'success',
            title: 'Information',
            description: 'Copied',
          },
        })
      );
      setExpanded(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>, copyString: string) => {
    e.stopPropagation();
    copyToClipBoard(copyString);
  };

  const renderDropDownContent = useCallback(
    () => (
      <div className={'bg-white p-3 flex flex-col items-start justify-start'}>
        {clipboard.map((item: IClipboard) => {
          const copyString = item.copyTemplate(rowData);

          if (!copyString) return;

          return (
            <div
              key={item.label}
              className='cursor-pointer w-full flex flex-row items-center justify-between hover:bg-fields p-2 rounded-default'
              onClick={(e) => handleClick(e, copyString)}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    ),
    [clipboard, rowData]
  );

  return (
    <Dropdown
      position={'bottom-start'}
      contentOffset={{ crossAxis: 45, mainAxis: 18 }}
      content={renderDropDownContent()}
      open={expanded}
      setOpen={setExpanded}
      floatClassName='rounded-default bg-white overflow-hidden'
    >
      <div className={twMerge('p-3')}>
        <UnionIcon />
      </div>
    </Dropdown>
  );
};
