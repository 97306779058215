import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@/components/Typography/Typography';

export const TableHeader: React.FC = () => {
  const { t: internshipDocumentationTranslations } = useTranslation(
    'internshipDocumentationModal',
    { keyPrefix: 'tableHeader' }
  );

  return (
    <div className={'grid grid-cols-12 gap-4 items-center justify-items-center px-4'}>
      <Typography className={'col-span-5 justify-self-start'} color={'Secondary'} type={'14-M'}>
        {internshipDocumentationTranslations('internship.label')}
      </Typography>
      <Typography className={'col-span-2 justify-self-start'} color={'Secondary'} type={'14-M'}>
        {internshipDocumentationTranslations('place.label')}
      </Typography>
      <Typography className={'col-span-2'} color={'Secondary'} type={'14-M'}>
        {internshipDocumentationTranslations('dateFrom.label')}
      </Typography>
      <Typography className={'col-span-2'} color={'Secondary'} type={'14-M'}>
        {internshipDocumentationTranslations('dateTo.label')}
      </Typography>
      <Typography className={'col-span-1'} color={'Secondary'} type={'14-M'}>
        {internshipDocumentationTranslations('adopt.label')}
      </Typography>
    </div>
  );
};
