import React from 'react';
import clsx from 'clsx';

import { IRadioButtonGroupState } from '@/components/FormRadioButtonGroup/FormRadioButtonGroup';
import { RadioButtonCheckedIcon } from '@/components/Icons/RadioButtonCheckedIcon';
import { RadioButtonUncheckedIcon } from '@/components/Icons/RadioButtonUncheckedIcon';
import { twMerge } from '@/core/utils/tailwindUtil';

export interface IFormRadioButton extends IRadioButtonGroupState {
  id: number | string;
  value: any;
  onChange?: (value: any) => void;
  isSelected?: boolean;
  readOnly?: boolean;
  className?: string;
}

export const FormRadioButton: React.FC<IFormRadioButton> = ({
  id,
  value,
  onChange,
  isSelected,
  readOnly,
  className,
}) => {
  const handleChange = () => {
    onChange?.(value);
  };

  return (
    <div
      className={twMerge(
        'relative cursor-pointer w-[24px] h-[24px] flex flex-row gap-3 items-center justify-center',
        className
      )}
      onClick={handleChange}
    >
      <input
        type={'radio'}
        value={value}
        className={'appearance-none'}
        id={String(id)}
        readOnly={readOnly}
      />
      <div
        className={clsx(
          'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10',
          isSelected ? 'text-blue hover:text-grey' : 'text-grey hover:text-blue'
        )}
      >
        {isSelected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
      </div>
    </div>
  );
};
