import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Input } from '@/components/Input/Input';
import {
  IRadioButtonGroupItem,
  RadioButtonGroup,
} from '@/components/RadioButtonGroup/RadioButtonGroup';
import { Typography } from '@/components/Typography/Typography';
import { participantExpectationFunctionsTypes } from '@/core/enums/participantExpectationFunctionsEnum';
import { IParticipantExpectation } from '@/core/redux/slices/functions/integrationPlan/participantExpectation/participantExpectationSlice';

export const Header: React.FC = () => {
  const { t: auxiliaryFunctionsTranslations } = useTranslation('participantExpectation', {
    keyPrefix: 'header',
  });

  const { control, watch } = useFormContext<IParticipantExpectation>();
  const selectedType = watch('creationReason');

  const options: IRadioButtonGroupItem[] = [
    {
      value: participantExpectationFunctionsTypes.isRecord,
      element: <Typography>{auxiliaryFunctionsTranslations('fields.isRecord.label')}</Typography>,
    },
    {
      value: participantExpectationFunctionsTypes.isEndeBBB,
      element: <Typography>{auxiliaryFunctionsTranslations('fields.isEndeBBB.label')}</Typography>,
    },
    {
      value: participantExpectationFunctionsTypes.isIndividualAppointment,
      element: (
        <div className={'grid grid-cols-[auto,auto] gap-x-4 w-full items-center'}>
          <Typography>
            {auxiliaryFunctionsTranslations('fields.isIndividualAppointment.label')}
          </Typography>
          <div className={'flex flex-row gap-4 items-center'}>
            <Typography>
              {auxiliaryFunctionsTranslations('fields.individualAppointmentReason.label')}
            </Typography>
            <Input
              control={control.register('individualAppointmentReason')}
              readOnly={
                selectedType !== participantExpectationFunctionsTypes.isIndividualAppointment
              }
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={'flex flex-col flex-grow'}>
      <Typography type={'H3'}>{auxiliaryFunctionsTranslations('title.label')}</Typography>
      <RadioButtonGroup
        options={options}
        name={'creationReason'}
        className={
          'grid grid-cols-[max-content,max-content] gap-x-4 gap-y-2 justify-items-start items-center mt-4'
        }
        itemClassName={'flex flex-row gap-3 items-center last:col-span-2 last:w-full'}
      />
    </div>
  );
};
