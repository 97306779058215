import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import fp from 'lodash/fp';

import { ActivityPlanningFullFunction } from '@/components/functions/ActivityPlanning/FullFunction/ActivityPlanningFullFunction';
import { ActivityPlanningWizardVersion } from '@/components/functions/ActivityPlanning/WizardFunction/ActivityPlanningWizardVersion';
import { AdditionalPersonRequirement } from '@/components/functions/AdditionalPersonRequirement/AdditionalPersonRequirement';
import { Diagnostics } from '@/components/functions/Diagnostics/Diagnostics';
import { IntegrationPlan } from '@/components/functions/IntegrationPlan/IntegrationPlan';
import { LegalGuardian } from '@/components/functions/LegalGuardian/LegalGuardian';
import { Medication } from '@/components/functions/Medication/Medication';
import { Mobility } from '@/components/functions/Mobility/Mobility';
import { OrganizationalUnits } from '@/components/functions/OrganizationalUnits/OrganizationalUnits';
import { PerformanceProfile } from '@/components/functions/PerformanceProfile/PerformanceProfile';
import { PersonGeneralData } from '@/components/functions/PersonGeneralData/PersonGeneralData';
import { SelfAssesment } from '@/components/functions/SelfAssesment/SelfAssesment';
import { SpecialistGeneralData } from '@/components/functions/SpecialistGeneralData/SpecialistGeneralData';
import { TestingResult } from '@/components/functions/TestingResult/TestingResult';
import { VocationalTrainingArea } from '@/components/functions/VocationalTrainingArea/VocationalTrainingArea';
import { WheelView } from '@/components/functions/WheelView/WheelView';
import { WorkingTime } from '@/components/functions/WorkingTime/WorkingTime';
import { HorizontalScrollableContainer } from '@/components/HorizontalScrollableContainer/HorizontalScrollableContainer';
import { Typography } from '@/components/Typography/Typography';
import { FunctionSelectedPerson } from '@/components/views/functions/FunctionContainer/FunctionSelectedPerson/FunctionSelectedPerson';
import { FunctionViews } from '@/core/enums/functionViewsEnum';
import { useAppSelector } from '@/core/redux/hooks';
import { IAddTabFunctionItem } from '@/core/redux/slices/functionPage/functionPageSlice';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { FunctionTypes } from '@/types/functions';

import { FunctionSelect } from './FunctionSelect/FunctionSelect';
import { FunctionTabs } from './FunctionTabs/FunctionTabs';

import styles from './styles.module.scss';

interface IFunctionView {
  id: string;
  type: FunctionViews;
  fullView: JSX.Element;
  wizardView?: JSX.Element;
}

export const functionViews: Record<string, IFunctionView> = {
  12: {
    id: '12',
    fullView: <IntegrationPlan />,
    type: FunctionViews.IntegrationPlan,
  },
  13: {
    id: '13',
    fullView: <SelfAssesment />,
    type: FunctionViews.SelfAssesment,
  },
  14: {
    id: '14',
    fullView: <PerformanceProfile />,
    type: FunctionViews.PerformanceProfile,
  },
  15: {
    id: '15',
    fullView: <PersonGeneralData />,
    type: FunctionViews.PersonGeneralData,
  },
  16: {
    id: '16',
    fullView: <Diagnostics />,
    type: FunctionViews.Diagnostics,
  },
  18: {
    id: '18',
    fullView: <TestingResult />,
    type: FunctionViews.ResearchResult,
  },
  17: {
    id: '17',
    fullView: <WheelView />,
    type: FunctionViews.WheelView,
  },
  20: {
    id: '20',
    fullView: <LegalGuardian />,
    type: FunctionViews.LegalGuardian,
  },
  22: {
    id: '22',
    fullView: <SpecialistGeneralData />,
    type: FunctionViews.SpecialistGeneralData,
  },
  23: {
    id: '23',
    fullView: <VocationalTrainingArea />,
    type: FunctionViews.SpecialistGeneralData,
  },
  24: {
    id: '24',
    fullView: <Medication />,
    type: FunctionViews.Medication,
  },
  26: {
    id: '26',
    fullView: <ActivityPlanningFullFunction />,
    wizardView: <ActivityPlanningWizardVersion />,
    type: FunctionViews.ActivityPlanning,
  },
  27: {
    id: '27',
    fullView: <WorkingTime />,
    type: FunctionViews.WorkingTime,
  },
  28: {
    id: '28',
    fullView: <Mobility />,
    type: FunctionViews.Mobility,
  },
  29: {
    id: '29',
    fullView: <OrganizationalUnits />,
    type: FunctionViews.OrganizationalUnits,
  },
  30: {
    id: '30',
    fullView: <AdditionalPersonRequirement />,
    type: FunctionViews.AdditionalPersonRequirement,
  },
};

export const FunctionContainer: React.FC = () => {
  const { t: functionsPageTranslations } = useTranslation('functionsPage');

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const activeTabId = useAppSelector(functionPageSelectors.activeTabId);
  const tabs = useAppSelector(functionPageSelectors.tabs);

  const [isFunctionSelected, setIsFunctionSelected] = useState<boolean>(false);

  const activeFunctionItem: IAddTabFunctionItem | null | undefined | '' = useMemo(
    () => selectedPerson && activeTabId && tabs[selectedPerson.id]?.[activeTabId]?.functionItem,
    [selectedPerson, activeTabId, tabs]
  );

  const selectedId: string | null | undefined = useMemo(
    () => activeFunctionItem && activeFunctionItem.id,
    [activeFunctionItem]
  );
  const selectedView: IFunctionView | undefined = useMemo(
    () => functionViews[selectedId ?? ''],
    [selectedId, functionViews]
  );

  const renderComponent = () => {
    const renderError = () => (
      <div className='p-5 flex justify-center'>
        <Typography>
          {functionsPageTranslations('placeholders.functionNotImplemented.label')}
        </Typography>
      </div>
    );

    if (!activeFunctionItem || !selectedId || !functionViews[selectedId]) {
      return renderError();
    }

    const { functionType } = activeFunctionItem;

    if (functionType === FunctionTypes.wizard) {
      return selectedView.wizardView ?? selectedView.fullView;
    }

    if (
      !functionType ||
      functionType === FunctionTypes.full ||
      functionType === FunctionTypes.none
    ) {
      return selectedView.fullView;
    }

    return renderError();
  };

  useEffect(() => {
    if (selectedPerson && activeTabId) {
      setIsFunctionSelected(!fp.isEmpty(tabs[selectedPerson.id]?.[activeTabId]?.functionItem));
    }
  }, [tabs, selectedPerson, activeTabId]);

  return (
    <div className={clsx('overflow-y-hidden w-full flex flex-col', styles.container)} id={'test'}>
      <div className='flex justify-between ms-7 pr-7 overflow-hidden'>
        <Typography type='H2'>{functionsPageTranslations('title.label')}</Typography>
        <FunctionSelectedPerson selectedPerson={selectedPerson} />
      </div>
      <HorizontalScrollableContainer className='mx-[10px] mt-[30px] h-[47px]'>
        <FunctionTabs />
      </HorizontalScrollableContainer>
      <div>
        {isFunctionSelected && activeTabId && renderComponent()}
        {!isFunctionSelected && activeTabId && <FunctionSelect />}
        {!isFunctionSelected && !activeTabId && (
          <div className='p-5 flex justify-center'>
            <Typography>
              {functionsPageTranslations('placeholders.selectFunction.label')}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
