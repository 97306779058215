import { boolean, ObjectSchema, string } from 'yup';

import { ICompetenceAnalysis } from '@/core/redux/slices/functions/integrationPlan/competenceAnalysis/competenceAnalysisSlice';
import { yup } from '@/core/utils/commonUtils';

const requiredByCheck = (checkFieldName: string) =>
  string()
    .optional()
    .when(checkFieldName, {
      is: true,
      then: () => string().required('must be filled'),
      otherwise: () => string().optional().nullable(),
    });

export const competenceAnalysisResolver: ObjectSchema<ICompetenceAnalysis> = yup.object().shape({
  melba: boolean().defined(),
  melbaSl: boolean().defined(),
  mast: boolean().defined(),
  pac: boolean().defined(),
  detmolderLearningPathModel: boolean().defined(),
  isOwnTestProcedure: boolean().defined(),
  ida: boolean().defined(),
  hamet: boolean().defined(),
  imba: boolean().defined(),
  isOtherTestProcedureV1: boolean().defined(),
  isOtherTestProcedureV2: boolean().defined(),
  recommendations: string().defined(),
  designationOtherTestProcedureV1: requiredByCheck('isOtherTestProcedureV1'),
  designationOtherTestProcedureV2: requiredByCheck('isOtherTestProcedureV2'),
  sociabilityRating: string().optional().nullable(),
  sociabilityComment: string().optional().nullable(),
  cooperationRating: string().optional().nullable(),
  cooperationComment: string().optional().nullable(),
  teamRating: string().optional().nullable(),
  teamComment: string().optional().nullable(),
  takeCriticismRating: string().optional().nullable(),
  takeCriticismComment: string().optional().nullable(),
  concentrationRating: string().optional().nullable(),
  concentrationComment: string().optional().nullable(),
  memorizationRating: string().optional().nullable(),
  memorizationComment: string().optional().nullable(),
  workSpeedRating: string().optional().nullable(),
  workSpeedComment: string().optional().nullable(),
  independentWorkRating: string().optional().nullable(),
  independentWorkComment: string().optional().nullable(),
  enduranceWorkRating: string().optional().nullable(),
  enduranceWorkComment: string().optional().nullable(),
  flexibilityRating: string().optional().nullable(),
  flexibilityComment: string().optional().nullable(),
  selfAssessmentRating: string().optional().nullable(),
  selfAssessmentComment: string().optional().nullable(),
  selfRepresentationRating: string().optional().nullable(),
  selfRepresentationComment: string().optional().nullable(),
  grossFineMotorSkillsWithoutToolsRating: string().optional().nullable(),
  grossFineMotorSkillsWithoutToolsComment: string().optional().nullable(),
  grossFineMotorSkillsWithToolsRating: string().optional().nullable(),
  grossFineMotorSkillsWithToolsComment: string().optional().nullable(),
  physicalCapacityRating: string().optional().nullable(),
  physicalCapacityComment: string().optional().nullable(),
  spacialOrientationRating: string().optional().nullable(),
  spacialOrientationComment: string().optional().nullable(),
  timeOrientationRating: string().optional().nullable(),
  timeOrientationComment: string().optional().nullable(),
  mobilityTrafficRating: string().optional().nullable(),
  mobilityTrafficComment: string().optional().nullable(),
  readRating: string().optional().nullable(),
  readComment: string().optional().nullable(),
  writingRating: string().optional().nullable(),
  writingComment: string().optional().nullable(),
  calculateRating: string().optional().nullable(),
  calculateComment: string().optional().nullable(),
  otherCompetencies1Name: string().optional().nullable(),
  otherCompetencies1Rating: string().optional().nullable(),
  otherCompetencies1Comment: string().optional().nullable(),
  otherCompetencies2Name: string().optional().nullable(),
  otherCompetencies2Rating: string().optional().nullable(),
  otherCompetencies2Comment: string().optional().nullable(),
  importDate: string().optional().nullable(),
});
