import React from 'react';

import { EfficacyAssessmentModal } from '@/components/Modals/Modals/efficacyAssessment/EfficacyAssessmentModal/EfficacyAssessmentModal';
import { AppointmentDetailsModal } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/AppointmentDetailsModal/AppointmentDetailsModal';
import { CompleteAppointmentModal } from '@/components/Modals/Modals/functions/activityPlaningModals/appointmentDetailsModals/CompleteAppointmentModal/CompleteAppointmentModal';
import { AddICDCodesModal } from '@/components/Modals/Modals/functions/diagnostics/AddICDCodesModal/AddICDCodesModal';
import { AddICFItemsModal } from '@/components/Modals/Modals/functions/diagnostics/AddICFItemsModal/AddICFItemsModal';
import { ActionCourseCreatedDateModal } from '@/components/Modals/Modals/functions/integrationPlanModals/ActionCourseCreatedDateModal/ActionCourseCreatedDateModal';
import { CompetenceAnalysisImportModal } from '@/components/Modals/Modals/functions/integrationPlanModals/CompetenceAnalysisImportModal/CompetenceAnalysisImportModal';
import { CreateIntegrationPlanDocumentsModal } from '@/components/Modals/Modals/functions/integrationPlanModals/CreateIntegrationPlanDocumentsModal/CreateIntegrationPlanDocumentsModal';
import { GeneralDataUpdatedDataModal } from '@/components/Modals/Modals/functions/integrationPlanModals/GeneralDataUpdatedDataModal/GeneralDataUpdatedDataModal';
import { IntegrationPlanVersionHistoryModal } from '@/components/Modals/Modals/functions/integrationPlanModals/IntegrationPlanVersionHistoryModal/IntegrationPlanVersionHistoryModal';
import { InternshipDocumentationModal } from '@/components/Modals/Modals/functions/integrationPlanModals/InternshipDocumentationModal/InternshipDocumentationModal';
import { QualificationObjectivesModal } from '@/components/Modals/Modals/functions/integrationPlanModals/QualificationObjectivesModal/QualificationObjectivesModal';
import { DisplayMedicationModal } from '@/components/Modals/Modals/functions/medication/DisplayMedicationModal/DisplayMedicationModal';
import { StopAdministratingModal } from '@/components/Modals/Modals/functions/medication/StopAdministratingModal/StopAdministratingModal';
import { AdditionalPersonRequirementAssessmentPrognosisModal } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementAssessmentPrognosisModal/AdditionalPersonRequirementAssessmentPrognosisModal';
import { AdditionalPersonRequirementModal } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementModal/AdditionalPersonRequirementModal';
import { AdditionalPersonRequirementTimeListModal } from '@/components/Modals/Modals/functions/organizationalUnitsModals/AdditionalPersonRequirementTimeListModal/AdditionalPersonRequirementTimeListModal';
import { CreateOrganizationalUnitModal } from '@/components/Modals/Modals/functions/organizationalUnitsModals/CreateOrganizationalUnitModal/CreateOrganizationalUnitModal';
import { UpdateOrganizationalUnitModal } from '@/components/Modals/Modals/functions/organizationalUnitsModals/UpdateOrganizationalUnitModal/UpdateOrganizationalUnitModal';
import { SelectColleagueModal } from '@/components/Modals/Modals/functions/performanceProfile/SelectColleagueModal/SelectColleagueModal';
import { SelectTargetCatalogModal } from '@/components/Modals/Modals/functions/performanceProfile/SelectTargetCatalogModal/SelectTargetCatalogModal';
import { DeleteAddressModal } from '@/components/Modals/Modals/functions/personGeneralData/DeleteAddressModal/DeleteAddressModal';
import { DisplayAddressModal } from '@/components/Modals/Modals/functions/personGeneralData/DisplayAddressModal/DisplayAddressModal';
import { DefaultQuestionnaireModal } from '@/components/Modals/Modals/functions/selfAssesmentModals/DefaultQuestionnaireModal';
import { SimpleQuestionnaireModal } from '@/components/Modals/Modals/functions/selfAssesmentModals/SimpleQuestionnaireModal';
import { CreateAppliedTestProcedureModal } from '@/components/Modals/Modals/functions/testingResultModals/CreateAppliedTestProcedureModal/CreateAppliedTestProcedureModal';
import { DeleteAppliedTestProcedureModal } from '@/components/Modals/Modals/functions/testingResultModals/DeleteAppliedTestProcedureModal/DeleteAppliedTestProcedureModal';
import { ImportInternalDifferentiationModal } from '@/components/Modals/Modals/functions/testingResultModals/ImportInternalDifferentiationModal/ImportInternalDifferentiationModal';
import { UpdateAppliedTestProcedureModal } from '@/components/Modals/Modals/functions/testingResultModals/UpdateAppliedTestProcedureModal/UpdateAppliedTestProcedureModal';
import { VocationalTrainingAreaDocumentsModal } from '@/components/Modals/Modals/functions/vocationalTrainingAreaModals/VocationalTrainingAreaDocumentsModal/VocationalTrainingAreaDocumentsModal';
import { MobilityTransportTypesModal } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/MobilityTransportTypesModal/MobilityTransportTypesModal';
import { WorkingTimePredefinedVariantsModal } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModal';
import { AddPersonsModal } from '@/components/Modals/Modals/functionsPage/AddPersonsModal/AddPersonsModal';
import { InitialFunctionTypeChooseModal } from '@/components/Modals/Modals/functionsPage/InitialFunctionTypeChooseModal/InitialFunctionTypeChooseModal';
import { PersonInfoModal } from '@/components/Modals/Modals/functionsPage/PersonInfoModal/PersonInfoModal';
import { LogbookAddModal } from '@/components/Modals/Modals/logbook/LogbookAddModal/LogbookAddModal';
import { LogbookEditModal } from '@/components/Modals/Modals/logbook/LogbookEditModal/LogbookEditModal';
import { LogbookListModal } from '@/components/Modals/Modals/logbook/LogbookListModal/LogbookListModal';
import { ConfirmMarkAdministrationModal } from '@/components/Modals/Modals/medicationAdministration/ConfirmMarkAdministrationModal';
import { ConfirmUnmarkAdministrationModal } from '@/components/Modals/Modals/medicationAdministration/ConfirmUnmarkAdministrationModal';
import { MedicationAdministrationHistoryModal } from '@/components/Modals/Modals/medicationAdministration/MedicationAdministrationHistoryModal';
import { ActivityPersonsListModal } from '@/components/Modals/Modals/widgets/ActivityPersonsListModal/ActivityPersonsListModal';
import { PersonWidgetModal } from '@/components/Modals/Modals/widgets/PersonWidgetModal/PersonWidgetModal';
import { WidgetControlModal } from '@/components/Modals/Modals/widgets/WidgetControlModal/WidgetControlModal';

import { RecordingEffectivenessModal } from '../Modals/efficacyAssessment/RecordingEffectivenessModal/RecordingEffectivenessModal';

export const ModalWrapper: React.FC = () => {
  return (
    <div>
      <AddICDCodesModal />
      <AddICFItemsModal />
      <AddPersonsModal />
      <PersonWidgetModal />
      <InitialFunctionTypeChooseModal />
      <WidgetControlModal />
      <ActivityPersonsListModal />
      <QualificationObjectivesModal />
      <InternshipDocumentationModal />
      <CreateIntegrationPlanDocumentsModal />
      <ActionCourseCreatedDateModal />
      <GeneralDataUpdatedDataModal />
      <CompetenceAnalysisImportModal />
      <IntegrationPlanVersionHistoryModal />
      <DeleteAppliedTestProcedureModal />
      <MobilityTransportTypesModal />
      <UpdateAppliedTestProcedureModal />
      <CreateAppliedTestProcedureModal />
      <PersonInfoModal />
      <SelectTargetCatalogModal />
      <SelectColleagueModal />
      <ImportInternalDifferentiationModal />
      <DisplayAddressModal />
      <DeleteAddressModal />
      <VocationalTrainingAreaDocumentsModal />
      <WorkingTimePredefinedVariantsModal />
      <DisplayMedicationModal />
      <StopAdministratingModal />
      <SimpleQuestionnaireModal />
      <DefaultQuestionnaireModal />
      <ConfirmMarkAdministrationModal />
      <ConfirmUnmarkAdministrationModal />
      <CreateOrganizationalUnitModal />
      <UpdateOrganizationalUnitModal />
      <AdditionalPersonRequirementModal />
      <AdditionalPersonRequirementTimeListModal />
      <AdditionalPersonRequirementAssessmentPrognosisModal />
      <LogbookEditModal />
      <LogbookListModal />
      <LogbookAddModal />
      <CompleteAppointmentModal />
      <MedicationAdministrationHistoryModal />
      <EfficacyAssessmentModal />
      <RecordingEffectivenessModal />
      <AppointmentDetailsModal />
    </div>
  );
};
