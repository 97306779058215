/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import { toCamelCase } from '@/core/utils/commonUtils';
import { toBackendDate, toClientDateInput } from '@/core/utils/dateTimeUtil';
import type oas from '@/services/rest/base/openapi';
import { LoadingStatus } from '@/types/loadingStatus';

import {
  IAdministratedMedicationsFetchPayload,
  IDepartment,
  IMarkAdministratedMedicationPayload,
  IMarkUnadministratedMedicationPayload,
  IMedication,
  IUnadministratedMedicationsFetchPayload,
  medicationAdministrationActions,
} from './medicationAdministrationSlice';

type MedicationsUnadministratedResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/medication/administration/unadministered',
  'get',
  '200'
>;
type MedicationsAdministratedResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/medication/administration/unadministered',
  'get',
  '200'
>;
type DepartmentsdResponse = OASOutput<
  NormalizeOAS<typeof oas>,
  '/department/medication/administration',
  'get',
  '200'
>;
type MedicationMarkAdministratedRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/medication/administration/mark_administered',
  'post'
>;
type MedicationMarkUnadministratedRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/medication/administration/mark_unadministered',
  'post'
>;

function* fetchUnadministratedMedications(
  action: PayloadAction<IUnadministratedMedicationsFetchPayload>
): Generator<any, void, MedicationsUnadministratedResponse> {
  yield put(
    medicationAdministrationActions.setUnadministratedMedicationsLock(LoadingStatus.LOADING)
  );

  const { departmentID, date, search, isOnlyOnDemand } = action.payload;

  try {
    const response = yield call(restCall, '/medication/administration/unadministered', 'get', {
      query: {
        department_id: departmentID,
        date: toBackendDate(date) ?? undefined,
        only_on_demand: isOnlyOnDemand,
        search: search,
      },
      ...authAdd(),
    });

    const medications: IMedication[] = toCamelCase(response.medications);

    yield put(medicationAdministrationActions.setUnadministratedMedications(medications));

    yield put(
      medicationAdministrationActions.setUnadministratedMedicationsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on medications data fetching');
    yield put(
      medicationAdministrationActions.setUnadministratedMedicationsLock(LoadingStatus.ERROR)
    );
  }
}

function* fetchAdministratedMedications(
  action: PayloadAction<IAdministratedMedicationsFetchPayload>
): Generator<any, void, MedicationsAdministratedResponse> {
  yield put(
    medicationAdministrationActions.setUnadministratedMedicationsLock(LoadingStatus.LOADING)
  );

  const { administerID, departmentID, date, search, isOnlyOnDemand } = action.payload;

  try {
    const response = yield call(restCall, '/medication/administration/administered', 'get', {
      query: {
        department_id: departmentID,
        date: toBackendDate(date),
        search: search,
        administer_id: administerID,
        only_on_demand: isOnlyOnDemand
      },
      ...authAdd(),
    });

    const initialMedications: IMedication[] = toCamelCase(response.medications);

    const medications = initialMedications.map((medication) => ({
      ...medication,
      administeredOnTimestamp: toClientDateInput(medication.administeredOnTimestamp),
    }));

    yield put(medicationAdministrationActions.setAdministratedMedications(medications));

    yield put(
      medicationAdministrationActions.setAdministratedMedicationsLock(LoadingStatus.LOADED)
    );
  } catch (error) {
    console.log('Error on medications data fetching');
    yield put(
      medicationAdministrationActions.setUnadministratedMedicationsLock(LoadingStatus.ERROR)
    );
  }
}

function* fetchDepartments(): Generator<any, void, DepartmentsdResponse> {
  yield put(medicationAdministrationActions.setDepartmentsLock(LoadingStatus.LOADING));

  try {
    const response = yield call(restCall, '/department/medication/administration', 'get', {
      ...authAdd(),
    });

    const departments: IDepartment[] = toCamelCase(response.departments);

    yield put(medicationAdministrationActions.setDepartments(departments));

    yield put(medicationAdministrationActions.setDepartmentsLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on medications data fetching');
    yield put(medicationAdministrationActions.setDepartmentsLock(LoadingStatus.ERROR));
  }
}

function* markAdministrated(
  action: PayloadAction<IMarkAdministratedMedicationPayload>
): Generator<any, void, any> {
  const { medicationID, isGiven, administrationRemark } = action.payload;

  try {
    yield put(medicationAdministrationActions.setMarkAdministratedLock(LoadingStatus.LOADING));

    const request: MedicationMarkAdministratedRequest = {
      json: {
        administration_medication_id: medicationID,
        is_given: isGiven,
        administration_remark: administrationRemark,
      },
      ...authAdd(),
    };

    yield call(restCall, '/medication/administration/mark_administered', 'post', request);

    yield put(medicationAdministrationActions.setMarkAdministratedLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on mark administrated', error);
    yield put(medicationAdministrationActions.setMarkAdministratedLock(LoadingStatus.ERROR));
  }
}

function* markUnadministrated(
  action: PayloadAction<IMarkUnadministratedMedicationPayload>
): Generator<any, void, any> {
  const { medicationID, administrationRemark } = action.payload;

  try {
    yield put(medicationAdministrationActions.setMarkUnadministratedLock(LoadingStatus.LOADING));

    const request: MedicationMarkUnadministratedRequest = {
      json: {
        administration_medication_id: medicationID,
        administration_remark: administrationRemark,
      },
      ...authAdd(),
    };

    yield call(restCall, '/medication/administration/mark_unadministered', 'post', request);

    yield put(medicationAdministrationActions.setMarkUnadministratedLock(LoadingStatus.LOADED));
  } catch (error) {
    console.log('Error on mark unadministrated', error);
    yield put(medicationAdministrationActions.setMarkUnadministratedLock(LoadingStatus.ERROR));
  }
}

export const medicationAdministrationSagas = [
  takeLatest(
    medicationAdministrationActions.fetchUnadministratedMedications,
    fetchUnadministratedMedications
  ),
  takeLatest(
    medicationAdministrationActions.fetchAdministratedMedications,
    fetchAdministratedMedications
  ),
  takeLatest(medicationAdministrationActions.fetchDepartments, fetchDepartments),
  takeLatest(medicationAdministrationActions.markAdministrated, markAdministrated),
  takeLatest(medicationAdministrationActions.markUnadministrated, markUnadministrated),
];
