import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  id: number;
  name: string;
  surname: string;
}
export interface IPeformanceProfileModalsPayload {
  personID: number;
}
export interface IModal {
  isRender: boolean;
  payload: IPeformanceProfileModalsPayload | null;
}

export interface ISelectCollegaueModalPayload {
  users: IUser[];
}
export interface ISelectCollegaueModal {
  isRender: boolean;
  payload: ISelectCollegaueModalPayload | null;
}

export interface IPerformanceProfileModalsState {
  selectTargerCatalogModal: IModal;
  selectColleagueModal: ISelectCollegaueModal;
}

const initialState: IPerformanceProfileModalsState = {
  selectTargerCatalogModal: {
    isRender: false,
    payload: null,
  },
  selectColleagueModal: {
    isRender: false,
    payload: null,
  },
};

export const performanceProfileModalsSlice = createSlice({
  name: 'performanceProfileModals',
  initialState,
  reducers: {
    //Add Select Target Modal
    openSelectTargetCatalogModal: (
      state,
      action: PayloadAction<IPeformanceProfileModalsPayload | null>
    ) => ({
      ...state,
      selectTargerCatalogModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeSelectTargetCatalogModal: (state) => ({
      ...state,
      selectTargerCatalogModal: {
        isRender: false,
        payload: null,
      },
    }),
    //Add Select Colleague Modal
    openSelectColleagueModal: (
      state,
      action: PayloadAction<ISelectCollegaueModalPayload | null>
    ) => ({
      ...state,
      selectColleagueModal: {
        isRender: true,
        payload: action.payload,
      },
    }),
    closeSelectColleagueModal: (state) => ({
      ...state,
      selectColleagueModal: {
        isRender: false,
        payload: null,
      },
    }),
  },
});

export const performanceProfileModalsReducers = performanceProfileModalsSlice.reducer;
export const performanceProfileModalsActions = performanceProfileModalsSlice.actions;
