import { PayloadAction } from '@reduxjs/toolkit';
import { NormalizeOAS, OASOutput } from 'fets';
import fp from 'lodash/fp';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FetchContext } from '@/components/views/person/PersonPage/Context/PersonPageStoreContext';
import { authAdd, restCall } from '@/core/clients/rest';
import { mixinWithErrorHandler, mixinWithLoading } from '@/core/redux/sagas';
import { IPersonsPayload, personPageActions } from '@/core/redux/slices/personPage/personPageSlice';
import { augmentSaga } from '@/core/redux/utils';
import type oas from '@/services/rest/base/openapi';
import { IDepartment } from '@/types/department';
import { LoadingStatus } from '@/types/loadingStatus';
import { IPerson, IShortcutFunctionsItem } from '@/types/person';

import { authSelectors } from '../auth/selectors';

type DepartmentsResponse = OASOutput<NormalizeOAS<typeof oas>, '/department', 'get', '200'>;

type PersonsResponse = OASOutput<NormalizeOAS<typeof oas>, '/person', 'get', '200'>;

function* fetchDepartments(): Generator<any, void, DepartmentsResponse> {
  const response = yield call(restCall, '/department', 'get', {
    ...authAdd(),
  });

  const departments: IDepartment[] = response.locations;

  yield put(personPageActions.setDepartments(departments));
}

function* fetchPersons(
  action: PayloadAction<IPersonsPayload>
): Generator<any, void, PersonsResponse> {
  yield put(personPageActions.setPersonsLock(LoadingStatus.LOADING));

  const aToken = yield select(authSelectors.accessToken);
  const { payload } = action;
  const path = payload.context === FetchContext.default ? '/person' : '/person/related';
  const response = yield call(restCall, path, 'get', {
    query: {
      limit: payload.limit,
      search: payload.search,
      dep_ids: payload.departments,
      persons_ids: payload.persons,
    },
    aToken,
  });

  yield put(
    personPageActions.setPersonsLock(
      fp.isEmpty(response.data) ? LoadingStatus.LOADED_EMPTY : LoadingStatus.LOADED
    )
  );

  const persons: Record<string, IPerson> = response.data.reduce(
    (accum: Record<string, IPerson>, person) => {
      return {
        ...accum,
        [person.id]: {
          name: person.name,
          surname: person.surname,
          last_modified: person.last_modified,
          id: person.id,
          functions: person.functions?.map<IShortcutFunctionsItem>((func) => ({
            id: func.id,
            name: func.name,
            iconFilenameDisk: func.icon_filename_disk,
            tooltip: func.tooltip,
          })),
        },
      };
    },
    {}
  );

  yield put(personPageActions.setPersons(persons));
}

export const personPageSagas = [
  takeLatest(
    personPageActions.fetchDepartments,
    augmentSaga(
      fetchDepartments,
      mixinWithErrorHandler('', personPageActions.setDepartmentsLock),
      mixinWithLoading(personPageActions.setDepartmentsLock)
    )
  ),

  takeLatest(personPageActions.fetchPersons, fetchPersons),
];
