import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '@/components/Skeleton/Skeleton';
import { ITableColumn, Table } from '@/components/Table/Table';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { testingResultSelectors } from '@/core/redux/slices/functions/testingResult/selectors';
import { testingResultActions } from '@/core/redux/slices/functions/testingResult/testingResultSlice';
import { LoadingStatus } from '@/types/loadingStatus';

interface ITestProcedureResultTable {
  handleSelect: (id: number | null) => void;
}

export const AppliedTestProceduresList: React.FC<ITestProcedureResultTable> = ({
  handleSelect,
}) => {
  const { t: testProcedureResultTranslations } = useTranslation('testingResult', {
    keyPrefix: 'testProcedures.table.header',
  });

  const appliedTestProcedures = useAppSelector(testingResultSelectors.appliedTestProcedures);
  const loadingStatus = useAppSelector(testingResultSelectors.appliedTestProceduresLock);

  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const selectedPersonID = selectedPerson?.id;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedPersonID) {
      return;
    }

    dispatch(testingResultActions.fetchAppliedTestProcedures({ personID: selectedPersonID }));
  }, []);

  const columns: ITableColumn[] = [
    { header: testProcedureResultTranslations('procedureName.label'), accessor: 'procedure.value' },
    { header: testProcedureResultTranslations('dateFrom.label'), accessor: 'performedTimestamp' },
    { header: testProcedureResultTranslations('dateTo.label'), accessor: 'performedBy.value' },
    { header: testProcedureResultTranslations('procedureResult.label'), accessor: 'testResult' },
  ];

  return (
    <Skeleton trigger={loadingStatus === LoadingStatus.LOADING}>
      <Table
        columns={columns}
        data={appliedTestProcedures}
        singleSelect={true}
        onSingleSelectChange={handleSelect}
      />
    </Skeleton>
  );
};
