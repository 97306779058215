import React from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@/components/Accordion/Accordion';
import { CheckboxItem } from '@/components/CheckboxItem/CheckboxItem';
import { Typography } from '@/components/Typography/Typography';
import { useAppDispatch } from '@/core/redux/hooks';
import { notificationsActions } from '@/core/redux/slices/notifications/notificationsSlice';
import { colorTypes } from '@/styles/types';

export const CompetenceAnalysis: React.FC = () => {
  const { t: competenceAnalysisTranslations } = useTranslation('statusReport', {
    keyPrefix: 'competenceAnalysis',
  });

  const dispatch = useAppDispatch();

  const handleBlockedClick = () => {
    dispatch(
      notificationsActions.showNotification({
        notification: {
          type: 'warn',
          description: 'Information',
          title: 'Unavailable',
        },
      })
    );
  };

  return (
    <Accordion
      title={<Typography type={'H3'}>{competenceAnalysisTranslations('title.label')}</Typography>}
      headerClassName={`hover:bg-${colorTypes.HoverLight} py-3`}
    >
      <div className={'flex flex-col mt-4'}>
        <Typography>{competenceAnalysisTranslations('classificationUpToDate.label')}</Typography>
        <div className={'grid grid-cols-4 grid-rows-2 gap-4 mt-4'}>
          <CheckboxItem
            label={
              <Typography type={'16-M'}>
                {competenceAnalysisTranslations('fields.isCompetenceAnalysisUnchanged.label')}
              </Typography>
            }
            fieldName={'isCompetenceAnalysisUnchanged'}
            className={'row-span-2'}
            labelPosition={'right'}
            onClick={handleBlockedClick}
          />
          <CheckboxItem
            label={
              <Typography type={'16-M'}>
                {competenceAnalysisTranslations('fields.isNewClassification.label')}
              </Typography>
            }
            fieldName={'isNewClassification'}
            className={'row-span-2'}
            labelPosition={'right'}
            onClick={handleBlockedClick}
          />
          <CheckboxItem
            label={competenceAnalysisTranslations('fields.isActivityOrientation.label')}
            fieldName={'isActivityOrientation'}
            labelPosition={'right'}
            onClick={handleBlockedClick}
          />
          <CheckboxItem
            label={competenceAnalysisTranslations('fields.isOccupationalFieldOriented.label')}
            fieldName={'isOccupationalFieldOriented'}
            labelPosition={'right'}
            onClick={handleBlockedClick}
          />
          <CheckboxItem
            label={competenceAnalysisTranslations('fields.isWorkplaceOriented.label')}
            fieldName={'isWorkplaceOriented'}
            labelPosition={'right'}
            onClick={handleBlockedClick}
          />
        </div>
      </div>
    </Accordion>
  );
};
