import createStoreContext from '@/components/Context/ReduceModelStoreContext';

export interface IQuestionnaireStoreContext {
  isFormEditable: boolean;
  collapseTrigger: boolean;
}

export const {
  useStoreSelector: useQuestionnaireStoreSelector,
  StoreProvider: QuestionnaireStoreProvider,
} = createStoreContext<IQuestionnaireStoreContext>();
