import { FormItem } from '@/components/FormRender/FormRender';

export const disabilityForm: FormItem[] = [
  {
    id: '1',
    name: 'select',
    label: 'mainForm.fields.disability.label',
    type: 'select',
    options: [
      {
        id: 'one',
        name: 'One',
      },
      {
        id: 'two',
        name: 'Two',
      },
    ],
  },
  {
    id: '2',
    name: 'gdb',
    label: 'mainForm.fields.gdb.label',
    type: 'select',
    options: [
      {
        id: 'one',
        name: 'One',
      },
      {
        id: 'two',
        name: 'Two',
      },
    ],
  },
  {
    id: '3',
    name: 'art',
    label: 'mainForm.fields.type.label',
    type: 'textarea',
  },
  {
    id: '4',
    name: 'info',
    label: 'mainForm.fields.info.label',
    type: 'textarea',
  },
];

export const characteristicForm: FormItem[] = [
  {
    id: '1',
    name: 'check',
    type: 'checkbox',
    options: ['aG', 'H', 'Bl', 'Gl', 'RF'],
  },
];
