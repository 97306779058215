import { TFunction } from 'i18next';
import fp from 'lodash/fp';
import * as yup from 'yup';

import { IWorkingTimePredefinedVariantsForm } from '@/components/Modals/Modals/functions/workingTimeMobilityModals/WorkingTimePredefinedVariantsModal/WorkingTimePredefinedVariantsModalContent/WorkingTimePredefinedVariantsModalContent';

export const workingTimeResolverBuilder = (
  translations: TFunction<'workingTimePredefinedVariantsModal', undefined>
): yup.ObjectSchema<IWorkingTimePredefinedVariantsForm> => {
  const timeValidationSchema = yup.object().shape({
    fromDateStamp: yup
      .string()
      .nullable()
      .test(
        'is-earlier-than-toDateStamp',
        translations('workingTimePlanner.errors.fromTimeAfterToTime.label'),
        function (value) {
          const { toDateStamp } = this.parent;
          return value && toDateStamp
            ? new Date(`1970-01-01T${value}:00Z`) <= new Date(`1970-01-01T${toDateStamp}:00Z`)
            : true;
        }
      )
      .test(
        'is-required-when-toDateStamp',
        translations('workingTimePlanner.errors.requiredField.label'),
        function (value) {
          const { toDateStamp } = this.parent;
          return toDateStamp ? !fp.isEmpty(value) : true;
        }
      ),
    toDateStamp: yup
      .string()
      .nullable()
      .test(
        'is-later-than-fromDateStamp',
        translations('workingTimePlanner.errors.toTimeBeforeFromTime.label'),
        function (value) {
          const { fromDateStamp } = this.parent;
          return fromDateStamp && value
            ? new Date(`1970-01-01T${fromDateStamp}:00Z`) <= new Date(`1970-01-01T${value}:00Z`)
            : true;
        }
      )
      .test(
        'is-required-when-fromDateStamp',
        translations('workingTimePlanner.errors.requiredField.label'),
        function (value) {
          const { fromDateStamp } = this.parent;
          return fromDateStamp ? !fp.isEmpty(value) : true;
        }
      ),
    pauseDuration: yup
      .number()
      .typeError('Must be a number')
      .test(
        'is-within-interval',
        translations('workingTimePlanner.errors.pauseDurationBiggerThenInterval.label'),
        function (value) {
          if (!value || Number.isNaN(value)) {
            return true;
          }
          const { fromDateStamp, toDateStamp } = this.parent;
          if (fromDateStamp && toDateStamp) {
            const from = new Date(`1970-01-01T${fromDateStamp}:00Z`).getTime();
            const to = new Date(`1970-01-01T${toDateStamp}:00Z`).getTime();
            const interval = (to - from) / 1000 / 60;
            return value <= interval;
          }
          return true;
        }
      ),
  });

  return yup.object().shape({
    selectedID: yup.number().nullable(),
    predefinedName: yup.string().nullable(),
    appliesFromTimeStamp: yup
      .date()
      .required(translations('workingTimePlanner.errors.requiredField.label')),
    otherPartTimeReasonText: yup.string(),
    workingTimeType: yup
      .string()
      .required(translations('workingTimePlanner.errors.requiredField.label')),
    partTimeReasonType: yup
      .string()
      .required(translations('workingTimePlanner.errors.requiredField.label')),
    workingWeek: yup.object().shape({
      sumGross: yup.number().nullable().min(0),
      sumNet: yup.number().nullable().min(0),
      sumPauseDuration: yup.number().nullable().min(0),
      workingDaysCount: yup.number().nullable().min(0),
      workingDays: yup.object().shape({
        1: timeValidationSchema,
        2: timeValidationSchema,
        3: timeValidationSchema,
        4: timeValidationSchema,
        5: timeValidationSchema,
        6: timeValidationSchema,
        7: timeValidationSchema,
      }),
    }),
  });
};
