import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { ChartsList } from '@/components/functions/PerformanceProfile/CompetenciesCharts/ChartsList/ChartsList';
import { CompetenciesBarChart } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CompetenciesBarChart/CompetenciesBarChart';
import { CompetenciesChartsNavigation } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CompetenciesChartsNavigation/CompetenciesChartsNavigation';
import { CompetenciesRadarChart } from '@/components/functions/PerformanceProfile/CompetenciesCharts/CompetenciesRadarChart/CompetenciesRadarChart';
import { CompetenciesView } from '@/core/enums/functions/performanceProfile/competenciesCharts/competenciesChartsViewEnum';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { functionPageSelectors } from '@/core/redux/slices/functionPage/selectors';
import { performanceProfileSelectors } from '@/core/redux/slices/functions/performanceProfile/selectors';
import { performanceProfileActions } from '@/core/redux/slices/functions/performanceProfile/slice';
import { twMerge } from '@/core/utils/tailwindUtil';

import styles from './styles.module.scss';

interface ICompetenciesCharts {
  backToQuestionnaireHistory: () => void;
}

export const CompetenciesCharts: React.FC<ICompetenciesCharts> = ({
  backToQuestionnaireHistory,
}) => {
  const [viewMode, setViewMode] = useState<CompetenciesView>(CompetenciesView.BarChart);
  const [competenceAnalysisIDs, setCompetenceAnalysisIDs] = useState<number[] | null>(null);

  const chartsData = useAppSelector(performanceProfileSelectors.competenceChartsData);
  const selectedPerson = useAppSelector(functionPageSelectors.selectedPerson);
  const detailIDs = useAppSelector(performanceProfileSelectors.detailsIDs);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!detailIDs || !selectedPerson) {
      return;
    }

    if (selectedPerson.id in detailIDs) {
      const competenceAnalysisIDs = detailIDs[selectedPerson.id];
      setCompetenceAnalysisIDs(competenceAnalysisIDs);
      return;
    }

    backToQuestionnaireHistory();
  }, [detailIDs, selectedPerson]);

  const competenciesViews: Record<CompetenciesView, ReactNode> = useMemo(
    () => ({
      [CompetenciesView.BarChart]: (
        <CompetenciesBarChart data={chartsData?.data} domain={chartsData?.valueRange} />
      ),
      [CompetenciesView.RadarChart]: (
        <CompetenciesRadarChart data={chartsData?.data} domain={chartsData?.valueRange} />
      ),
    }),
    [chartsData]
  );

  const handleChangeView = (view: CompetenciesView) => {
    setViewMode(view);
  };

  useEffect(() => {
    if (selectedPerson && competenceAnalysisIDs)
      dispatch(
        performanceProfileActions.fetchCompetenceChartsData({
          personID: selectedPerson.id,
          parentID: 0,
          competenceAnalysisIds: competenceAnalysisIDs,
        })
      );
  }, [competenceAnalysisIDs]);

  return (
    <div className={'flex flex-col gap-4 px-4'}>
      <CompetenciesChartsNavigation
        backToQuestionnaireHistory={backToQuestionnaireHistory}
        handleChangeView={handleChangeView}
      />
      <div
        className={twMerge(
          styles.container,
          'grid grid-cols-[9fr_3fr] grid-rows-[min-content,_auto] gap-2'
        )}
      >
        {competenciesViews[viewMode]}
        {competenceAnalysisIDs && (
          <ChartsList reviewVariants={chartsData?.variants} selectedIds={competenceAnalysisIDs} />
        )}
      </div>
    </div>
  );
};
