import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import { initializeApollo } from '@/core/clients/apollo';
import {} from '@/core/redux/slices/modalsSlice/functions/selfAssesment/selfAssesmentModalsSlice';
import {
  CreateSelfAssessmentForPersonDocument,
  CreateSelfAssessmentForPersonMutation,
  CreateSelfAssessmentForPersonMutationResult,
  CreateSelfAssessmentForPersonMutationVariables,
  GetSelfAssessmentAnswersByPersonDocument,
  GetSelfAssessmentAnswersByPersonQuery,
  GetSelfAssessmentAnswersByPersonQueryHookResult,
  GetSelfAssessmentAnswersByPersonQueryVariables,
  GetSelfAssessmentAnswersDocument,
  GetSelfAssessmentAnswersQuery,
  GetSelfAssessmentAnswersQueryHookResult,
  GetSelfAssessmentAnswersQueryVariables,
  GetSelfAssessmentPersonQuestionnaireDocument,
  GetSelfAssessmentPersonQuestionnaireQuery,
  GetSelfAssessmentPersonQuestionnaireQueryHookResult,
  GetSelfAssessmentPersonQuestionnaireQueryVariables,
  GetSelfAssessmentQuestionnairesByPersonDocument,
  GetSelfAssessmentQuestionnairesByPersonQuery,
  GetSelfAssessmentQuestionnairesByPersonQueryHookResult,
  GetSelfAssessmentQuestionnairesByPersonQueryVariables,
  GetSelfAssessmentQuestionnairesDocument,
  GetSelfAssessmentQuestionnairesQuery,
  GetSelfAssessmentQuestionnairesQueryHookResult,
  GetSelfAssessmentQuestionnairesQueryVariables,
  UpdateSelfAssessmentForPersonDocument,
  UpdateSelfAssessmentForPersonMutation,
  UpdateSelfAssessmentForPersonMutationResult,
  UpdateSelfAssessmentForPersonMutationVariables,
} from '@/services/graphql/base/graphql';

import {
  ICreateSelfAssessmentForPersonPayload,
  IFetchPersonAnswersPayload,
  IFetchPersonQuestionnaireAnswersPayload,
  IFetchPersonQuestionnairePayload,
  IFetchPersonQuestionnairesPayload,
  ISelfAssessmentAnswer,
  ISelfAssessmentPersonQuestionnaireWithData,
  ISelfAssessmentPersonQuestionnaireWithDetails,
  ISelfAssessmentQuestionnaire,
  IUpdateSelfAssessmentForPersonPayload,
  simpleSelfAssesmentActions,
} from './simpleSelfAssesmentSlice';

const mapAnswersResponse = (
  response: GetSelfAssessmentAnswersQueryHookResult
): ISelfAssessmentAnswer[] =>
  response.data?.t_se_antworten?.map((sea) => {
    return {
      seaID: sea.sea_id,
      m2sID: String(sea.m2s_id?.m2s_id ?? 0),
      sefID: String(sea.sef_id),
      answer: sea.antwort,
      note: sea.anmerkung,
      lastChange: sea.edit_am ?? '',
    };
  }) ?? [];

const mapQuestionnairesResponse = (
  response: GetSelfAssessmentQuestionnairesQueryHookResult
): ISelfAssessmentQuestionnaire[] =>
  response.data?.lt_se_bogen?.map((seb) => {
    return {
      sebID: seb.seb_id,
      name: seb.seb_name,
      questions:
        seb.fragen?.map((sef) => ({
          sefID: sef?.sef_id?.sef_id ?? '0',
          name: sef?.sef_id?.sef_name ?? '',
        })) ?? [],
    };
  }) ?? [];

const mapPersonQuestionnaireData = (
  response: GetSelfAssessmentPersonQuestionnaireQueryHookResult,
  availableQuestionnaires: ISelfAssessmentQuestionnaire[],
  personAnswers: ISelfAssessmentAnswer[]
): ISelfAssessmentPersonQuestionnaireWithData[] =>
  response.data?.t_se_ma2seb?.map((ma2seb) => {
    const foundQuestionnaire = availableQuestionnaires.find(
      (questionnaire) => questionnaire.sebID === String(ma2seb.seb_id)
    );
    const foundAnswers = personAnswers.filter((answer) => answer.m2sID === ma2seb.m2s_id);
    foundAnswers.sort((a, b) => {
      return Date.parse(b.lastChange) - Date.parse(a.lastChange);
    });
    return {
      m2sID: ma2seb.m2s_id,
      personID: String(ma2seb.ma_id),
      sebID: String(ma2seb.seb_id),
      createdByID: String(ma2seb.new_von),
      createdDate: ma2seb.new_am ?? '',
      statusName: String(ma2seb.se_status_id?.status_name ?? ''),
      answeredByPerson: ma2seb.selbst_beantwortet,
      questionnaire: foundQuestionnaire ?? null,
      answers: foundAnswers,
      lastChange: foundAnswers[0]?.lastChange ?? '',
    };
  }) ?? [];

const mapPersonQuestionnaireDetails = (
  response: GetSelfAssessmentQuestionnairesByPersonQueryHookResult,
  availableQuestionnaires: ISelfAssessmentQuestionnaire[],
  personAnswers: ISelfAssessmentAnswer[]
): ISelfAssessmentPersonQuestionnaireWithDetails[] =>
  response.data?.t_se_ma2seb?.map((ma2seb) => {
    const foundQuestionnaire = availableQuestionnaires.find(
      (questionnaire) => questionnaire.sebID === String(ma2seb.seb_id)
    );
    const foundAnswers = personAnswers.filter((answer) => answer.m2sID === ma2seb.m2s_id);
    foundAnswers.sort((a, b) => {
      return Date.parse(b.lastChange) - Date.parse(a.lastChange);
    });
    return {
      m2sID: ma2seb.m2s_id,
      personID: String(ma2seb.ma_id),
      sebID: String(ma2seb.seb_id),
      createdByID: String(ma2seb.new_von),
      createdDate: ma2seb.new_am ?? '',
      statusName: String(ma2seb.se_status_id?.status_name ?? ''),
      answeredByPerson: ma2seb.selbst_beantwortet,
      questionnaireName: foundQuestionnaire?.name ?? '',
      numQuestions: foundQuestionnaire?.questions?.length ?? 0,
      numAnswered: foundAnswers.length ?? 0,
      lastChange: foundAnswers[0]?.lastChange ?? '',
    };
  }) ?? [];

const mapPersonAnswersResponse = (
  response: GetSelfAssessmentAnswersByPersonQueryHookResult
): ISelfAssessmentAnswer[] =>
  response.data?.t_se_antworten?.map((sea) => {
    return {
      seaID: sea.sea_id,
      m2sID: String(sea.m2s_id?.m2s_id ?? 0),
      sefID: String(sea.sef_id),
      answer: sea.antwort,
      note: sea.anmerkung,
      lastChange: sea.edit_am ?? '',
    };
  }) ?? [];

function* fetchQuestionnaires(): Generator<
  any,
  void,
  GetSelfAssessmentQuestionnairesQueryHookResult
> {
  try {
    const client = initializeApollo();

    const response = yield client.query<
      GetSelfAssessmentQuestionnairesQuery,
      GetSelfAssessmentQuestionnairesQueryVariables
    >({
      query: GetSelfAssessmentQuestionnairesDocument,
      errorPolicy: 'ignore',
    });

    const questionnaires = mapQuestionnairesResponse(response);

    yield put(simpleSelfAssesmentActions.setQuestionnaires(questionnaires));
  } catch (error) {
    console.log('Error on trying to fetch questionnaires', error);
  }
}

function* fetchPersonQuestionnaire(
  action: PayloadAction<IFetchPersonQuestionnairePayload>
): Generator<any, void, GetSelfAssessmentPersonQuestionnaireQueryHookResult> {
  const { payload } = action;

  try {
    const client = initializeApollo();

    const response = yield client.query<
      GetSelfAssessmentPersonQuestionnaireQuery,
      GetSelfAssessmentPersonQuestionnaireQueryVariables
    >({
      query: GetSelfAssessmentPersonQuestionnaireDocument,
      variables: payload.variables,
    });

    if (!response.data) {
      return;
    }

    const personQuestionnaires = mapPersonQuestionnaireData(
      response,
      payload.availableQuestionnaires ?? [],
      payload.personQuestionnaireAnswers ?? []
    );

    if (!personQuestionnaires[0]) {
      return;
    }

    yield put(simpleSelfAssesmentActions.setPersonQuestionnaire(personQuestionnaires[0]));
  } catch (error) {
    console.log('Error on trying to fetch person questionnaire', error);
  }
}

function* fetchPersonQuestionnaires(
  action: PayloadAction<IFetchPersonQuestionnairesPayload>
): Generator<any, void, GetSelfAssessmentQuestionnairesByPersonQueryHookResult> {
  const { payload } = action;

  try {
    const client = initializeApollo();

    const response = yield client.query<
      GetSelfAssessmentQuestionnairesByPersonQuery,
      GetSelfAssessmentQuestionnairesByPersonQueryVariables
    >({
      query: GetSelfAssessmentQuestionnairesByPersonDocument,
      variables: payload.variables,
    });

    const result = response.data?.t_se_ma2seb;
    if (!result) {
      return;
    }

    const personQuestionnaires = mapPersonQuestionnaireDetails(
      response,
      payload.availableQuestionnaires ?? [],
      payload.personAnswers ?? []
    );

    yield put(simpleSelfAssesmentActions.setPersonQuestionnaires(personQuestionnaires));
  } catch (error) {
    console.log('Error on trying to fetch person questionnaires', error);
  }
}

function* fetchPersonAnswers(
  action: PayloadAction<IFetchPersonAnswersPayload>
): Generator<any, void, GetSelfAssessmentAnswersByPersonQueryHookResult> {
  const { payload } = action;

  try {
    const client = initializeApollo();

    const response = yield client.query<
      GetSelfAssessmentAnswersByPersonQuery,
      GetSelfAssessmentAnswersByPersonQueryVariables
    >({
      query: GetSelfAssessmentAnswersByPersonDocument,
      variables: payload.variables,
    });

    const result = response.data?.t_se_antworten;
    if (!result) {
      return;
    }

    const answers = mapPersonAnswersResponse(response);

    yield put(simpleSelfAssesmentActions.setPersonAnswers(answers));
  } catch (error) {
    console.log('Error on trying to fetch person answers', error);
  }
}

function* fetchPersonQuestionnaireAnswers(
  action: PayloadAction<IFetchPersonQuestionnaireAnswersPayload>
): Generator<any, void, GetSelfAssessmentAnswersQueryHookResult> {
  const { payload } = action;

  try {
    const client = initializeApollo();

    const response = yield client.query<
      GetSelfAssessmentAnswersQuery,
      GetSelfAssessmentAnswersQueryVariables
    >({
      query: GetSelfAssessmentAnswersDocument,
      variables: payload.variables,
    });

    const result = response.data?.t_se_antworten;
    if (!result) {
      return;
    }

    const answers = mapAnswersResponse(response);

    yield put(simpleSelfAssesmentActions.setPersonQuestionnaireAnswers(answers));
  } catch (error) {
    console.log('Error on trying to fetch person questionnaire answers', error);
  }
}

function* createSelfAssessmentForPerson(
  action: PayloadAction<ICreateSelfAssessmentForPersonPayload>
): Generator<any, void, CreateSelfAssessmentForPersonMutationResult> {
  const { payload } = action;
  try {
    const client = initializeApollo();

    const response = yield client.mutate<
      CreateSelfAssessmentForPersonMutation,
      CreateSelfAssessmentForPersonMutationVariables
    >({
      mutation: CreateSelfAssessmentForPersonDocument,
      variables: payload.variables,
    });

    const m2sID = response.data?.create_t_se_ma2seb_item?.m2s_id;
    if (!m2sID) {
      return;
    }

    yield put(simpleSelfAssesmentActions.setUpdatedPersonQuestionnaireID(m2sID));
  } catch (error) {
    console.log('Error on trying to create answer', error);
  }
}

function* updateSelfAssessmentForPerson(
  action: PayloadAction<IUpdateSelfAssessmentForPersonPayload>
): Generator<any, void, UpdateSelfAssessmentForPersonMutationResult> {
  const { payload } = action;
  try {
    const client = initializeApollo();

    const response = yield client.mutate<
      UpdateSelfAssessmentForPersonMutation,
      UpdateSelfAssessmentForPersonMutationVariables
    >({
      mutation: UpdateSelfAssessmentForPersonDocument,
      variables: payload.variables,
    });

    const m2sID = response.data?.update_t_se_ma2seb_item?.m2s_id;
    if (!m2sID) {
      return;
    }

    yield put(simpleSelfAssesmentActions.setUpdatedPersonQuestionnaireID(m2sID));
  } catch (error) {
    console.log('Error on trying to update answer', error);
  }
}

export const simpleSelfAssessmentSagas = [
  takeLatest(simpleSelfAssesmentActions.fetchQuestionnaires, fetchQuestionnaires),
  takeLatest(simpleSelfAssesmentActions.fetchPersonQuestionnaire, fetchPersonQuestionnaire),
  takeLatest(simpleSelfAssesmentActions.fetchPersonQuestionnaires, fetchPersonQuestionnaires),
  takeLatest(simpleSelfAssesmentActions.fetchPersonAnswers, fetchPersonAnswers),
  takeLatest(
    simpleSelfAssesmentActions.fetchPersonQuestionnaireAnswers,
    fetchPersonQuestionnaireAnswers
  ),
  takeLatest(
    simpleSelfAssesmentActions.createSelfAssessmentForPerson,
    createSelfAssessmentForPerson
  ),
  takeLatest(
    simpleSelfAssesmentActions.updateSelfAssessmentForPerson,
    updateSelfAssessmentForPerson
  ),
];
