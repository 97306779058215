import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAppointmentDetailsForm } from '@/components/functions/ActivityPlanning/FullFunction/AppointmentDetails/hooks/useAppointmentDetailsForm';
import { LoadingStatus } from '@/types/loadingStatus';

export interface IActivityPlanningAppointmentDetailsFetchPayload {
  personID: number;
  appointmentID: number;
  measureID: number;
}

export interface IActivityPlanningAppointmentDetailsValuesFetchPayload {
  measureID: number;
}

export interface ICreateActivityPlanningAppointmentPayload {
  formValues: IAppointmentDetailsForm;
  measureID: number;
  personID: number;
}

export interface IUpdateActivityPlanningAppointmentPayload
  extends ICreateActivityPlanningAppointmentPayload {
  appointmentID: number;
}

export interface IAppointmentImplementation {
  id: number;
  name: string;
}

export interface IAppointmentType {
  id: number;
  value?: string | null;
}

export interface IAppointmentMeasureAid {
  id: number;
  name: string | null;
}

export interface IAppointmentDetails {
  id: number;
  responsibleUser: string;
  appointmentType?: IAppointmentType | null;
  appointmentStartDate?: string | null;
  appointmentEndDate?: string | null;
  appointmentStartTime?: string | null;
  appointmentEndTime?: string | null;
  hours?: string | null;
  measureTitle?: string | null;
  location?: string | null;
  implementationAids?: IAppointmentImplementation[] | null;
  appointmentInfo?: string | null;
  places?: number | null;
}

export interface IActivityPlanningAppointmentDetails {
  createAppointmentPayload: ICreateActivityPlanningAppointmentPayload | null;
  updateAppointmentPayload: ICreateActivityPlanningAppointmentPayload | null;
  appointmentDetails: IAppointmentDetails | null;
  appointmentDetailsLock: LoadingStatus;
  measureAidsValues: IAppointmentMeasureAid[] | null;
  appointmentTypeValues: IAppointmentType[] | null;
}

const initialState: IActivityPlanningAppointmentDetails = {
  appointmentDetails: null,
  appointmentDetailsLock: LoadingStatus.NEVER,
  createAppointmentPayload: null,
  updateAppointmentPayload: null,
  measureAidsValues: null,
  appointmentTypeValues: null,
};

export const activityPlanningAppointmentDetails = createSlice({
  name: 'appointmentDetails',
  initialState,
  reducers: {
    //measures list
    fetchAppointmentDetails: (
      state,
      action: PayloadAction<IActivityPlanningAppointmentDetailsFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    fetchAppointmentDetailsFields: (
      state,
      action: PayloadAction<IActivityPlanningAppointmentDetailsValuesFetchPayload>
    ) => ({
      ...state,
      payload: action.payload,
    }),
    setAppointmentDetails: (state, action: PayloadAction<IAppointmentDetails | null>) => ({
      ...state,
      appointmentDetails: action.payload,
    }),
    setAppointmentDetailsLock: (state, action: PayloadAction<LoadingStatus>) => ({
      ...state,
      appointmentDetailsLock: action.payload,
    }),
    createAppointment: (
      state,
      action: PayloadAction<ICreateActivityPlanningAppointmentPayload>
    ) => ({
      ...state,
      createAppointmentPayload: action.payload,
    }),
    updateAppointment: (
      state,
      action: PayloadAction<IUpdateActivityPlanningAppointmentPayload>
    ) => ({
      ...state,
      updateAppointmentPayload: action.payload,
    }),
    //select values
    setMeasureAidsValues: (state, action: PayloadAction<IAppointmentMeasureAid[] | null>) => ({
      ...state,
      measureAidsValues: action.payload,
    }),
    fetchAppointmentTypeValues: () => {},
    setAppointmentTypeValues: (state, action: PayloadAction<IAppointmentType[] | null>) => ({
      ...state,
      appointmentTypeValues: action.payload,
    }),
  },
});

export const activityPlanningAppointmentDetailsReducers =
  activityPlanningAppointmentDetails.reducer;
export const activityPlanningAppointmentDetailsActions = activityPlanningAppointmentDetails.actions;
